import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Col, Row, Tabs, Spin } from "antd";

import AOS from "aos";
import "aos/dist/aos.css";
import RangeProfiles from "../components/templates/RangeProfiles";
import WorkoutTemplates from "../components/templates/WorkoutTemplates";
import BlockTemplates from "../components/templates/BlockTemplates";
import TabPane from "antd/es/tabs/TabPane";
import ProgramTemplates from "../components/templates/ProgramTemplates";

function Templates() {
  const [isLoading, setLoading] = useState(true);
  const { user } = useSelector((state) => state.auth);
  const { Theme } = useSelector((state) => state.auth);

  useEffect(() => {
    AOS.init();
    setLoading(false);
  }, []);

  if (isLoading) {
    return (
      <Spin
        size="large"
        style={{ position: "absolute", top: "50%", left: "50%" }}
      />
    );
  }

  return (
    <Col style={{ position: "relative" }}>
      <Row
        data-aos="fade-up"
        data-aos-easing="linear"
        data-aos-duration="750"
        style={{
          position: "relative",
          top: "3vw",
          marginBottom: 500
        }}
        gutter={[24, 0]}
      >
        <Col
          style={{
            paddingLeft: "2vw"
          }}
          xs={28}
          sm={24}
          md={18}
          lg={18}
          xl={16}
        >
          <Tabs
            className={Theme.BACKGROUND === "#000" ? "dark-mode" : "light-mode"}
            style={{ color: Theme.PRIMARY, outline: "none" }}
          >
            <TabPane tab="Workouts" key="workouts">
              <WorkoutTemplates />
            </TabPane>
            <TabPane tab="Blocks" key="blocks">
              <BlockTemplates />
            </TabPane>
            <TabPane tab="Programs" key="programs">
              <ProgramTemplates />
            </TabPane>
            {user?.profile?.allow_templates && (
              <TabPane tab="Range Profiles" key="ranges">
                <RangeProfiles />
              </TabPane>
            )}
          </Tabs>
        </Col>
      </Row>
    </Col>
  );
}
export default Templates;
