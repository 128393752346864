import React, { useState } from "react";
import { Button, Card, Col, Modal, Row } from "antd";
import { StyleSheet, css } from "aphrodite";

import { IoCheckmarkCircleSharp } from "react-icons/io5";
import Text from "antd/lib/typography/Text";
import { useDispatch, useSelector } from "react-redux";
import { getProfiles, patchClient } from "../redux/actions/clients";
import RangeTemplates from "./templates/RangeTemplates";
import Theme from "../constants/Theme";
import Title from "antd/lib/typography/Title";
import CustomSelect from "./common/DropDown";
import range_descriptions from "./templates/range_descriptions";

const ClientProfile = ({ item, selected, muted }) => {
  const dispatch = useDispatch();
  const { Theme, user } = useSelector((state) => state.auth);
  const { profiles, client } = useSelector((state) => state.clients);
  const [rangeProfile, setRangeProfile] = useState({});
  const [isRangeModalVisible, setIsRangeModalVisible] = useState(false);
  const [resetModalVisible, setResetModalVisible] = useState(false);
  const [selectedRangeTemplate, setSelectedRangeTemplate] = useState(null);
  const [isRangeInfoModalVisible, seRangeInfoModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState({
    name: "",
    description: ""
  });

  const profileOptions = profiles.map((profile) => ({
    value: profile.id,
    label: profile.name
  }));

  const showRangeModal = () => {
    dispatch(getProfiles());
    setIsRangeModalVisible(true);
  };

  const handleRangeModalCancel = () => {
    setIsRangeModalVisible(false);
  };

  const handleRangeModalReset = () => {
    setResetModalVisible(true);
  };

  const closeResetModal = () => {
    setResetModalVisible(false);
  };
  const updateRanges = () => {
    const updatedData = {
      range_profile: rangeProfile
    };

    dispatch(patchClient(client?.id, updatedData));
    setIsRangeModalVisible(false);
  };

  const showRangeInfoModal = (category) => {
    const descriptionData = range_descriptions[category];
    if (descriptionData) {
      setModalContent({
        name: category,
        description: descriptionData.description
      });
      seRangeInfoModalVisible(true);
    }
  };
  const handleRangeReset = () => {
    const selectedProfile = profiles.find(
      (profile) => profile.id === selectedRangeTemplate
    );
    const updatedData = {
      range_profile: selectedProfile.range_profile
    };
    dispatch(patchClient(client?.id, updatedData));
    setSelectedRangeTemplate(null);
    setResetModalVisible(false);
    //setIsRangeModalVisible(false);
  };

  const calculateAge = (dob) => {
    const [year, month, day] = dob.split("-");
    const birthDate = new Date(year, month - 1, day);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };

  const clientAge = client?.dob ? calculateAge(client?.dob) : null;

  return (
    <Card
      style={{
        bottom: 75,
        width: "78vw",
        minHeight: "100vh",
        zIndex: 100,
        position: "relative",
        background: Theme.CARD,
        borderWidth: 0,
        right: "8.75vw"
      }}
    >
      <Row>
        {user?.profile?.allow_edit_ranges && (
          <button textStyle={{ fontSize: "3vw" }} onClick={showRangeModal}>
            Edit Ranges
          </button>
        )}
        <Text
          style={{
            fontSize: ".75vw",
            marginRight: ".5vw",
            color: Theme.TEXT
          }}
        >
          {" "}
          Age:
        </Text>
        <Text
          style={{
            fontSize: ".75vw",
            color: Theme.PRIMARY
          }}
        >
          {" "}
          {clientAge}
        </Text>
      </Row>
      <Row>
        <Text
          style={{
            fontSize: ".75vw",
            marginRight: ".5vw",
            color: Theme.TEXT
          }}
        >
          {" "}
          Weight:
        </Text>
        {user?.profile?.measurement === "Imperial" ? (
          <Text
            style={{
              fontSize: ".75vw",
              color: Theme.PRIMARY
            }}
          >
            {" "}
            {client?.weight}lbs
          </Text>
        ) : (
          <Text
            style={{
              fontSize: ".75vw",
              color: Theme.PRIMARY
            }}
          >
            {" "}
            {client?.weight_kg}kg
          </Text>
        )}
      </Row>
      <Row>
        <Text
          style={{
            fontSize: ".75vw",
            marginRight: ".5vw",
            color: Theme.TEXT
          }}
        >
          {" "}
          Height:{" "}
        </Text>
        {user?.profile?.measurement === "Imperial" ? (
          <Text style={{ fontSize: ".75vw", color: Theme.PRIMARY }}>
            {client?.height_feet}'{client?.height_inches}"
          </Text>
        ) : (
          <Text style={{ fontSize: ".75vw", color: Theme.PRIMARY }}>
            {client?.height_cm}cm
          </Text>
        )}
      </Row>
      <Row>
        <Text
          style={{
            fontSize: ".75vw",
            marginRight: ".5vw",
            color: Theme.TEXT
          }}
        >
          {" "}
          Gender:{" "}
        </Text>
        {client?.gender === "M" && (
          <Text style={{ fontSize: ".75vw", color: Theme.PRIMARY }}>Male</Text>
        )}{" "}
        {client?.gender === "F" && (
          <Text style={{ fontSize: ".75vw", color: Theme.PRIMARY }}>
            Female
          </Text>
        )}
      </Row>
      <Modal
        visible={isRangeModalVisible}
        className={
          Theme.BACKGROUND === "#000"
            ? "customAntdModalDark"
            : "customAntdModalLight"
        }
        onCancel={handleRangeModalCancel}
        title={[
          <Row style={{ background: Theme.CARD }}>
            <Button
              style={{
                backgroundImage:
                  "linear-gradient(45deg, #C04848 0%, #C04848 80%)",
                border: 0,
                color: "white",
                marginLeft: "1vw"
              }}
              key="cancel"
              onClick={handleRangeModalCancel}
            >
              Cancel
            </Button>
            ,
            <Button
              style={{
                backgroundImage: "linear-gradient(45deg, #fff 0%, #fff 80%)",
                border: 0,
                color: "black",
                marginLeft: "1vw"
              }}
              key="reset"
              onClick={handleRangeModalReset}
            >
              Reset
            </Button>
            ,
            <Button
              style={{
                backgroundImage:
                  "linear-gradient(45deg, #C04848 0%, #480048 80%)",
                border: 0,
                marginLeft: "1vw"
              }}
              key="save"
              type="primary"
              onClick={updateRanges}
            >
              Save
            </Button>
          </Row>
        ]}
        width={"90%"}
        centered={true}
        bodyStyle={{ padding: 0 }}
      >
        <Row justify={"center"}>
          <RangeTemplates
            rangeProfile={rangeProfile}
            setRangeProfile={setRangeProfile}
            showModal={showRangeInfoModal}
          />
        </Row>

        <Modal
          visible={resetModalVisible}
          onCancel={closeResetModal}
          footer={null}
          width={"40%"}
          centered={true}
          className={
            Theme.BACKGROUND === "#000"
              ? "customAntdModalDark"
              : "customAntdModalLight"
          }
        >
          <Col align="center">
            <div className={css(styles2.title)}>
              <p
                className={css(styles2.titleText)}
                style={{ color: Theme.TEXT }}
              >
                Select Range Template
              </p>
            </div>

            <Row
              justify={"center"}
              style={{ bottom: 25, marginBottom: ".75vw" }}
            >
              <CustomSelect
                style={{ width: "9vw" }}
                showSearch
                suffixIcon={null}
                options={profileOptions}
                value={selectedRangeTemplate}
                placeholder={
                  <span
                    style={{
                      color: Theme.BACKGROUND === "#000" ? "#fff" : "#000",
                      fontSize: ".7vw",
                      top: ".05vw",
                      position: "relative",
                      fontWeight: "normal"
                    }}
                  >
                    Choose Range Profile
                  </span>
                }
                size={"large"}
                lightModeBackground="#f1f1fb"
                darkModeBackground="#000"
                onChange={(value) => setSelectedRangeTemplate(value)}
              ></CustomSelect>
            </Row>
            <button
              className={css(styles2.createButton)}
              type="submit"
              onClick={handleRangeReset}
              disabled={!selectedRangeTemplate}
              style={{
                cursor: !selectedRangeTemplate ? "not-allowed" : "pointer",
                backgroundColor: !selectedRangeTemplate ? "#ccc" : undefined,
                color: !selectedRangeTemplate ? "#666" : undefined,
                opacity: !selectedRangeTemplate ? 0.6 : 1
              }}
            >
              <div className={css(styles2.buttonGradient)}>
                <p className={css(styles2.buttontext)}>Reset</p>
              </div>
            </button>
          </Col>
        </Modal>

        <Modal
          visible={isRangeInfoModalVisible}
          onCancel={() => seRangeInfoModalVisible(false)}
          width={modalContent.name === "CMJ" ? "55vw" : "25vw"}
          bodyStyle={{
            paddingBottom: "10vw",
            paddingTop: "1vw",
            paddingLeft: "1vw",
            paddingRight: "1vw",
            minHeight: "25vw"
          }}
          className={
            Theme.BACKGROUND === "#000"
              ? "customAntdModalDark"
              : "customAntdModalLight"
          }
          footer={[<div></div>]}
        >
          <Title style={{ color: Theme.TEXT }} level={3}>
            {modalContent.name}
          </Title>
          {modalContent.description &&
            modalContent.description.map((desc, index) => (
              <p key={index} style={{ fontSize: "1.5rem", color: Theme.TEXT }}>
                {desc}
              </p>
            ))}
        </Modal>
      </Modal>
    </Card>
  );
};

export default ClientProfile;

const styles2 = StyleSheet.create({
  title: {
    color: "#232b2b",
    fontSize: 18,

    bottom: 25,
    fontWeight: "600",
    alignSelf: "center"
  },

  titleText: { fontSize: "1vw" },
  createButton: {
    width: "5vw",
    marginTop: "2%",
    backgroundImage: "linear-gradient(45deg, #C04848 0%, #480048 80%)",
    borderWidth: 0,
    borderRadius: 10
  },
  buttontext: {
    color: Theme.COLORS.WHITE,
    fontWeight: "bold",
    marginHorizontal: 5
    // paddingHorizontal: 5,
  }
});
