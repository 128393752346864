import {
  GET_WORKOUTS,
  CREATE_WORKOUT_SUCCESS,
  CREATE_WORKOUT_FAIL,
  UPDATE_WORKOUT,
  DELETE_WORKOUT,
  GET_EXERCISES,
  CREATE_EXERCISE_SUCCESS,
  CREATE_EXERCISE_FAIL,
  UPDATE_EXERCISE,
  DELETE_EXERCISE,
  RESET_WORKOUT_STATUS,
  RESET_EXERCISE_STATUS,
  GET_SINGLE_WORKOUT,
  CREATE_LIBRARY_EXERCISE_SUCCESS,
  CREATE_LIBRARY_EXERCISE_FAIL,
  CREATE_MESOCYCLE_SUCCESS,
  CREATE_MESOCYCLE_FAIL,
  GET_MEOSCYCLES,
  GET_SINGLE_MESOCYCLE,
  UPDATE_MESOCYCLE,
  DELETE_MESOCYCLE,
  RESET_MESOCYCLE_STATUS,
  CREATE_PROGRAM_FAIL,
  UPDATE_PROGRAM,
  GET_SINGLE_PROGRAM,
  RESET_PROGRAM_STATUS,
  DELETE_PROGRAM,
  CREATE_PROGRAM_SUCCESS,
  GET_PROGRAMS,
  COPY_WORKOUT_SUCCESS,
  COPY_WEEK_SUCCESS,
  COPY_WEEK_FAIL,
  CREATE_WORKOUT_TEMPLATE_SUCCESS,
  CREATE_WORKOUT_TEMPLATE_FAIL,
  GET_WORKOUT_TEMPLATES,
  GET_SINGLE_WORKOUT_TEMPLATE,
  UPDATE_WORKOUT_TEMPLATE,
  DELETE_WORKOUT_TEMPLATE,
  RESET_WORKOUT_TEMPLATE_STATUS,
  ASSIGN_WORKOUT_TEMPLATE_SUCCESS,
  CREATE_BLOCK_TEMPLATE_SUCCESS,
  CREATE_BLOCK_TEMPLATE_FAIL,
  GET_BLOCK_TEMPLATES,
  GET_SINGLE_BLOCK_TEMPLATE,
  UPDATE_BLOCK_TEMPLATE,
  DELETE_BLOCK_TEMPLATE,
  ASSIGN_BLOCK_TEMPLATE_SUCCESS,
  RESET_BLOCK_TEMPLATE_STATUS,
  COPY_TEMPLATE_WEEK_SUCCESS,
  COPY_TEMPLATE_WEEK_FAIL,
  RESET_PROGRAM_TEMPLATE_STATUS,
  ASSIGN_PROGRAM_TEMPLATE_SUCCESS,
  CREATE_PROGRAM_TEMPLATE_FAIL,
  DELETE_PROGRAM_TEMPLATE,
  UPDATE_PROGRAM_TEMPLATE,
  GET_SINGLE_PROGRAM_TEMPLATE,
  GET_PROGRAM_TEMPLATES,
  CREATE_PROGRAM_TEMPLATE_SUCCESS
} from "../actions/types";

const initialState = {
  workouts: [],
  workout: null,
  exercises: [],
  workoutStatus: null,
  exerciseStatus: null,
  mesocycles: [],
  mesocycle: null,
  mesocycleStatus: null,
  programs: [],
  program: null,
  programStatus: null,
  total_exercises: 0,
  workoutTemplates: [],
  workoutTemplate: null,
  workoutTemplateStatus: null,
  blockTemplates: [],
  blockTemplate: null,
  blockTemplateStatus: null,
  programTemplates: [],
  programTemplate: null,
  programTemplateStatus: null
};

const workoutsAndExercisesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_WORKOUTS:
      return {
        ...state,
        workouts: action.payload
      };
    case GET_SINGLE_WORKOUT:
      return {
        ...state,
        workout: action.payload
      };
    case CREATE_WORKOUT_SUCCESS:
      return {
        ...state,
        workoutStatus: "success",
        workouts: [...state.workouts, action.payload[0]],
        workout: action.payload
      };
    case CREATE_WORKOUT_FAIL:
      return {
        ...state,
        workoutStatus: "fail"
      };
    case UPDATE_WORKOUT:
      const updateIndex = state.workouts.findIndex(
        (workout) => workout.id === action.payload.id
      );

      if (updateIndex !== -1) {
        const updatedWorkouts = [...state.workouts];
        updatedWorkouts[updateIndex] = action.payload;
        return {
          ...state,
          workouts: updatedWorkouts,
          workout: action.payload,
          workoutStatus: "updated"
        };
      } else {
        return {
          ...state,
          workout: action.payload,
          workoutStatus: "updated"
        };
      }
    case DELETE_WORKOUT:
      const deleteIndex = state.workouts.findIndex(
        (workout) => workout.id === action.payload
      );
      if (deleteIndex !== -1) {
        const updatedWorkouts = [...state.workouts];
        updatedWorkouts.splice(deleteIndex, 1);
        return {
          ...state,
          workouts: updatedWorkouts,
          workoutStatus: "deleted"
        };
      } else {
        return {
          ...state,
          workoutStatus: "deleted"
        };
      }
    case GET_EXERCISES:
      return {
        ...state,
        exercises: action.payload,
        total_exercises: action.total_count
      };
    case CREATE_EXERCISE_SUCCESS:
      return {
        ...state,
        exerciseStatus: "success"
      };
    case CREATE_EXERCISE_FAIL:
      return {
        ...state,
        exerciseStatus: "fail"
      };
    case CREATE_LIBRARY_EXERCISE_SUCCESS:
      return {
        ...state,
        exerciseStatus: "success"
      };
    case CREATE_LIBRARY_EXERCISE_FAIL:
      return {
        ...state,
        exerciseStatus: "fail"
      };
    case UPDATE_EXERCISE:
      // Handle the update exercise logic
      return state;
    case DELETE_EXERCISE:
      // Handle the delete exercise logic
      return state;
    case RESET_WORKOUT_STATUS:
      return {
        ...state,
        workoutStatus: null
      };
    case RESET_EXERCISE_STATUS:
      return {
        ...state,
        exerciseStatus: null
      };
    case CREATE_MESOCYCLE_SUCCESS:
      return {
        ...state,
        mesocycle: action.payload,
        mesocycleStatus: "success"
      };

    case CREATE_MESOCYCLE_FAIL:
      return {
        ...state,
        mesocycleStatus: "fail"
      };

    case GET_MEOSCYCLES:
      return {
        ...state,
        mesocycles: action.payload
      };

    case GET_SINGLE_MESOCYCLE:
      return {
        ...state,
        mesocycle: action.payload
      };

    case UPDATE_MESOCYCLE:
      return {
        ...state,
        mesocycle: action.payload,
        mesocycleStatus: "updated"
      };

    case DELETE_MESOCYCLE:
      return {
        ...state,
        mesocycleStatus: "deleted"
      };

    case RESET_MESOCYCLE_STATUS:
      return {
        ...state,
        mesocycleStatus: null
      };
    case CREATE_PROGRAM_SUCCESS:
      return {
        ...state,
        program: action.payload,
        programStatus: "success"
      };

    case CREATE_PROGRAM_FAIL:
      return {
        ...state,
        programStatus: "fail"
      };

    case GET_PROGRAMS:
      return {
        ...state,
        programs: action.payload
      };

    case GET_SINGLE_PROGRAM:
      return {
        ...state,
        program: action.payload
      };

    case UPDATE_PROGRAM:
      return {
        ...state,
        programStatus: "updated"
      };

    case DELETE_PROGRAM:
      return {
        ...state,
        programStatus: "deleted"
      };

    case RESET_PROGRAM_STATUS:
      return {
        ...state,
        programStatus: null
      };

    case COPY_WORKOUT_SUCCESS:
      return {
        ...state,
        workoutStatus: "success",
        workouts: [...state.workouts, ...action.payload]
      };

    case COPY_WEEK_SUCCESS:
      return {
        ...state,
        mesocycleStatus: "week success"
      };

    case COPY_WEEK_FAIL:
      return {
        ...state,
        mesocycleStatus: "week fail"
      };
    case COPY_TEMPLATE_WEEK_SUCCESS:
      return {
        ...state,
        blockTemplateStatus: "week success"
      };

    case COPY_TEMPLATE_WEEK_FAIL:
      return {
        ...state,
        blockTemplateStatus: "week fail"
      };

    case GET_WORKOUT_TEMPLATES:
      return {
        ...state,
        workoutTemplates: action.payload
      };
    case GET_SINGLE_WORKOUT_TEMPLATE:
      return {
        ...state,
        workoutTemplate: action.payload
      };
    case CREATE_WORKOUT_TEMPLATE_SUCCESS:
      return {
        ...state,
        workoutTemplateStatus: "success",
        workoutTemplates: [...state.workoutTemplates, action.payload[0]],
        workoutTemplate: action.payload
      };
    case CREATE_WORKOUT_TEMPLATE_FAIL:
      return {
        ...state,
        workoutTemplateStatus: "fail"
      };
    case UPDATE_WORKOUT_TEMPLATE:
      const updateWTIndex = state.workoutTemplates.findIndex(
        (workoutTemplate) => workoutTemplate.id === action.payload.id
      );

      if (updateWTIndex !== -1) {
        const updatedWorkoutTemplates = [...state.workoutTemplates];
        updatedWorkoutTemplates[updateWTIndex] = action.payload;
        return {
          ...state,
          workoutTemplates: updatedWorkoutTemplates,
          workoutTemplate: action.payload,
          workoutTemplateStatus: "updated"
        };
      } else {
        return {
          ...state,
          workoutTemplate: action.payload,
          workoutTemplateStatus: "updated"
        };
      }
    case RESET_WORKOUT_TEMPLATE_STATUS:
      return {
        ...state,
        workoutTemplateStatus: null
      };
    case DELETE_WORKOUT_TEMPLATE:
      const deleteWTIndex = state.workoutTemplates.findIndex(
        (workoutTemplate) => workoutTemplate.id === action.payload
      );
      if (deleteWTIndex !== -1) {
        const updatedWorkoutTemplates = [...state.workoutTemplates];
        updatedWorkoutTemplates.splice(deleteWTIndex, 1);
        return {
          ...state,
          workoutTemplates: updatedWorkoutTemplates,
          workoutTemplateStatus: "deleted"
        };
      } else {
        return {
          ...state,
          workoutTemplateStatus: "deleted"
        };
      }
    case ASSIGN_WORKOUT_TEMPLATE_SUCCESS:
      return {
        ...state,
        workoutTemplateStatus: "success"
      };

    case GET_BLOCK_TEMPLATES:
      return {
        ...state,
        blockTemplates: action.payload
      };
    case GET_SINGLE_BLOCK_TEMPLATE:
      return {
        ...state,
        blockTemplate: action.payload
      };
    case CREATE_BLOCK_TEMPLATE_SUCCESS:
      return {
        ...state,
        blockTemplateStatus: "success",
        blockTemplates: [...state.blockTemplates, action.payload[0]],
        blockTemplate: action.payload
      };
    case CREATE_BLOCK_TEMPLATE_FAIL:
      return {
        ...state,
        blockTemplateStatus: "fail"
      };
    case UPDATE_BLOCK_TEMPLATE:
      const updateBTIndex = state.blockTemplates.findIndex(
        (blockTemplate) => blockTemplate.id === action.payload.id
      );

      if (updateBTIndex !== -1) {
        const updatedBlockTemplates = [...state.blockTemplates];
        updatedBlockTemplates[updateBTIndex] = action.payload;
        return {
          ...state,
          blockTemplates: updatedBlockTemplates,
          blockTemplate: action.payload,
          blockTemplateStatus: "updated"
        };
      } else {
        return {
          ...state,
          blockTemplate: action.payload,
          blockTemplateStatus: "updated"
        };
      }
    case RESET_BLOCK_TEMPLATE_STATUS:
      return {
        ...state,
        blockTemplateStatus: null
      };
    case DELETE_BLOCK_TEMPLATE:
      const deleteBTIndex = state.blockTemplates.findIndex(
        (blockTemplate) => blockTemplate.id === action.payload
      );
      if (deleteBTIndex !== -1) {
        const updatedBlockTemplates = [...state.blockTemplates];
        updatedBlockTemplates.splice(deleteBTIndex, 1);
        return {
          ...state,
          blockTemplates: updatedBlockTemplates,
          blockTemplateStatus: "deleted"
        };
      } else {
        return {
          ...state,
          blockTemplateStatus: "deleted"
        };
      }
    case ASSIGN_BLOCK_TEMPLATE_SUCCESS:
      return {
        ...state,
        mesocycle: action.payload,
        blockTemplateStatus: "success"
      };

    case GET_PROGRAM_TEMPLATES:
      return {
        ...state,
        programTemplates: action.payload
      };
    case GET_SINGLE_PROGRAM_TEMPLATE:
      return {
        ...state,
        programTemplate: action.payload
      };
    case CREATE_PROGRAM_TEMPLATE_SUCCESS:
      return {
        ...state,
        programTemplateStatus: "success",
        programTemplates: [...state.programTemplates, action.payload[0]],
        programTemplate: action.payload
      };
    case CREATE_PROGRAM_TEMPLATE_FAIL:
      return {
        ...state,
        programTemplateStatus: "fail"
      };
    case UPDATE_PROGRAM_TEMPLATE:
      const updatePTIndex = state.programTemplates.findIndex(
        (programTemplate) => programTemplate.id === action.payload.id
      );

      if (updatePTIndex !== -1) {
        const updatedProgramTemplates = [...state.programTemplates];
        updatedProgramTemplates[updatePTIndex] = action.payload;
        return {
          ...state,
          programTemplates: updatedProgramTemplates,
          programTemplate: action.payload,
          programTemplateStatus: "updated"
        };
      } else {
        return {
          ...state,
          programTemplate: action.payload,
          programTemplateStatus: "updated"
        };
      }
    case RESET_PROGRAM_TEMPLATE_STATUS:
      return {
        ...state,
        programTemplateStatus: null
      };
    case DELETE_PROGRAM_TEMPLATE:
      const deletePTIndex = state.programTemplates.findIndex(
        (programTemplate) => programTemplate.id === action.payload
      );
      if (deletePTIndex !== -1) {
        const updatedProgramTemplates = [...state.programTemplates];
        updatedProgramTemplates.splice(deletePTIndex, 1);
        return {
          ...state,
          programTemplates: updatedProgramTemplates,
          programTemplateStatus: "deleted"
        };
      } else {
        return {
          ...state,
          programTemplateStatus: "deleted"
        };
      }
    case ASSIGN_PROGRAM_TEMPLATE_SUCCESS:
      return {
        ...state,
        program: action.payload,
        programTemplateStatus: "success"
      };

    default:
      return state;
  }
};

export default workoutsAndExercisesReducer;
