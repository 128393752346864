import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Typography, Button, Spin } from "antd";
import { FileAddOutlined } from "@ant-design/icons";
import { StyleSheet } from "aphrodite";

import BootstrapSearch from "../common/Search";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  assignBlockTemplate,
  getWorkouts,
  getBlockTemplates,
  resetBlockTemplateStatus
} from "../../redux/actions/workouts";
import StyledDatePickerComponent from "../common/DatePickerWorkouts";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import meso from "../../assets/images/mesocycle.png";

const { Text } = Typography;

function BlockTemplateModal({ closeModal, fromTrainerGroup }) {
  const dispatch = useDispatch();
  const { client } = useSelector((state) => state.clients);
  const { blockTemplates, blockTemplateStatus, mesocycle } = useSelector(
    (state) => state.workouts
  );
  const { trainer_group } = useSelector((state) => state.trainer_groups);
  const [isLoading, setLoading] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [assignBlocktId, setAssignBlocktId] = useState(null);
  const [selectedDate, setSelectedDate] = useState("");
  const [error, setError] = useState(false);
  const history = useHistory();

  const handleSubmit = async () => {
    setError(false);
    const newDate = new Date(selectedDate);
    const formattedDate = newDate.toISOString();
    const indexOfT = formattedDate.indexOf("T");
    const dateBeforeT = formattedDate.substring(0, indexOfT);
    const data = {
      client_ids: fromTrainerGroup
        ? trainer_group.clients.map((client) => client.id)
        : [client?.id],
      start_date: dateBeforeT
    };
    dispatch(assignBlockTemplate(assignBlocktId, data));
  };

  useEffect(() => {
    setError(false);
    setAssignBlocktId(null);
    setSelectedDate("");
  }, [client?.id, closeModal]);

  useEffect(() => {
    if (blockTemplateStatus === "success") {
      dispatch(getWorkouts(client?.id));
      closeModal();
      if (!fromTrainerGroup) {
        history.push(`/block/${client.id}/${mesocycle[0].id}`);
      }
      dispatch(resetBlockTemplateStatus());
    } else if (blockTemplateStatus === "fail") {
      setError(true);
    }
  }, [client?.id, closeModal, dispatch, blockTemplateStatus]);

  const { Theme } = useSelector((state) => state.auth);

  useEffect(() => {
    AOS.init();
    dispatch(getBlockTemplates());
    setLoading(false);
  }, []);

  useEffect(() => {
    if (blockTemplateStatus === "deleted") {
      dispatch(getBlockTemplates());
      dispatch(resetBlockTemplateStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockTemplateStatus]);

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value.toLowerCase());
  };

  const filteredTemplates = blockTemplates
    ? blockTemplates.filter((template) =>
        template?.name?.toLowerCase().includes(searchInput)
      )
    : blockTemplates;

  const buttonDisabled =
    blockTemplates?.length === 0 ||
    selectedDate === "" ||
    assignBlocktId === null;

  if (isLoading) {
    return (
      <Spin
        size="large"
        style={{ position: "absolute", top: "50%", left: "50%" }}
      />
    );
  }

  return (
    <Col align={"middle"} justify={"center"} style={{ position: "relative" }}>
      <Col
        align={"middle"}
        justify={"center"}
        bordered={true}
        style={{
          background: Theme.CARD,
          borderWidth: 0,
          paddingBottom: "3vw"
        }}
        className="criclebox cardbody"
      >
        <Text
          style={{
            fontWeight: "bold",
            fontSize: "1dvw",
            marginBottom: "3vw",
            top: "1vw",
            position: "relative",
            color: Theme.TEXT,
            justify: "center"
          }}
        >
          Select Template and Start Date
        </Text>
        <Col
          align="middle"
          justify="center"
          style={{ position: "absolute", right: 75 }}
        >
          <Button
            color={"PRIMARY"}
            style={{
              width: "8vw",
              cursor: "pointer",
              zIndex: 999,
              backgroundImage: buttonDisabled
                ? Theme.MUTED
                : "linear-gradient(60deg, #C04848 25%, #480048 120%)",
              borderRadius: 10,
              borderWidth: 0,
              opacity: 0.9,
              background: buttonDisabled
                ? Theme.MUTED
                : "linear-gradient(60deg, #C04848 25%, #480048 120%)"
            }}
            onClick={() => handleSubmit()}
            disabled={buttonDisabled}
          >
            <Text
              style={{
                color: "white",
                fontSize: ".75vw",
                textAlign: "center",
                opacity: 1,
                fontWeight: "bold"
              }}
            >
              Create
            </Text>
          </Button>
          <div>
            {error && (
              <Text
                style={{
                  fontSize: ".65vw",
                  color: "red"
                }}
              >
                Error - Please Try Again
              </Text>
            )}
          </div>
        </Col>

        <Row align={"middle"} justify={"center"}>
          <Col
            style={{ position: "relative", marginTop: "4vw", width: "100%" }}
            justify="start"
          >
            <Row
              className="project-ant"
              style={{
                position: "relative",
                left: "2vw"
              }}
            >
              <Col style={styles.fieldrow}>
                <BootstrapSearch
                  placeholder="Search Templates"
                  value={searchInput}
                  width="15vw"
                  onChange={handleSearchChange}
                />
              </Col>
              <Col style={{ right: "34%", position: "relative" }}>
                <StyledDatePickerComponent
                  selected={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                  placeholder={"Start Date"}
                  width={"2vw"}
                  onClear={() => setSelectedDate(null)}
                />
              </Col>
            </Row>

            {/* Render Profiles */}
            {filteredTemplates.length > 0 ? (
              <div
                style={{ paddingLeft: "4vw" }}
                className="ant-list-box table-responsive"
              >
                <table className="width-100">
                  <thead></thead>
                  <tbody>
                    {filteredTemplates.map((d, index) => (
                      <tr key={index}>
                        <td
                          style={{
                            fontSize: ".75vw",
                            width: "50%",
                            cursor: "pointer"
                          }}
                        >
                          <Row
                            style={{
                              fontSize: "1vw",
                              position: "relative",
                              right: "1.25vw"
                            }}
                          >
                            <p
                              style={{
                                position: "relative",

                                fontSize: ".85vw",
                                color: Theme.TEXT
                              }}
                            >
                              {d?.name}
                            </p>
                          </Row>
                        </td>

                        <td
                          style={{
                            fontSize: ".85vw",
                            color: Theme.TEXT,
                            left: "3vw",
                            position: "relative"
                          }}
                        >
                          <Row align={"middle"} justify={"center"}>
                            <Button
                              onClick={() => {
                                setAssignBlocktId(d?.id);
                              }}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color: "white",
                                borderWidth: 0,
                                borderRadius: 5,
                                height: "1.5vw",
                                width: "5vw",
                                fontWeight: "bold",

                                cursor: "pointer",
                                background:
                                  d?.id === assignBlocktId
                                    ? "#000"
                                    : Theme.PRIMARY
                              }}
                            >
                              <Row align={"middle"}>
                                <div>
                                  {d?.id === assignBlocktId ? (
                                    <Text
                                      style={{
                                        fontSize: ".75vw",
                                        color: "white"
                                      }}
                                    >
                                      Selected
                                    </Text>
                                  ) : (
                                    <Text
                                      style={{
                                        fontSize: ".75vw",
                                        color: "white"
                                      }}
                                    >
                                      Select
                                    </Text>
                                  )}
                                </div>
                              </Row>
                            </Button>
                          </Row>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <Col
                style={{
                  textAlign: "center",
                  marginTop: "5vw",
                  marginBottom: "2vw",
                  width: "30%"
                }}
              >
                <img
                  style={{
                    height: "3vw",
                    width: "3vw",
                    objectFit: "cover"
                  }}
                  src={meso}
                  alt="programs"
                />
                <Text
                  style={{
                    display: "block",
                    color: Theme.TEXT,
                    marginTop: 10,
                    fontSize: "1vw",
                    fontWeight: "bold"
                  }}
                >
                  No Templates
                </Text>
                <Text
                  style={{
                    display: "block",
                    color: Theme.TEXT,
                    marginTop: 10,
                    fontSize: ".8vw",
                    textAlign: "center"
                  }}
                >
                  Create block templates from the templates section in the side
                  navigation panel
                </Text>
              </Col>
            )}
          </Col>
        </Row>
      </Col>
    </Col>
  );
}

export default BlockTemplateModal;

const styles = StyleSheet.create({
  fieldrow: {
    marginBottom: "1vw"
  }
});
