import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Col,
  Row,
  Typography,
  Spin,
  InputNumber
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  createWorkoutTemplate,
  getMesocycles,
  getSingleBlockTemplate,
  getWorkoutTemplates,
  resetWorkoutTemplateStatus
} from "../../redux/actions/workouts";

import ExerciseList from "../workouts/ExerciseList";
import Library from "../workouts/Library";
import { TextField } from "@mui/material";

const { Text } = Typography;

const CreateWorkoutTemplateForm = ({
  closeModal,
  closeModalSuccess,
  meso,
  initExercises
}) => {
  const dispatch = useDispatch();
  const { client } = useSelector((state) => state.clients);
  const { Theme } = useSelector((state) => state.auth);
  const [form] = Form.useForm();
  const [chosenExercises, setChosenExercises] = useState([]);

  const { workoutTemplateStatus } = useSelector((state) => state.workouts);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedMesocycle, setSelectedMesocycle] = useState(
    meso ? meso : null
  );

  useEffect(() => {
    if (initExercises && initExercises.length > 0) {
      setChosenExercises(initExercises);
    } else {
      setChosenExercises([]);
    }
    dispatch(getMesocycles(client.id));
  }, [client.id, dispatch, initExercises]);

  useEffect(() => {
    if (meso) {
      setSelectedMesocycle([{ id: meso[0]?.id }]);
    }
  }, [meso]);

  const handleCloseModal = () => {
    closeModal();
    form.resetFields();
    setSelectedMesocycle(null);
  };

  const handleExerciseSelect = (exercises, isSupersetChecked = false) => {
    setChosenExercises((prevExercises) => {
      const currentExercises = JSON.parse(
        JSON.stringify(Array.isArray(prevExercises) ? prevExercises : [])
      ); // Deep copy and ensure it's always an array
      let newExercises = Array.isArray(exercises) ? exercises : [exercises]; // Ensure it's always an array

      newExercises = newExercises.map((exercise, index) => ({
        ...exercise,
        order: currentExercises.length + index // Update order based on existing exercises count
      }));

      if (isSupersetChecked) {
        // If it's a superset, add the new exercises as a single superset entry
        return [
          ...currentExercises,
          {
            isSuperset: true,
            exercises: newExercises,
            order: currentExercises.length
          }
        ];
      } else {
        // If not a superset, just concatenate the new exercises
        return [...currentExercises, ...newExercises];
      }
    });
  };

  const handleAddSet = (exerciseIndex, sourceSetIndex, subIndex) => {
    setChosenExercises((prevExercises) => {
      let updatedExercises = JSON.parse(JSON.stringify(prevExercises));
      // Define a function to create a new set based on the existing source set or default values
      const createNewSet = (sourceSet) => {
        return sourceSet
          ? {
              weight: { ...sourceSet.weight },
              params: sourceSet.params.map((param) => ({ ...param }))
            }
          : {
              weight: { type: "% Difficulty", value: "" },
              params: [
                { type: "Reps", value: "" },
                { type: "Rest", value: "" }
              ]
            };
      };

      if (typeof subIndex !== "undefined") {
        // Handling for sub-exercise within a superset
        const superset = updatedExercises[exerciseIndex];
        if (superset && superset.exercises && superset.exercises[subIndex]) {
          const subExercise = superset.exercises[subIndex];
          if (!subExercise.sets) {
            subExercise.sets = [];
          }
          const sourceSet = subExercise.sets[sourceSetIndex];
          subExercise.sets.push(createNewSet(sourceSet));
        }
      } else {
        const exercise = updatedExercises[exerciseIndex];
        if (exercise && exercise.sets) {
          const sourceSet = exercise.sets[sourceSetIndex];
          exercise.sets.push(createNewSet(sourceSet));
        }
      }

      return updatedExercises;
    });
  };

  const handleRemoveSet = (index, setIndex, subIndex) => {
    setChosenExercises((prevExercises) => {
      let updatedExercises = JSON.parse(JSON.stringify(prevExercises));
      if (
        typeof subIndex !== "undefined" &&
        updatedExercises[index]?.exercises[subIndex]?.sets
      ) {
        updatedExercises[index].exercises[subIndex].sets.splice(setIndex, 1);
      } else if (updatedExercises[index]?.sets) {
        updatedExercises[index].sets.splice(setIndex, 1);
      }

      return updatedExercises;
    });
  };

  const handleRemoveExercise = (index, subIndex) => {
    setChosenExercises((prevExercises) => {
      let updatedExercises = JSON.parse(JSON.stringify(prevExercises));

      if (typeof subIndex !== "undefined") {
        const targetExercise = updatedExercises[index];

        if (targetExercise.isSuperset) {
          targetExercise.exercises = targetExercise.exercises.filter(
            (_, si) => si !== subIndex
          );

          if (targetExercise.exercises.length === 0) {
            return updatedExercises.filter((_, i) => i !== index);
          } else {
            updatedExercises[index] = targetExercise;
            return updatedExercises;
          }
        }
      } else {
        updatedExercises = updatedExercises.filter((_, i) => i !== index);
      }
      // Return the previous exercises by default
      return updatedExercises;
    });
  };

  const handleParamTypeChange = (
    exerciseIndex,
    setIndex,
    paramIndex,
    value,
    subIndex
  ) => {
    setChosenExercises((prevExercises) => {
      const updatedExercises = [...prevExercises];

      if (typeof subIndex !== "undefined") {
        if (
          updatedExercises[exerciseIndex]?.exercises[subIndex]?.sets[setIndex]
            ?.params
        ) {
          updatedExercises[exerciseIndex].exercises[subIndex].sets[
            setIndex
          ].params[paramIndex].type = value;
        }
      } else {
        if (updatedExercises[exerciseIndex]?.sets[setIndex]?.params) {
          updatedExercises[exerciseIndex].sets[setIndex].params[
            paramIndex
          ].type = value;
        }
      }

      return updatedExercises;
    });
  };

  const handleRemoveParamFromSet = (
    exerciseIndex,
    setIndex,
    paramIndex,
    subIndex
  ) => {
    setChosenExercises((prevExercises) => {
      const updatedExercises = [...prevExercises];

      if (typeof subIndex !== "undefined") {
        updatedExercises[exerciseIndex].exercises[subIndex].sets[
          setIndex
        ].params.splice(paramIndex, 1);
      } else {
        updatedExercises[exerciseIndex].sets[setIndex].params.splice(
          paramIndex,
          1
        );
      }

      return updatedExercises;
    });
  };

  const handleFormSubmit = (values) => {
    setError(false);
    setLoading(true);
    const workoutData = {
      title: values?.title,
      notes: values?.notes,
      exercises: chosenExercises,
      meso_template: meso ? meso.id : null,
      week: meso ? values?.week : null,
      day: meso ? values?.day : null
    };
    dispatch(createWorkoutTemplate(workoutData));
    setLoading(false);
  };

  // const handleReorder = (dragIndex, hoverIndex) => {
  //   const draggedExercise = chosenExercises[dragIndex];
  //   setChosenExercises((prevExercises) => {
  //     const updatedExercises = [...prevExercises];
  //     updatedExercises.splice(dragIndex, 1);
  //     updatedExercises.splice(hoverIndex, 0, draggedExercise);
  //     return updatedExercises;
  //   });
  // };

  const handleExerciseInputChange = (index, field, value) => {
    setChosenExercises((prevExercises) =>
      prevExercises.map((exercise, i) =>
        i === index
          ? { ...exercise, [field]: { ...exercise[field], value: value } }
          : exercise
      )
    );
  };

  const handleParamValueChange = (
    exerciseIndex,
    setIndex,
    paramIndex,
    value,
    subIndex
  ) => {
    setChosenExercises((prevExercises) => {
      let updatedExercises = JSON.parse(JSON.stringify(prevExercises));
      if (typeof subIndex !== "undefined") {
        updatedExercises[exerciseIndex].exercises[subIndex].sets[
          setIndex
        ].params[paramIndex].value = value;
      } else {
        updatedExercises[exerciseIndex].sets[setIndex].params[
          paramIndex
        ].value = value;
      }

      return updatedExercises;
    });
  };

  const handleAddParamToSet = (exerciseIndex, setIndex, subIndex) => {
    setChosenExercises((prevExercises) => {
      let updatedExercises = JSON.parse(JSON.stringify(prevExercises));

      // Determine the new parameter type based on the number of existing parameters
      const getNewParamType = (params) => {
        switch (params.length) {
          case 0:
            return "Reps";
          case 1:
            return "Rest";
          case 2:
            return "Time";
          default:
            return "Reps"; // Default or adjust as needed
        }
      };

      if (typeof subIndex !== "undefined") {
        const subExercise = updatedExercises[exerciseIndex].exercises[subIndex];
        const newParamType = getNewParamType(subExercise.sets[setIndex].params);
        subExercise.sets[setIndex].params.push({
          type: newParamType,
          value: ""
        });
      } else {
        // Adding new param to a regular exercise set
        const exercise = updatedExercises[exerciseIndex];
        const newParamType = getNewParamType(exercise.sets[setIndex].params);
        exercise.sets[setIndex].params.push({ type: newParamType, value: "" });
      }

      return updatedExercises;
    });
  };

  const handleWeightTypeChange = (exerciseIndex, setIndex, value, subIndex) => {
    setChosenExercises((prevExercises) => {
      let updatedExercises = JSON.parse(JSON.stringify(prevExercises));
      if (typeof subIndex !== "undefined") {
        if (
          updatedExercises[exerciseIndex]?.exercises[subIndex]?.sets[setIndex]
            ?.weight
        ) {
          updatedExercises[exerciseIndex].exercises[subIndex].sets[
            setIndex
          ].weight.type = value;
        }
      } else {
        if (updatedExercises[exerciseIndex]?.sets[setIndex]?.weight) {
          updatedExercises[exerciseIndex].sets[setIndex].weight.type = value;
        }
      }

      return updatedExercises;
    });
  };

  const handleWeightValueChange = (
    exerciseIndex,
    setIndex,
    value,
    subIndex
  ) => {
    setChosenExercises((prevExercises) => {
      let updatedExercises = JSON.parse(JSON.stringify(prevExercises));

      if (typeof subIndex !== "undefined") {
        if (
          updatedExercises[exerciseIndex]?.exercises[subIndex]?.sets[setIndex]
            ?.weight
        ) {
          updatedExercises[exerciseIndex].exercises[subIndex].sets[
            setIndex
          ].weight.value = value;
        }
      } else {
        if (updatedExercises[exerciseIndex]?.sets[setIndex]?.weight) {
          updatedExercises[exerciseIndex].sets[setIndex].weight.value = value;
        }
      }

      return updatedExercises;
    });
  };

  useEffect(() => {
    if (workoutTemplateStatus === "success") {
      dispatch(resetWorkoutTemplateStatus());
      dispatch(getWorkoutTemplates());
      if (meso) {
        dispatch(getSingleBlockTemplate(meso?.id));
      }
      closeModal();
      form.resetFields();
      setChosenExercises([]);
    }
    if (workoutTemplateStatus === "fail") {
      setError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workoutTemplateStatus]);

  return (
    <Form
      style={{ background: Theme.CARD, marginTop: "1.5vw" }}
      form={form}
      onFinish={handleFormSubmit}
    >
      <Col justify="center" align="middle">
        <Text
          style={{
            fontWeight: "bold",
            fontSize: "1dvw",
            bottom: "1vw",
            position: "relative",
            color: Theme.TEXT
          }}
        >
          Create Workout Template
        </Text>
        <Col align={"end"} style={{ position: "relative" }}>
          <Row align={"end"} style={{ position: "relative", bottom: "2.5vw" }}>
            <Button
              style={{
                marginRight: "1vw"
              }}
              type="primary"
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            <Form.Item>
              {!loading ? (
                <Button
                  style={{
                    backgroundImage:
                      "linear-gradient(45deg, #C04848 0%, #480048 80%)",
                    border: 0
                  }}
                  type="primary"
                  htmlType="submit"
                >
                  Create
                </Button>
              ) : (
                <Button
                  style={{
                    backgroundImage:
                      "linear-gradient(45deg, #C04848 0%, #480048 80%)",
                    border: 0
                  }}
                  type="primary"
                  htmlType="submit"
                >
                  <Spin size="small" />
                </Button>
              )}
            </Form.Item>
          </Row>

          <div>
            {error && (
              <Text
                style={{
                  fontSize: ".75vw",
                  color: "red",
                  bottom: "3vw",
                  position: "relative"
                }}
              >
                Error - Please Try Again
              </Text>
            )}
          </div>
        </Col>
        <Row
          align="middle"
          justify={"center"}
          style={{ position: "relative", bottom: "2vw" }}
          span={12}
        >
          <Row justify={"center"} align={"middle"}>
            <Form.Item
              name="title"
              style={{
                // marginBottom: "1.5vw",
                marginRight: "1vw"
              }}
              rules={[{ required: true, message: "Title is required" }]}
            >
              <Input
                placeholder="Title"
                className={
                  Theme.BACKGROUND === "#000"
                    ? "custom-placeholder-color-dark"
                    : "custom-placeholder-color-light"
                }
                style={{
                  height: "2vw",
                  color: Theme.TEXT,
                  fontSize: ".75vw",
                  background: Theme.BACKGROUND,
                  borderWidth: Theme.BACKGROUND === "#000" ? 0 : 1
                }}
              />
            </Form.Item>
          </Row>
          <Form.Item
            name="notes"
            style={{ width: "30vw", position: "relative", marginLeft: "3vw" }}
          >
            <Input.TextArea
              className={
                Theme.BACKGROUND === "#000"
                  ? "custom-placeholder-color-dark"
                  : "custom-placeholder-color-light"
              }
              style={{
                color: Theme.TEXT,
                background: Theme.BACKGROUND,
                fontSize: ".75vw",
                height: "2vw",
                borderWidth: Theme.BACKGROUND === "#000" ? 0 : 1
              }}
              placeholder="Instructions"
            />
          </Form.Item>
          {meso && (
            <>
              <Form.Item
                name="week"
                style={{
                  marginBottom: "1.5vw",
                  marginLeft: "3vw"
                }}
                rules={[
                  {
                    required: meso ? true : false,
                    type: "number",
                    min: 1,
                    max: meso?.numOfWeeks,
                    message: "Please enter a valid week number"
                  }
                ]}
              >
                <input
                  type="number"
                  placeholder="Block Week"
                  value={form.getFieldValue("week") || ""}
                  onChange={(e) => {
                    const value = parseInt(e.target.value, 10); // Parse the input value to a number
                    if (!isNaN(value)) {
                      form.setFieldsValue({ week: value }); // Set form value if valid number
                    } else {
                      form.setFieldsValue({ week: null }); // Reset if invalid
                    }
                  }}
                  style={{
                    background: Theme.BACKGROUND,
                    color: Theme.TEXT,
                    fontSize: "0.75vw",
                    border: "none",
                    boxShadow: "none",
                    padding: ".6vw",
                    fontWeight: "bold",
                    outline: "none",
                    borderRadius: 5
                  }}
                  className={
                    Theme.BACKGROUND === "#000"
                      ? "custom-placeholder-color-dark"
                      : "custom-placeholder-color-light"
                  }
                />
              </Form.Item>
              <Form.Item
                name="day"
                style={{
                  marginBottom: "1.5vw",
                  marginLeft: "3vw"
                }}
                rules={[
                  {
                    required: meso ? true : false,
                    type: "number",
                    min: 1,
                    max: 7,
                    message: "Please enter a valid day number"
                  }
                ]}
              >
                <input
                  type="number"
                  placeholder="Day #"
                  value={form.getFieldValue("day") || ""}
                  onChange={(e) => {
                    const value = parseInt(e.target.value, 10); // Parse the input value to a number
                    if (!isNaN(value)) {
                      form.setFieldsValue({ day: value }); // Set form value if valid number
                    } else {
                      form.setFieldsValue({ day: null }); // Reset if invalid
                    }
                  }}
                  style={{
                    background: Theme.BACKGROUND,
                    color: Theme.TEXT,
                    fontSize: "0.75vw",
                    border: "none",
                    boxShadow: "none",
                    padding: ".6vw",
                    fontWeight: "bold",
                    outline: "none",
                    borderRadius: 5
                  }}
                  className={
                    Theme.BACKGROUND === "#000"
                      ? "custom-placeholder-color-dark"
                      : "custom-placeholder-color-light"
                  }
                />
              </Form.Item>
            </>
          )}
        </Row>
        <Row
          justify={"center"}
          align={"center"}
          style={{ position: "relative", bottom: "2vw", width: "85vw" }}
        >
          <Col
            style={{ minHeight: "20vw", width: "39vw", marginBottom: "1vw" }}
          >
            <ExerciseList
              chosenExercises={chosenExercises}
              Theme={Theme}
              handleExerciseInputChange={handleExerciseInputChange}
              handleWeightTypeChange={handleWeightTypeChange}
              handleWeightValueChange={handleWeightValueChange}
              handleParamTypeChange={handleParamTypeChange}
              handleParamValueChange={handleParamValueChange}
              handleAddParamToSet={handleAddParamToSet}
              handleRemoveExercise={handleRemoveExercise}
              handleAddSet={handleAddSet}
              handleRemoveSet={handleRemoveSet}
              handleRemoveParamFromSet={handleRemoveParamFromSet}
              setChosenExercises={setChosenExercises}
            />
          </Col>
          <Col span={12} style={{ marginRight: "1vw" }}>
            <Library
              Theme={Theme}
              handleExerciseSelect={handleExerciseSelect}
              clientID={client.id}
            />
          </Col>
        </Row>
      </Col>
    </Form>
  );
};

export default CreateWorkoutTemplateForm;
