import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Col, Row, Modal, Typography, Button, Spin } from "antd";
import { ExclamationCircleOutlined, FileAddOutlined } from "@ant-design/icons";
import { IoAddCircleOutline } from "react-icons/io5";
import { StyleSheet } from "aphrodite";

import { load_user } from "../../redux/actions/auth";
import BootstrapSearch from "../common/Search";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  deleteProgramTemplate,
  getSingleProgramTemplate,
  getProgramTemplates,
  resetProgramTemplateStatus
} from "../../redux/actions/workouts";
// import AssignProgram from "./AssignProgram";
// import EditProgramTemplate from "./EditProgramTemplate";
import macro from "../../assets/images/macrocycle.png";
import CreateProgramTemplateForm from "./CreateProgramTemplateForm";
import EditProgramTemplate from "./EditProgramTemplate";
import AssignProgram from "./AssignProgram";

const { Text } = Typography;

function ProgramTemplates() {
  const dispatch = useDispatch();
  const [programTemplateCreateModalVisible, setProgramTemplateCreateModal] =
    useState(false);
  const [programTemplateEditModalVisible, setProgramTemplateEditModal] =
    useState(false);
  const [assignModalVisible, setAssignModalVisible] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [assignProgramId, setAssignProgramId] = useState(null);
  const { programTemplates, programTemplateStatus, programTemplate } =
    useSelector((state) => state.workouts);
  const { Theme } = useSelector((state) => state.auth);
  const fetchUser = () => dispatch(load_user());

  useEffect(() => {
    AOS.init();
    fetchUser();
    dispatch(getProgramTemplates());
    setLoading(false);
  }, []);

  const deleteAlert = (id) => {
    Modal.confirm({
      title: "Are you sure you want to delete this Template?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "Cancel",
      onOk: () => dispatch(deleteProgramTemplate(id))
    });
  };

  useEffect(() => {
    if (programTemplateStatus === "deleted") {
      dispatch(getProgramTemplates());
      dispatch(resetProgramTemplateStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programTemplateStatus]);

  const handleCloseModal = () => {
    setProgramTemplateCreateModal(false);
    setProgramTemplateEditModal(false);
  };

  const handleOpenEditModal = () => {
    setProgramTemplateEditModal(true);
  };

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value.toLowerCase());
  };

  const filteredTemplates = programTemplates
    ? programTemplates.filter((template) =>
        template?.name?.toLowerCase().includes(searchInput)
      )
    : programTemplates;

  if (isLoading) {
    return (
      <Spin
        size="large"
        style={{ position: "absolute", top: "50%", left: "50%" }}
      />
    );
  }

  return (
    <Col style={{ position: "relative" }}>
      <Card
        bordered={true}
        style={{
          width: "60vw",
          background: Theme.CARD,
          borderWidth: 0
        }}
        className="criclebox cardbody"
      >
        <Row className="project-ant" style={{ marginBottom: "1.5vw" }}>
          <Col span={12} style={styles.fieldrow}>
            <BootstrapSearch
              placeholder="Search Templates"
              value={searchInput}
              width="15vw"
              onChange={handleSearchChange}
            />
          </Col>
          <Col style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={() => setProgramTemplateCreateModal(true)}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                borderWidth: 0,
                borderRadius: 12,
                height: "2.2vw",
                width: "9vw",
                fontWeight: "bold",

                cursor: "pointer",
                backgroundImage:
                  "linear-gradient(45deg, #C04848 0%, #480048 80%)"
              }}
            >
              <Row align={"middle"}>
                <IoAddCircleOutline size={"1vw"} style={{}} />
                <div>
                  <Text style={{ fontSize: ".75vw", color: "white" }}>
                    New Template
                  </Text>
                </div>
              </Row>
            </Button>
          </Col>
        </Row>

        {/* Render Profiles */}
        {filteredTemplates.length > 0 ? (
          <div
            style={{ paddingLeft: "4vw" }}
            className="ant-list-box table-responsive"
          >
            <table className="width-100">
              <thead>
                <tr>
                  <th style={{ fontSize: ".85vw", color: Theme.TEXT2 }}>
                    NAME
                  </th>
                  <th
                    style={{
                      fontSize: ".85vw",
                      right: "3.5vw",
                      position: "relative"
                    }}
                  ></th>
                </tr>
              </thead>
              <tbody>
                {filteredTemplates.map((d, index) => (
                  <tr key={index}>
                    <td
                      style={{
                        fontSize: ".75vw",
                        width: "50%",
                        cursor: "pointer"
                      }}
                    >
                      <Row style={{ fontSize: "1vw" }}>
                        <p
                          style={{
                            position: "relative",

                            fontSize: ".85vw",
                            color: Theme.TEXT
                          }}
                        >
                          {d?.name}
                        </p>
                      </Row>
                    </td>
                    <td style={{ fontSize: ".85vw", color: Theme.TEXT }}>
                      <span className="text-xs font-weight-bold"></span>
                    </td>
                    <td
                      style={{
                        fontSize: ".85vw",
                        color: Theme.TEXT,
                        left: "4vw",
                        position: "relative"
                      }}
                    >
                      <Row>
                        <Button
                          onClick={() => {
                            setAssignProgramId(d?.id);
                            setAssignModalVisible(true);
                          }}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "white",
                            borderWidth: 0,
                            borderRadius: 12,
                            height: "1.5vw",
                            width: "4vw",
                            fontWeight: "bold",

                            cursor: "pointer",
                            background: "#000"
                          }}
                        >
                          <Text style={{ fontSize: ".75vw", color: "white" }}>
                            Assign
                          </Text>
                        </Button>
                        <Button
                          onClick={() => {
                            setProgramTemplateEditModal(true);
                            dispatch(getSingleProgramTemplate(d?.id));
                          }}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "white",
                            borderWidth: 0,
                            borderRadius: 12,
                            height: "1.5vw",
                            width: "4vw",
                            fontWeight: "bold",
                            marginRight: "1vw",
                            marginLeft: "1vw",
                            cursor: "pointer",
                            background: "#000"
                          }}
                        >
                          <Text style={{ fontSize: ".75vw", color: "white" }}>
                            Edit
                          </Text>
                        </Button>
                        <Button
                          onClick={() => deleteAlert(d.id)}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "white",
                            borderWidth: 0,
                            borderRadius: 12,
                            height: "1.5vw",
                            width: "4vw",
                            fontWeight: "bold",

                            cursor: "pointer",
                            background: "#000"
                          }}
                        >
                          <Text style={{ fontSize: ".75vw", color: "white" }}>
                            Delete
                          </Text>
                        </Button>
                      </Row>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <Col
            style={{
              textAlign: "center",
              marginTop: "3vw",
              marginBottom: "2vw"
            }}
          >
            <img
              style={{
                height: "3vw",
                width: "3vw",
                objectFit: "cover"
              }}
              src={macro}
              alt="programs"
            />
            <div>
              <Text
                style={{
                  display: "program",
                  color: Theme.TEXT,
                  marginTop: 10,
                  fontSize: "1vw"
                }}
              >
                No Program Templates
              </Text>
            </div>
          </Col>
        )}
      </Card>

      <Modal
        open={programTemplateCreateModalVisible}
        onCancel={() => setProgramTemplateCreateModal(false)}
        footer={[<Col align={"center"} justify={"center"}></Col>]}
        width={"30vw"}
        style={{ top: "2vw" }}
        className={
          Theme.BACKGROUND === "#000"
            ? "customAntdModalDark"
            : "customAntdModalLight"
        }
      >
        <Col align="middle" justify="center">
          <img
            style={{
              height: "4vw",
              width: "4vw",
              objectFit: "cover"
            }}
            src={macro}
            alt="block"
          />
          <div>
            <Text
              style={{
                fontWeight: "bold",
                fontSize: ".95dvw",
                bottom: "1vw",
                position: "relative",
                color: Theme.TEXT,
                justify: "center"
              }}
            >
              Create Program Template
            </Text>
          </div>
        </Col>
        <CreateProgramTemplateForm
          // Pass programData if applicable
          closeModal={handleCloseModal} // Pass handleCloseModal here
          setProgramTemplateEditModal={handleOpenEditModal}
        />
      </Modal>

      <Modal
        open={programTemplateEditModalVisible}
        onCancel={() => setProgramTemplateEditModal(false)}
        footer={[<Col align={"center"} justify={"center"}></Col>]}
        width={"60vw"}
        className={
          Theme.BACKGROUND === "#000"
            ? "customAntdModalDark"
            : "customAntdModalLight"
        }
      >
        <EditProgramTemplate />
      </Modal>

      <Modal
        open={assignModalVisible}
        onCancel={() => setAssignModalVisible(false)}
        cancelButtonProps={{ style: { display: "none" } }}
        footer={[<Col align={"center"} justify={"center"}></Col>]}
        width={"30vw"}
        style={{ top: "5vw" }}
        className={
          Theme.BACKGROUND === "#000"
            ? "customAntdModalDark"
            : "customAntdModalLight"
        }
      >
        <Col>
          <AssignProgram
            closeModal={() => setAssignModalVisible(false)}
            assignProgramId={assignProgramId}
          />
        </Col>
      </Modal>
    </Col>
  );
}

export default ProgramTemplates;

const styles = StyleSheet.create({
  fieldrow: {
    marginBottom: "1vw"
  }
});
