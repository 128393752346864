// Library.js
import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Typography,
  Modal,
  Form,
  Input,
  Upload,
  message,
  notification,
  Spin,
  Pagination,
  Checkbox
} from "antd";
import {
  InfoCircleOutlined,
  UploadOutlined,
  ExclamationCircleOutlined,
  CheckCircleOutlined
} from "@ant-design/icons";
import customblank from "../assets/images/customblank.jpg";

import { createLibraryExercise, getExercises } from "../redux/actions/workouts";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import BootstrapSearch from "../components/common/Search";
import ExerciseFilterModal from "../components/workouts/ExerciseFilterModal";

const { Text } = Typography;

const Library = ({ handleExerciseSelect, clientID }) => {
  const dispatch = useDispatch();
  const { Theme } = useSelector((state) => state.auth);
  const [infoModalVisible, setInfoModalVisible] = useState(false);
  const [createExerciseModalVisible, setCreateExerciseModalVisible] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [initLoading, setInitLoading] = useState(true);

  const [selectedExercise, setSelectedExercise] = useState(null);
  const [form] = Form.useForm();
  const [videoFileList, setVideoFileList] = useState([]);
  const [imageFileList, setImageFileList] = useState([]);
  const [query, setQuery] = useState("");
  const [tags, setTags] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterModal, setFilterModal] = useState(false);
  const [isSuperset, setIsSuperset] = useState(false);
  const [selectedExercises, setSelectedExercises] = useState([]);

  const MAX_FILE_SIZE_MB = 5;
  const { exercises, total_exercises, workoutStatus, mesocycles } = useSelector(
    (state) => state.workouts
  );

  const handleApplyTags = (selectedTags) => {
    setTags(selectedTags);
  };

  const closeFilterModal = (selectedTags) => {
    setFilterModal(false);
  };

  const normFile = (e, fileListSetter) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const paginateChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    setTimeout(() => {
      setInitLoading(false);
    }, 1500);
  }, []);

  useEffect(() => {
    dispatch(getExercises(query, tags, currentPage, clientID));
  }, [query, tags, currentPage]);

  const beforeUpload = (file, fileListSetter, fileType) => {
    const isVideo = fileType === "video";
    const isImage = fileType === "image";

    // Check the file type
    const fileTypeValid = isVideo
      ? file.type.startsWith("video/")
      : isImage
      ? file.type.startsWith("image/")
      : true; // Allow other file types

    if (!fileTypeValid) {
      message.error(
        `Invalid file type. Please upload a ${
          isVideo ? "video" : "image"
        } file.`
      );
      return false; // Prevent default upload behavior
    }

    // Limit the file list to one item
    fileListSetter([file]);

    return false; // Prevent default upload behavior
  };

  const validateFileType = (fileList, fileType) => {
    return fileList.every((file) => {
      const isVideo = fileType === "video";
      const isImage = fileType === "image";
      return (
        (isVideo && file.type.startsWith("video/")) ||
        (isImage && file.type.startsWith("image/"))
      );
    });
  };

  const handleVideoFileChange = (info) => {
    const { file, fileList } = info;

    // Check if the file is valid (video)
    if (file.status === "done" && !file.type.startsWith("video/")) {
      message.error("Invalid file type. Please upload a video file.");
      setVideoFileList(fileList.filter((item) => item.uid !== file.uid));
      return;
    }

    // Update the fileList
    setVideoFileList(fileList);
  };

  const handleImageFileChange = (info) => {
    const { file, fileList } = info;

    // Check if the file is valid (image)
    if (file.status === "done" && !file.type.startsWith("image/")) {
      message.error("Invalid file type. Please upload an image file.");
      // Remove the invalid file from fileList
      setImageFileList(fileList.filter((item) => item.uid !== file.uid));
      return;
    }

    // Update the fileList
    setImageFileList(fileList);
  };

  const onFinish = async (values) => {
    setLoading(true);
    // Validate file types before submitting the form
    const videoFileValid = validateFileType(videoFileList, "video");

    if (!videoFileValid) {
      // Display an error notification indicating invalid file types
      notification.error({
        message: "Invalid File Type",
        description: `Please upload a ${
          videoFileValid ? "valid video" : "video"
        }`
      });
      return;
    }

    // Continue with form submission logic
    try {
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("instructions", values.description);
      formData.append("exercise_type", "custom");

      // Ensure videoFileList[0] is a File object and does not exceed file size limit
      if (videoFileList.length > 0) {
        const videoFile = videoFileList[0].originFileObj;

        if (videoFile.size / 1024 / 1024 > MAX_FILE_SIZE_MB) {
          // Display an error notification for file size exceeding limit
          notification.error({
            message: "File Size Exceeds Limit",
            description: `Video file size exceeds the maximum limit of ${MAX_FILE_SIZE_MB}MB.`
          });
          setLoading(false);
          return;
        }

        formData.append("video_file", videoFile);
      }

      // Dispatch the Redux action to create a new LibraryExercise instance
      await dispatch(createLibraryExercise(formData));
      dispatch(getExercises(query, tags, currentPage, clientID));
      setLoading(false);
      // After successfully creating the exercise, close the modal
      handleExerciseModalClose();
    } catch (error) {
      setLoading(false);
      console.error("Error creating exercise:", error);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleInfoClick = (exercise) => {
    setSelectedExercise(exercise);
    setInfoModalVisible(true);
  };

  const handleInfoModalClose = () => {
    setInfoModalVisible(false);
    setSelectedExercise(null);
  };

  const handleExerciseModalClose = () => {
    // Clear the file lists to remove any uploaded files
    setVideoFileList([]);
    setImageFileList([]);

    // Reset the form fields
    form.resetFields();

    // Close the modal
    setCreateExerciseModalVisible(false);
  };

  const itemRender = (current, type, originalElement) => {
    if (type === "page") {
      const color = Theme.PRIMARY;
      return <span style={{ color }}>{current}</span>;
    }
    return originalElement;
  };

  const onClickExercise = (exercise) => {
    const newExercise = {
      name: exercise.name,
      sets: [
        {
          weight: { type: "% Difficulty", value: "" },
          params: [
            { type: "Reps", value: "" },
            { type: "Rest", value: "" }
          ]
        }
      ],
      library_exercise_id: exercise.id,
      order: 0,
      photo: exercise.image
    };

    setSelectedExercises((prev) => {
      const isExerciseSelected = prev.some(
        (e) => e.library_exercise_id === exercise.id
      );

      if (isExerciseSelected) {
        return prev.filter((e) => e.library_exercise_id !== exercise.id);
      } else {
        return [...prev, newExercise];
      }
    });
  };

  const handleSubmitSuperset = () => {
    handleExerciseSelect(selectedExercises, isSuperset);
    setSelectedExercises([]);
  };

  return (
    <Row
      align={"middle"}
      justify={"center"}
      style={{
        minHeight: "50vh",
        marginTop: ".75vw",
        position: "relative",
        right: "5vw"
      }}
    >
      {initLoading ? (
        <Row
          align={"middle"}
          justify={"center"}
          style={{
            minHeight: "100vh",
            marginTop: ".75vw",
            position: "relative",

            width: "75vw",
            background:
              Theme.BACKGROUND === "#000" ? Theme.BACKGROUND : Theme.CARD
          }}
        >
          <Spin size="large" />
        </Row>
      ) : (
        <Col
          justify="center"
          align="middle"
          style={{
            borderRadius: "8px",
            width: "100%",

            background:
              Theme.BACKGROUND === "#000" ? Theme.BACKGROUND : Theme.CARD
          }}
        >
          <Col
            justify="center"
            align="middle"
            style={{ padding: "0.4vw", marginTop: ".5vw" }}
          ></Col>
          <Row
            justify="center"
            align="middle"
            style={{ paddingBottom: ".5vw" }}
          >
            <BootstrapSearch
              placeholder="Search Exercises"
              onChange={(e) => setQuery(e.target.value)}
              width="15vw"
              background={
                Theme.BACKGROUND === "#000" ? Theme.CARD : Theme.BACKGROUND
              }
            />

            <Text
              style={{
                color: Theme.PRIMARY, // Use your theme's primary color or another color of your choice
                fontSize: ".65vw",
                cursor: "pointer",
                fontWeight: "bold",
                position: "absolute",
                left: "24vw" // Keep padding consistent with the Create Exercise button
              }}
              onClick={() => setFilterModal(true)}
            >
              Filter
            </Text>

            <Button
              style={{
                backgroundImage:
                  "linear-gradient(45deg, #C04848 0%, #480048 80%)",
                border: 0,
                fontSize: ".55vw",
                height: "1.5vw",
                borderRadius: 10,
                color: "white",
                paddingLeft: "1.5%",
                paddingRight: "1.5%",
                fontWeight: "bold",
                cursor: "pointer",
                position: "absolute",
                right: "3vw"
              }}
              type="button"
              onClick={() => setCreateExerciseModalVisible(true)}
            >
              Create Exercise
            </Button>
          </Row>

          <Col
            align={"middle"}
            justify={"center"}
            style={{
              overflowY: "auto",
              // minHeight: "27vw",
              // maxHeight: "27vw",
              paddingBottom: "2vw"
            }}
          >
            {
              <Row
                align={"middle"}
                justify={"center"}
                style={{ marginTop: ".5vw", marginLeft: "1vw" }}
              >
                {exercises.results?.length > 0 ||
                exercises.recommended_exercises?.length > 0 ? (
                  <>
                    {exercises.results?.length > 0 &&
                      exercises.results.map((exercise, index) => {
                        const isSelected = selectedExercises.some(
                          (e) => e.library_exercise_id === exercise.id
                        );
                        return (
                          <Col
                            align={"middle"}
                            justify={"center"}
                            style={{ marginBottom: ".75vw", marginTop: "1vw" }}
                            key={`${exercise.name}_${index}`}
                          >
                            <Col
                              align={"middle"}
                              justify={"center"}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                marginBottom: "0.2vw",
                                background: Theme.CARD,
                                marginRight: "2vw",
                                marginLeft: "2vw",
                                borderRadius: 10,
                                height: "8vw",
                                cursor: "pointer",
                                boxShadow: "0px 6px 6px rgba(0, 0, 0, 0.175)",
                                width: "17vw"
                              }}
                              onClick={() => handleInfoClick(exercise)}
                            >
                              <img
                                style={{
                                  height: "8vw",
                                  right: "5%",
                                  position: "relative",
                                  maxWidth: "50%",
                                  objectFit: "cover",
                                  borderTopLeftRadius: 10,
                                  borderBottomLeftRadius: 10,
                                  opacity: isSelected ? 0.3 : 1
                                }}
                                alt={exercise.name}
                                src={
                                  exercise.image ? exercise.image : customblank
                                }
                              />
                              {isSelected && (
                                <CheckCircleOutlined
                                  style={{
                                    color: Theme.PRIMARY, // Light green for visibility, adjust as needed
                                    fontSize: "2vw", // Adjust size as needed
                                    position: "absolute",
                                    top: "30%", // Adjust for best appearance
                                    left: "10%", // Adjust for best appearance
                                    zIndex: 1
                                  }}
                                />
                              )}
                              <Col
                                align="start"
                                style={{
                                  width: "8vw",
                                  marginLeft: ".25vw",
                                  lineHeight: ".85"
                                }}
                              >
                                <Text
                                  style={{
                                    color: Theme.TEXT,
                                    fontSize: ".65vw",
                                    textAlign: "left",
                                    width: "80%"
                                  }}
                                >
                                  {exercise.name}
                                </Text>
                              </Col>
                            </Col>
                          </Col>
                        );
                      })}
                  </>
                ) : (
                  <Col
                    style={{
                      marginTop: "3vw",
                      textAlign: "center",
                      width: "100%"
                    }}
                  >
                    <ExclamationCircleOutlined
                      style={{ color: Theme.PRIMARY, fontSize: "4vw" }}
                    />
                    <Text
                      style={{
                        display: "block",
                        color: Theme.TEXT,
                        fontSize: "1vw",
                        marginTop: "2vw"
                      }}
                    >
                      Search not found. Try a different search.
                    </Text>
                  </Col>
                )}
              </Row>
            }
            <Pagination
              style={{
                marginTop: "3%",
                position: "relative",
                alignSelf: "center",
                left: "33%"
              }}
              onChange={paginateChange}
              total={total_exercises}
              defaultCurrent={1}
              defaultPageSize={26}
              itemRender={itemRender}
              showSizeChanger={false}
            />
          </Col>
        </Col>
      )}

      <Modal
        open={infoModalVisible}
        onCancel={handleInfoModalClose}
        width={"65vw"}
        bodyStyle={{
          paddingBottom:
            selectedExercise &&
            selectedExercise.video_file &&
            selectedExercise.exercise_type == "custom"
              ? "20vw"
              : "10vw",
          paddingTop: "1vw",
          paddingLeft: "1vw",
          paddingRight: "1vw",
          minHeight: "25vw"
        }}
        className={
          Theme.BACKGROUND === "#000"
            ? "customAntdModalDark"
            : "customAntdModalLight"
        }
        footer={[<div></div>]}
      >
        {selectedExercise && (
          <Col align="middle">
            <Text
              style={{ color: Theme.TEXT, fontSize: "1vw", fontWeight: "bold" }}
            >
              {selectedExercise.name}
            </Text>
            <Row
              gutter={16}
              justify="center"
              style={{ position: "relative", marginTop: "4vw" }}
            >
              <Col span={12}>
                {selectedExercise.video_file ? (
                  <video
                    style={{
                      width: "85%",
                      height: "auto",
                      maxHeight: "20vw",
                      objectFit: "contain",
                      borderRadius: 20
                    }}
                    controls
                    src={selectedExercise.video_file}
                  />
                ) : selectedExercise.exercise_library.image ? (
                  <img
                    style={{
                      width: "85%",
                      height: "auto",
                      maxHeight: "20vw",
                      borderRadius: 20
                    }}
                    alt={selectedExercise.name}
                    src={selectedExercise.image}
                  />
                ) : (
                  <img
                    style={{
                      width: "85%", // Use 100% width
                      height: "auto", // Maintain aspect ratio
                      objectFit: "contain", // Maintain aspect ratio and fill container
                      borderRadius: 20
                    }}
                    alt={selectedExercise.name}
                    src={customblank}
                  />
                )}
              </Col>
              <Col span={12} style={{ textAlign: "left" }}>
                <div>
                  <Text
                    style={{
                      fontWeight: "bold",
                      fontSize: ".75vw",
                      marginBottom: ".5vw",
                      marginTop: ".5vw",
                      color: Theme.TEXT
                    }}
                  >
                    Instructions
                  </Text>
                </div>
                {selectedExercise.instructions &&
                selectedExercise.instructions !== "undefined" ? (
                  <Text style={{ fontSize: ".85vw", textAlign: "left" }}>
                    {selectedExercise.instructions
                      .split("\n")
                      .map((instruction, index) => (
                        <Text
                          style={{ textAlign: "left", color: Theme.TEXT2 }}
                          key={index}
                        >
                          {instruction}
                          <br />
                        </Text>
                      ))}
                  </Text>
                ) : (
                  <Text style={{ fontSize: ".85vw", color: Theme.TEXT2 }}>
                    No Instructions Provided
                  </Text>
                )}
              </Col>
            </Row>
          </Col>
        )}
      </Modal>

      <Modal
        open={createExerciseModalVisible}
        onCancel={handleExerciseModalClose}
        width={"25vw"}
        bodyStyle={{
          paddingBottom: "5vw",
          paddingTop: "1vw",
          paddingLeft: "1vw",
          paddingRight: "1vw"
        }}
        footer={[<div></div>]}
        className={
          Theme.BACKGROUND === "#000"
            ? "customAntdModalDark"
            : "customAntdModalLight"
        }
      >
        <Col align="middle" justify="center">
          <Form
            style={{ background: Theme.CARD }}
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Text
              style={{
                fontWeight: "bold",
                fontSize: ".75vw",
                marginBottom: ".5vw",
                marginTop: ".5vw",
                color: Theme.TEXT
              }}
            >
              Exercise Name
            </Text>
            <Form.Item
              name="name"
              rules={[
                { required: true, message: "Please enter an exercise name" }
              ]}
            >
              <Input
                style={{
                  width: "15vw",
                  height: "auto",
                  borderRadius: "10px",
                  boxShadow: "1px 0px 2px 3px rgba(0, 0, 0, 0.1)",
                  borderWidth: 0,
                  fontSize: ".75vw",
                  background: Theme.BACKGROUND,
                  color: Theme.TEXT,
                  outline: "none"
                }}
              />
            </Form.Item>
            <Row style={{ marginTop: "1vw" }}>
              <Col
                align="middle"
                justify="center"
                style={{ alignItems: "center" }}
              >
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: ".75vw",
                    marginBottom: ".5vw",
                    marginTop: ".5vw",
                    marginRight: ".45vw",
                    color: Theme.TEXT
                  }}
                >
                  Video
                </Text>
                <Form.Item
                  name="video_file"
                  valuePropName="fileList"
                  getValueFromEvent={(e) => normFile(e, setVideoFileList)}
                  rules={[
                    { required: true, message: "Please upload a video file" }
                  ]}
                >
                  <Upload
                    name="video_file"
                    action="/upload.do"
                    listType="picture-card"
                    beforeUpload={(file) =>
                      beforeUpload(file, setVideoFileList, "video")
                    }
                    fileList={videoFileList}
                    onChange={handleVideoFileChange}
                  >
                    {videoFileList.length === 0 && (
                      <Button icon={<UploadOutlined />}>Upload</Button>
                    )}
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
            <Text
              style={{
                fontWeight: "bold",
                fontSize: ".75vw",
                marginBottom: ".5vw",
                marginTop: ".5vw",
                color: Theme.TEXT
              }}
            >
              Instructions
            </Text>
            <Form.Item style={{ width: "20vw" }} name="description">
              <Input.TextArea
                style={{
                  minHeight: "7.5vw",
                  fontSize: ".7vw",
                  borderRadius: "10px",
                  boxShadow: "1px 0px 2px 3px rgba(0, 0, 0, 0.1)",
                  borderWidth: 0,
                  fontSize: ".75vw",
                  background: Theme.BACKGROUND,
                  color: Theme.TEXT,
                  outline: "none"
                }}
              />
            </Form.Item>

            <Form.Item>
              {loading ? (
                <Button
                  style={{
                    backgroundImage:
                      "linear-gradient(45deg, #C04848 0%, #480048 80%)",
                    border: 0,
                    fontSize: ".65vw",
                    height: "2vw",
                    borderRadius: 10,
                    color: "white",
                    paddingLeft: "2.5%",
                    paddingRight: "2.5%",
                    fontWeight: "bold",
                    width: "10vw",
                    cursor: "pointer"
                  }}
                  type="primary"
                  htmlType="submit"
                >
                  <Spin color={"white"} />{" "}
                </Button>
              ) : (
                <Button
                  style={{
                    backgroundImage:
                      "linear-gradient(45deg, #C04848 0%, #480048 80%)",
                    border: 0,
                    fontSize: ".65vw",
                    height: "2vw",
                    borderRadius: 10,
                    color: "white",
                    paddingLeft: "2.5%",
                    paddingRight: "2.5%",
                    fontWeight: "bold",
                    width: "10vw",
                    cursor: "pointer"
                  }}
                  type="primary"
                  htmlType="submit"
                >
                  Create Exercise
                </Button>
              )}
            </Form.Item>
          </Form>
        </Col>
      </Modal>
      <Modal
        open={filterModal}
        onCancel={closeFilterModal}
        width={"65vw"}
        bodyStyle={{
          paddingBottom: "0vw",
          paddingTop: "1vw",
          paddingLeft: "1vw",
          paddingRight: "1vw",
          minHeight: "25vw"
        }}
        className={
          Theme.BACKGROUND === "#000"
            ? "customAntdModalDark"
            : "customAntdModalLight"
        }
        footer={[<div></div>]}
      >
        <ExerciseFilterModal
          onApply={handleApplyTags}
          closeModal={closeFilterModal}
        ></ExerciseFilterModal>
      </Modal>
    </Row>
  );
};

export default Library;
