import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Col,
  Row,
  Typography,
  Spin,
  Modal
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteWorkout,
  getExercises,
  getSingleWorkoutTemplate,
  getWorkouts,
  updateWorkoutTemplate
} from "../../redux/actions/workouts";

import ExerciseList from "../workouts/ExerciseList";
import Library from "../workouts/Library";

const { Text } = Typography;

const EditWorkoutTemplateForm = ({
  initialDate,
  closeModal,
  workoutData,
  modalOpen
}) => {
  const dispatch = useDispatch();
  const { client } = useSelector((state) => state.clients);
  const { Theme } = useSelector((state) => state.auth);
  const [form] = Form.useForm();
  const [chosenExercises, setChosenExercises] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState("");
  const [tags, setTags] = useState([]);
  const { exercises, workoutTemplateStatus, workoutTemplate } = useSelector(
    (state) => state.workouts
  );
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [workoutId, setWorkoutId] = useState(null);

  useEffect(() => {
    dispatch(getExercises(query, tags, currentPage, client.id));
  }, [query, currentPage, tags, dispatch, client.id]);

  useEffect(() => {
    if (workoutTemplate?.length) {
      form.setFieldsValue({
        title: workoutTemplate[0]?.title,
        notes: workoutTemplate[0]?.notes,
        date: workoutTemplate[0]?.date // Set the formatted date string
      });
      setWorkoutId(workoutTemplate[0]?.id);
      setChosenExercises(workoutTemplate[0]?.exercises);
    }
  }, [form, workoutTemplate, workoutTemplate?.length]);

  const cancelEdit = () => {
    setChosenExercises([]);
    closeModal(false);
  };

  const handleExerciseSelect = (exercises, isSupersetChecked = false) => {
    setChosenExercises((prevExercises) => {
      const currentExercises = Array.isArray(prevExercises)
        ? prevExercises
        : [];
      let newExercises = Array.isArray(exercises) ? exercises : [exercises]; // Ensure it's always an array

      newExercises = newExercises.map((exercise, index) => ({
        ...exercise,
        order: currentExercises.length + index // Update order based on existing exercises count
      }));

      if (isSupersetChecked) {
        // If it's a superset, add the new exercises as a single superset entry
        return [
          ...currentExercises,
          {
            isSuperset: true,
            exercises: newExercises,
            order: currentExercises.length
          }
        ];
      } else {
        // If not a superset, just concatenate the new exercises
        return [...currentExercises, ...newExercises];
      }
    });
  };

  const handleAddSet = (exerciseIndex, sourceSetIndex, subIndex) => {
    setChosenExercises((prevExercises) => {
      const updatedExercises = [...prevExercises];

      // Define a function to create a new set based on the existing source set or default values
      const createNewSet = (sourceSet) => {
        return sourceSet
          ? {
              weight: { ...sourceSet.weight },
              params: sourceSet.params.map((param) => ({ ...param }))
            }
          : {
              weight: { type: "% Difficulty", value: "" },
              params: [
                { type: "Reps", value: "" },
                { type: "Rest", value: "" }
              ]
            };
      };

      if (typeof subIndex !== "undefined") {
        // Handling for sub-exercise within a superset
        const superset = updatedExercises[exerciseIndex];
        if (superset && superset.exercises && superset.exercises[subIndex]) {
          const subExercise = superset.exercises[subIndex];
          if (!subExercise.sets) {
            subExercise.sets = [];
          }
          const sourceSet = subExercise.sets[sourceSetIndex];
          subExercise.sets.push(createNewSet(sourceSet));
        }
      } else {
        const exercise = updatedExercises[exerciseIndex];
        if (exercise && exercise.sets) {
          const sourceSet = exercise.sets[sourceSetIndex];
          exercise.sets.push(createNewSet(sourceSet));
        }
      }

      return updatedExercises;
    });
  };

  const handleRemoveSet = (index, setIndex, subIndex) => {
    setChosenExercises((prevExercises) => {
      const updatedExercises = [...prevExercises];
      if (
        typeof subIndex !== "undefined" &&
        updatedExercises[index]?.exercises[subIndex]?.sets
      ) {
        updatedExercises[index].exercises[subIndex].sets.splice(setIndex, 1);
      } else if (updatedExercises[index]?.sets) {
        updatedExercises[index].sets.splice(setIndex, 1);
      }

      return updatedExercises;
    });
  };

  const handleRemoveParamFromSet = (
    exerciseIndex,
    setIndex,
    paramIndex,
    subIndex
  ) => {
    setChosenExercises((prevExercises) => {
      const updatedExercises = [...prevExercises];

      if (typeof subIndex !== "undefined") {
        updatedExercises[exerciseIndex].exercises[subIndex].sets[
          setIndex
        ].params.splice(paramIndex, 1);
      } else {
        updatedExercises[exerciseIndex].sets[setIndex].params.splice(
          paramIndex,
          1
        );
      }

      return updatedExercises;
    });
  };

  const handleRemoveExercise = (index, subIndex) => {
    setChosenExercises((prevExercises) => {
      if (typeof subIndex !== "undefined") {
        let updatedExercises = JSON.parse(JSON.stringify(prevExercises));
        const targetExercise = updatedExercises[index];

        if (targetExercise.isSuperset) {
          targetExercise.exercises = targetExercise.exercises.filter(
            (_, si) => si !== subIndex
          );

          if (targetExercise.exercises.length === 0) {
            return updatedExercises.filter((_, i) => i !== index);
          } else {
            updatedExercises[index] = targetExercise;
            return updatedExercises;
          }
        }
      } else {
        return prevExercises.filter((_, i) => i !== index);
      }
      // Return the previous exercises by default
      return prevExercises;
    });
  };

  const handleParamTypeChange = (
    exerciseIndex,
    setIndex,
    paramIndex,
    value,
    subIndex
  ) => {
    setChosenExercises((prevExercises) => {
      const updatedExercises = [...prevExercises];

      if (typeof subIndex !== "undefined") {
        if (
          updatedExercises[exerciseIndex]?.exercises[subIndex]?.sets[setIndex]
            ?.params
        ) {
          updatedExercises[exerciseIndex].exercises[subIndex].sets[
            setIndex
          ].params[paramIndex].type = value;
        }
      } else {
        if (updatedExercises[exerciseIndex]?.sets[setIndex]?.params) {
          updatedExercises[exerciseIndex].sets[setIndex].params[
            paramIndex
          ].type = value;
        }
      }

      return updatedExercises;
    });
  };

  const handleExerciseInputChange = (index, field, value) => {
    setChosenExercises((prevExercises) =>
      prevExercises.map((exercise, i) =>
        i === index
          ? { ...exercise, [field]: { ...exercise[field], value: value } }
          : exercise
      )
    );
  };

  const handleParamValueChange = (
    exerciseIndex,
    setIndex,
    paramIndex,
    value,
    subIndex
  ) => {
    setChosenExercises((prevExercises) => {
      const updatedExercises = [...prevExercises];
      if (typeof subIndex !== "undefined") {
        updatedExercises[exerciseIndex].exercises[subIndex].sets[
          setIndex
        ].params[paramIndex].value = value;
      } else {
        updatedExercises[exerciseIndex].sets[setIndex].params[
          paramIndex
        ].value = value;
      }

      return updatedExercises;
    });
  };

  const handleAddParamToSet = (exerciseIndex, setIndex, subIndex) => {
    setChosenExercises((prevExercises) => {
      const updatedExercises = [...prevExercises];

      // Determine the new parameter type based on the number of existing parameters
      const getNewParamType = (params) => {
        switch (params.length) {
          case 0:
            return "Reps";
          case 1:
            return "Rest";
          case 2:
            return "Time";
          default:
            return "Reps"; // Default or adjust as needed
        }
      };

      if (typeof subIndex !== "undefined") {
        const subExercise = updatedExercises[exerciseIndex].exercises[subIndex];
        const newParamType = getNewParamType(subExercise.sets[setIndex].params);
        subExercise.sets[setIndex].params.push({
          type: newParamType,
          value: ""
        });
      } else {
        // Adding new param to a regular exercise set
        const exercise = updatedExercises[exerciseIndex];
        const newParamType = getNewParamType(exercise.sets[setIndex].params);
        exercise.sets[setIndex].params.push({ type: newParamType, value: "" });
      }

      return updatedExercises;
    });
  };

  const handleWeightTypeChange = (exerciseIndex, setIndex, value, subIndex) => {
    setChosenExercises((prevExercises) => {
      const updatedExercises = [...prevExercises];
      if (typeof subIndex !== "undefined") {
        if (
          updatedExercises[exerciseIndex]?.exercises[subIndex]?.sets[setIndex]
            ?.weight
        ) {
          updatedExercises[exerciseIndex].exercises[subIndex].sets[
            setIndex
          ].weight.type = value;
        }
      } else {
        if (updatedExercises[exerciseIndex]?.sets[setIndex]?.weight) {
          updatedExercises[exerciseIndex].sets[setIndex].weight.type = value;
        }
      }

      return updatedExercises;
    });
  };

  const handleWeightValueChange = (
    exerciseIndex,
    setIndex,
    value,
    subIndex
  ) => {
    setChosenExercises((prevExercises) => {
      const updatedExercises = [...prevExercises];

      if (typeof subIndex !== "undefined") {
        if (
          updatedExercises[exerciseIndex]?.exercises[subIndex]?.sets[setIndex]
            ?.weight
        ) {
          updatedExercises[exerciseIndex].exercises[subIndex].sets[
            setIndex
          ].weight.value = value;
        }
      } else {
        if (updatedExercises[exerciseIndex]?.sets[setIndex]?.weight) {
          updatedExercises[exerciseIndex].sets[setIndex].weight.value = value;
        }
      }

      return updatedExercises;
    });
  };

  const handleFormSubmit = (values) => {
    setError(false);
    setLoading(true);

    const workoutUpdatedData = {
      title: values.title,
      notes: values.notes,
      exercises: chosenExercises,
      client: client.id
    };

    dispatch(updateWorkoutTemplate(workoutId, workoutUpdatedData)).then(
      (response) => {
        closeModal();
      }
    );
    setLoading(false);
  };

  useEffect(() => {
    const handleEffect = async () => {
      if (workoutTemplateStatus === "success") {
        closeModal();
        dispatch(getWorkouts(client.id));
        form.resetFields();
      }
      if (workoutTemplateStatus === "fail") {
        setError(true);
      }
    };

    handleEffect();
  }, [workoutTemplateStatus, closeModal]);

  return (
    <Form
      style={{ background: Theme.CARD, marginTop: "1.5vw" }}
      form={form}
      onFinish={handleFormSubmit}
    >
      <Col>
        <Text
          style={{
            fontWeight: "bold",
            fontSize: "1dvw",
            bottom: "1vw",
            position: "relative",
            color: Theme.TEXT
          }}
        >
          Edit Workout
        </Text>
        <Col align={"end"} style={{ position: "relative", bottom: "2.5vw" }}>
          <Row align={"end"}>
            <Button
              style={{
                marginRight: "1vw"
              }}
              type="primary"
              onClick={() => cancelEdit()}
            >
              Cancel
            </Button>
            <Form.Item>
              <Col>
                {!loading ? (
                  <Button
                    style={{
                      backgroundImage:
                        "linear-gradient(45deg, #C04848 0%, #480048 80%)",
                      border: 0
                    }}
                    type="primary"
                    htmlType="submit"
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    style={{
                      backgroundImage:
                        "linear-gradient(45deg, #C04848 0%, #480048 80%)",
                      border: 0
                    }}
                    type="primary"
                    htmlType="submit"
                  >
                    <Spin size="small" />
                  </Button>
                )}
              </Col>
            </Form.Item>
          </Row>
          <div>
            {error && (
              <Text
                style={{
                  fontSize: ".65vw",
                  color: "red",
                  bottom: ".75vw",
                  position: "relative"
                }}
              >
                Error - Please Try Again
              </Text>
            )}
          </div>
        </Col>
        <Row
          align="middle"
          justify={"center"}
          style={{ position: "relative", bottom: "2vw" }}
          span={12}
        >
          <Row justify={"center"} align={"middle"}>
            <Form.Item
              name="title"
              style={{
                marginRight: "1vw"
              }}
              rules={[{ required: true, message: "Title is required" }]}
            >
              <Input
                placeholder="Title"
                className={
                  Theme.BACKGROUND === "#000"
                    ? "custom-placeholder-color-dark"
                    : "custom-placeholder-color-light"
                }
                style={{
                  height: "2vw",
                  color: Theme.TEXT,
                  fontSize: ".65vw",
                  background: Theme.BACKGROUND,
                  borderWidth: Theme.BACKGROUND === "#000" ? 0 : 1
                }}
              />
            </Form.Item>
          </Row>
          <Form.Item
            name="notes"
            style={{ width: "30vw", position: "relative", marginLeft: "3vw" }}
          >
            <Input.TextArea
              className={
                Theme.BACKGROUND === "#000"
                  ? "custom-placeholder-color-dark"
                  : "custom-placeholder-color-light"
              }
              style={{
                color: Theme.TEXT,
                background: Theme.BACKGROUND,
                fontSize: ".65vw",
                height: "2vw",
                borderWidth: Theme.BACKGROUND === "#000" ? 0 : 1
              }}
              placeholder="Instructions"
            />
          </Form.Item>
        </Row>

        <Row
          justify={"center"}
          style={{ position: "relative", bottom: "2vw", width: "85vw" }}
        >
          <Col
            style={{ minHeight: "20vw", width: "39vw", marginBottom: "1vw" }}
            align="start"
            justify="left"
          >
            <ExerciseList
              chosenExercises={chosenExercises}
              Theme={Theme}
              handleExerciseInputChange={handleExerciseInputChange}
              handleWeightTypeChange={handleWeightTypeChange}
              handleWeightValueChange={handleWeightValueChange}
              handleParamTypeChange={handleParamTypeChange}
              handleParamValueChange={handleParamValueChange}
              handleAddParamToSet={handleAddParamToSet}
              handleRemoveExercise={handleRemoveExercise}
              handleAddSet={handleAddSet}
              handleRemoveSet={handleRemoveSet}
              handleRemoveParamFromSet={handleRemoveParamFromSet}
              setChosenExercises={setChosenExercises}
            />
          </Col>
          <Col span={12} style={{ marginRight: "1vw" }}>
            <Library
              exercises={exercises}
              Theme={Theme}
              handleExerciseSelect={handleExerciseSelect}
              clientID={client.id}
            />
          </Col>
        </Row>
      </Col>
    </Form>
  );
};

export default EditWorkoutTemplateForm;
