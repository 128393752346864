import React, { useEffect, useState } from "react";
import { Row, Col, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Card, Typography, Pagination } from "antd";
import { Button } from "react-bootstrap";
import macro from "../../assets/images/macrocycle.png";
import meso from "../../assets/images/mesocycle.png";
import CreateBlockForm from "./BlockForm";
import { getMesocycles, getPrograms } from "../../redux/actions/workouts";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CreateProgramForm from "./ProgramForm";
import CustomSelect from "../common/DropDown";
import BlockTemplateModal from "../templates/BlockTemplateModal";
import ProgramTemplateModal from "../templates/ProgramTemplateModal";

const { Text } = Typography;
const Cycles = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [mesoModalVisible, setMesoModalVisible] = useState(false);
  const [assignBlockModalVisble, setAssignBlockTemplateModalVisible] =
    useState(false);
  const [choiceModalVisible, setChoiceModalVisible] = useState(false);
  const [assignProgramModalVisble, setAssignProgramTemplateModalVisible] =
    useState(false);
  const [programChoiceModalVisible, setProgramChoiceModalVisible] =
    useState(false);
  const [macroModalVisible, setMacroModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const { Theme } = useSelector((state) => state.auth);
  const { client } = useSelector((state) => state.clients);
  const { mesocycles, programs } = useSelector((state) => state.workouts);
  const [isRowLayout, setIsRowLayout] = useState(window.innerWidth >= 1000);
  // Function to handle window resize event and update layout mode
  const handleWindowResize = () => {
    setIsRowLayout(window.innerWidth >= 996);
  };
  useEffect(() => {
    dispatch(getMesocycles(client.id));
    dispatch(getPrograms(client.id));
  }, [client]);

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener("resize", handleWindowResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  // Define state for managing selected dropdown option (Blocks/Programs)
  const [selectedOption, setSelectedOption] = useState("blocks");

  // Define state for managing selected dropdown option (Current/Future/Past)
  const [selectedTimeframe, setSelectedTimeframe] = useState("current");

  const formatDate = (dateString) => {
    const splitString = dateString.split("-");
    const formattedString = `${splitString[1]}-${splitString[2]}-${splitString[0]}`;
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    const date = new Date(formattedString);
    return date.toLocaleDateString("en-US", options);
  };

  // Function to filter blocks or programs based on selected timeframe
  const filterDataByTimeframe = (data) => {
    const currentDate = new Date();

    return data.filter((item) => {
      if (selectedOption === "blocks") {
        const endDate = new Date(item.start_date);
        endDate.setDate(endDate.getDate() + item.numOfWeeks * 7); // Adding weeks to start date

        if (selectedTimeframe === "current") {
          return (
            currentDate >= new Date(item.start_date) && currentDate <= endDate
          );
        } else if (selectedTimeframe === "future") {
          return currentDate < new Date(item.start_date);
        } else {
          return currentDate > endDate;
        }
      } else {
        if (selectedTimeframe === "current") {
          return (
            currentDate >= new Date(item.time_length_start) &&
            currentDate <= new Date(item.time_length_end)
          );
        } else if (selectedTimeframe === "future") {
          return currentDate < new Date(item.time_length_start);
        } else {
          return currentDate > new Date(item.time_length_end);
        }
      }
    });
  };

  // Function to handle dropdown change for Blocks/Programs
  const handleOptionChange = (value) => {
    setSelectedOption(value);
    setCurrentPage(1);
  };

  // Function to handle dropdown change for Current/Future/Past
  const handleTimeframeChange = (value) => {
    setSelectedTimeframe(value);
    setCurrentPage(1);
  };

  // Get the filtered data based on selected option and timeframe
  const filteredData =
    selectedOption === "blocks"
      ? filterDataByTimeframe(mesocycles)
      : filterDataByTimeframe(programs);

  const handleClick = (item) => {
    if (selectedOption === "blocks") {
      history.push(`/block/${client.id}/${item.id}`);
    } else {
      history.push(`/program/${client.id}/${item.id}`);
    }
  };

  // Calculate the index of the first and last item to display on the current page
  const indexOfLastItem = currentPage * 2;
  const indexOfFirstItem = indexOfLastItem - 2;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  return (
    <Card
      style={{
        zIndex: 100,
        flex: 1,
        background: Theme.CARD,
        borderWidth: 0,
        position: "relative",
        width: isRowLayout ? "38vw" : "55vw",
        minHeight: "26.5vw",
        right: isRowLayout ? ".75vw" : null
      }}
      className="criclebox h-full"
    >
      <Row justify={"center"} align={"middle"} style={{ marginBottom: "2%" }}>
        <h3
          style={{
            color: Theme.TEXT,
            fontSize: "1.1vw",
            position: "relative"
          }}
        >
          Cycles
        </h3>
      </Row>
      <Button
        color={"PRIMARY"}
        style={{
          // width: "5vw",
          position: "absolute",
          right: "1vw",
          paddingLeft: "1vw",

          paddingRight: "1vw",
          height: "1.5vw",
          cursor: "pointer",
          zIndex: 999,
          top: "1.85vw",
          backgroundImage: "linear-gradient(60deg, #C04848 25%, #480048 120%)",
          borderRadius: 10,
          borderWidth: 0,
          opacity: 0.9
        }}
        onClick={() => setCreateModalVisible(true)}
      >
        <div style={{ flexDirection: "column" }}>
          <div style={{ flexDirection: "row" }}>
            <Text
              style={{
                color: "white",
                fontSize: ".75vw",
                textAlign: "center",
                opacity: 1,
                fontWeight: "bold"
              }}
            >
              Create Cycle
            </Text>
          </div>
        </div>
      </Button>
      <Row justify={"center"} align={"middle"} style={{ marginBottom: "1vw" }}>
        {/* Dropdown for selecting Blocks or Programs */}
        <CustomSelect
          value={selectedOption}
          options={[
            { value: "blocks", label: "Blocks" },
            { value: "programs", label: "Programs" }
          ]}
          onChange={handleOptionChange}
          width={200}
          placeholder="Select"
          lightModeBackground={Theme.LIGHT_BACKGROUND}
          darkModeBackground={Theme.DARK_BACKGROUND}
        />
        <Col style={{ marginLeft: "1vw" }}>
          <CustomSelect
            value={selectedTimeframe}
            options={[
              { value: "current", label: "Current" },
              { value: "future", label: "Future" },
              { value: "past", label: "Past" }
            ]}
            onChange={handleTimeframeChange}
            width={200}
            placeholder="Select"
            lightModeBackground={Theme.LIGHT_BACKGROUND}
            darkModeBackground={Theme.DARK_BACKGROUND}
          />{" "}
        </Col>
      </Row>
      {filteredData.length === 0 && selectedOption === "blocks" && (
        <Col style={{ marginTop: "4vw" }}>
          <img
            style={{
              height: "3vw",
              width: "3vw",
              objectFit: "cover"
            }}
            src={meso}
          />{" "}
          <div>
            <Text style={{ fontSize: "1vw", color: Theme.TEXT }}>
              No Blocks
            </Text>
          </div>
        </Col>
      )}

      {filteredData.length === 0 && selectedOption === "programs" && (
        <Col style={{ marginTop: "4vw" }}>
          <img
            style={{
              height: "3vw",
              width: "3vw",
              objectFit: "cover"
            }}
            src={macro}
            alt="program"
          />{" "}
          <div>
            <Text style={{ fontSize: "1vw", color: Theme.TEXT }}>
              No Programs
            </Text>
          </div>
        </Col>
      )}
      <Row
        justify={"center"}
        align={"middle"}
        gutter={[16, 16]}
        style={{ paddingBottom: "2vw" }}
      >
        {currentItems.map((item, index) => (
          <Col
            style={{
              marginTop:
                currentItems.length === 1
                  ? "2.5vw"
                  : index === 0
                  ? ".5vw"
                  : null,
              width: "65%"
            }}
            key={item.id}
          >
            <Card
              style={{
                backgroundImage:
                  selectedOption === "blocks"
                    ? "linear-gradient(25deg, #353939 -25%, #000000 150%)"
                    : "linear-gradient(60deg, #C04848 25%, #480048 120%)",
                borderWidth: 0,
                marginBottom: "1vw",
                cursor: "pointer"
              }}
              onClick={() => handleClick(item)}
            >
              <Col justify={"center"} align={"middle"}>
                <Text
                  style={{
                    color: Theme.WHITE,
                    fontWeight: "bold",
                    fontSize: ".85vw"
                  }}
                >
                  {item.name}
                </Text>
                <Row
                  justify={"center"}
                  align={"middle"}
                  style={{ marginTop: ".5vw" }}
                >
                  <Col>
                    <Text
                      style={{
                        color:
                          selectedOption === "blocks"
                            ? Theme.PRIMARY
                            : Theme.WHITE,
                        fontWeight: "bold",
                        fontSize: ".7vw"
                      }}
                    >
                      Start Date
                    </Text>
                    <div>
                      <Text
                        style={{
                          color: Theme.WHITE,
                          fontWeight: "bold",
                          fontSize: ".7vw"
                        }}
                      >
                        {selectedOption === "blocks"
                          ? formatDate(item.start_date)
                          : formatDate(item.time_length_start)}
                      </Text>
                    </div>
                  </Col>
                  {selectedOption === "blocks" ? (
                    <Col style={{ marginLeft: "2vw" }}>
                      <Text
                        style={{
                          color: Theme.PRIMARY,
                          fontWeight: "bold",
                          fontSize: ".7vw"
                        }}
                      >
                        Length
                      </Text>
                      <div>
                        <Text
                          style={{
                            color: Theme.WHITE,
                            fontWeight: "bold",
                            fontSize: ".7vw"
                          }}
                        >
                          {item.numOfWeeks} Weeks
                        </Text>
                      </div>
                    </Col>
                  ) : (
                    <Col style={{ marginLeft: "2vw" }}>
                      <Text
                        style={{
                          color: Theme.WHITE,
                          fontWeight: "bold",
                          fontSize: ".7vw"
                        }}
                      >
                        End Date
                      </Text>
                      <div>
                        <Text
                          style={{
                            color: Theme.WHITE,
                            fontWeight: "bold",
                            fontSize: ".7vw"
                          }}
                        >
                          {formatDate(item.time_length_end)}
                        </Text>
                      </div>
                    </Col>
                  )}
                </Row>
              </Col>
            </Card>
          </Col>
        ))}
        <Row
          justify="center"
          align={"middle"}
          style={{ position: "absolute", bottom: "1vw" }}
        >
          <Pagination
            current={currentPage}
            onChange={handleChangePage}
            total={filteredData.length}
            pageSize={2}
            showSizeChanger={false}
            hideOnSinglePage={true}
          />
        </Row>
      </Row>

      {/* Pagination */}

      <Row justify={"center"} align={"middle"}>
        {/* Dropdown for selecting Current/Future/Past */}
      </Row>

      <Modal
        open={createModalVisible}
        // title="Create Workout"

        onCancel={() => setCreateModalVisible(false)}
        cancelButtonProps={{ style: { display: "none" } }}
        // closeIcon={<span></span>}
        footer={[<Col align={"center"} justify={"center"}></Col>]}
        width={"40vw"}
        // style={{ top: "2vw" }}
        className={
          Theme.BACKGROUND === "#000"
            ? "customAntdModalDark"
            : "customAntdModalLight"
        }
      >
        <Col align="middle" style={{ marginTop: "1vw" }}>
          <Text
            style={{
              fontWeight: "bold",
              fontSize: ".85dvw",
              bottom: "1vw",
              position: "relative",
              color: Theme.TEXT,
              justify: "center"
            }}
          >
            Create Cycle
          </Text>
          <Row
            aling="middle"
            justify={"center"}
            style={{ marginTop: "1vw", paddingBottom: "2.5vw" }}
          >
            <Card
              style={{
                cursor: "pointer",
                boxShadow: "2px 2px 2px 2px rgba(0, 0, 0, 0.1)",
                marginRight: "5vw",
                background: Theme.BACKGROUND
              }}
              bordered={false}
              onClick={() => {
                setCreateModalVisible(false);
                setChoiceModalVisible(true);
              }}
            >
              <Col>
                <div>
                  <Text style={{ fontSize: "1vw", color: Theme.TEXT }}>
                    Blocks
                  </Text>
                </div>
                <img
                  style={{
                    height: "8vw",
                    width: "8vw",
                    objectFit: "cover"
                  }}
                  src={meso}
                />
                <div style={{ width: "9vw" }}>
                  <Text style={{ fontSize: ".65vw", color: Theme.TEXT }}>
                    Specific training periods normally lasting 2-12 weeks
                  </Text>
                </div>
              </Col>
            </Card>

            <Card
              style={{
                cursor: "pointer",
                boxShadow: "2px 2px 2px 2px rgba(0, 0, 0, 0.1)",
                background: Theme.BACKGROUND
              }}
              bordered={false}
              onClick={() => {
                setCreateModalVisible(false);
                setProgramChoiceModalVisible(true);
              }}
            >
              <Col>
                <div>
                  <Text style={{ fontSize: "1vw", color: Theme.TEXT }}>
                    Program
                  </Text>{" "}
                </div>
                <img
                  style={{
                    height: "8vw",
                    width: "8vw",
                    objectFit: "cover"
                  }}
                  src={macro}
                />
                <div style={{ width: "9vw" }}>
                  <Text style={{ fontSize: ".65vw", color: Theme.TEXT }}>
                    Seasonal training plan usually between 6-12 months long{" "}
                  </Text>
                </div>
              </Col>
            </Card>
          </Row>
        </Col>
      </Modal>
      <Modal
        open={assignBlockModalVisble}
        onCancel={() => setAssignBlockTemplateModalVisible(false)}
        width={"60vw"}
        footer={[null]}
        className={
          Theme.BACKGROUND === "#000"
            ? "customAntdModalDark"
            : "customAntdModalLight"
        }
      >
        <BlockTemplateModal
          closeModal={() => setAssignBlockTemplateModalVisible(false)}
        />
      </Modal>

      <Modal
        open={assignProgramModalVisble}
        onCancel={() => setAssignProgramTemplateModalVisible(false)}
        width={"60vw"}
        footer={[null]}
        className={
          Theme.BACKGROUND === "#000"
            ? "customAntdModalDark"
            : "customAntdModalLight"
        }
      >
        <ProgramTemplateModal
          closeModal={() => setAssignProgramTemplateModalVisible(false)}
        />
      </Modal>
      <Modal
        open={choiceModalVisible}
        onCancel={() => setChoiceModalVisible(false)}
        footer={[<Col align={"center"} justify={"center"}></Col>]}
        width={"30vw"}
        style={{ top: "15vh" }}
        className={
          Theme.BACKGROUND === "#000"
            ? "customAntdModalDark"
            : "customAntdModalLight"
        }
      >
        <Col
          align="middle"
          justify="center"
          style={{ marginTop: "2.5vw", paddingBottom: "1vw" }}
        >
          <Text
            style={{
              fontWeight: "bold",
              fontSize: "1dvw",
              bottom: "1vw",
              position: "relative",
              color: Theme.TEXT,
              justify: "center"
            }}
          >
            How would you like to create this block?
          </Text>
          <Row align="middle" justify="center" style={{ marginTop: "1vw" }}>
            <Button
              color={"PRIMARY"}
              style={{
                mrginLeft: "1vw",
                right: "1vw",
                padding: ".5vw",
                width: "8vw",
                cursor: "pointer",
                zIndex: 999,
                top: "1.85vw",
                backgroundImage:
                  "linear-gradient(60deg, #C04848 25%, #480048 120%)",
                borderRadius: 10,
                borderWidth: 0,
                opacity: 0.9
              }}
              onClick={() => setAssignBlockTemplateModalVisible(true)}
            >
              <div style={{ flexDirection: "column" }}>
                <div style={{ flexDirection: "row" }}>
                  <Text
                    style={{
                      color: "white",
                      fontSize: ".75vw",
                      textAlign: "center",
                      opacity: 1,
                      fontWeight: "bold"
                    }}
                  >
                    Use a Template
                  </Text>
                </div>
              </div>
            </Button>
            <Button
              color={"PRIMARY"}
              style={{
                marginLeft: "1vw",
                padding: ".5vw",
                width: "8vw",
                cursor: "pointer",
                zIndex: 999,
                top: "1.85vw",
                backgroundImage:
                  "linear-gradient(60deg, #C04848 25%, #480048 120%)",
                borderRadius: 10,
                borderWidth: 0,
                opacity: 0.9
              }}
              onClick={() => setMesoModalVisible(true)}
            >
              <div style={{ flexDirection: "column" }}>
                <div style={{ flexDirection: "row" }}>
                  <Text
                    style={{
                      color: "white",
                      fontSize: ".75vw",
                      textAlign: "center",
                      opacity: 1,
                      fontWeight: "bold"
                    }}
                  >
                    Create from Scratch
                  </Text>
                </div>
              </div>
            </Button>
          </Row>
        </Col>
      </Modal>
      <Modal
        open={programChoiceModalVisible}
        onCancel={() => setProgramChoiceModalVisible(false)}
        footer={[<Col align={"center"} justify={"center"}></Col>]}
        width={"30vw"}
        style={{ top: "15vh" }}
        className={
          Theme.BACKGROUND === "#000"
            ? "customAntdModalDark"
            : "customAntdModalLight"
        }
      >
        <Col
          align="middle"
          justify="center"
          style={{ marginTop: "2.5vw", paddingBottom: "1vw" }}
        >
          <Text
            style={{
              fontWeight: "bold",
              fontSize: "1dvw",
              bottom: "1vw",
              position: "relative",
              color: Theme.TEXT,
              justify: "center"
            }}
          >
            How would you like to create this program?
          </Text>
          <Row align="middle" justify="center" style={{ marginTop: "1vw" }}>
            <Button
              color={"PRIMARY"}
              style={{
                mrginLeft: "1vw",
                right: "1vw",
                padding: ".5vw",
                width: "8vw",
                cursor: "pointer",
                zIndex: 999,
                top: "1.85vw",
                backgroundImage:
                  "linear-gradient(60deg, #C04848 25%, #480048 120%)",
                borderRadius: 10,
                borderWidth: 0,
                opacity: 0.9
              }}
              onClick={() => setAssignProgramTemplateModalVisible(true)}
            >
              <div style={{ flexDirection: "column" }}>
                <div style={{ flexDirection: "row" }}>
                  <Text
                    style={{
                      color: "white",
                      fontSize: ".75vw",
                      textAlign: "center",
                      opacity: 1,
                      fontWeight: "bold"
                    }}
                  >
                    Use a Template
                  </Text>
                </div>
              </div>
            </Button>
            <Button
              color={"PRIMARY"}
              style={{
                marginLeft: "1vw",
                padding: ".5vw",
                width: "8vw",
                cursor: "pointer",
                zIndex: 999,
                top: "1.85vw",
                backgroundImage:
                  "linear-gradient(60deg, #C04848 25%, #480048 120%)",
                borderRadius: 10,
                borderWidth: 0,
                opacity: 0.9
              }}
              onClick={() => setMacroModalVisible(true)}
            >
              <div style={{ flexDirection: "column" }}>
                <div style={{ flexDirection: "row" }}>
                  <Text
                    style={{
                      color: "white",
                      fontSize: ".75vw",
                      textAlign: "center",
                      opacity: 1,
                      fontWeight: "bold"
                    }}
                  >
                    Create from Scratch
                  </Text>
                </div>
              </div>
            </Button>
          </Row>
        </Col>
      </Modal>

      <Modal
        open={mesoModalVisible}
        // title="Create Workout"

        onCancel={() => setMesoModalVisible(false)}
        cancelButtonProps={{ style: { display: "none" } }}
        // closeIcon={<span></span>}
        footer={[<Col align={"center"} justify={"center"}></Col>]}
        width={"35vw"}
        // style={{ top: "2vw" }}
        className={
          Theme.BACKGROUND === "#000"
            ? "customAntdModalDark"
            : "customAntdModalLight"
        }
      >
        <Col align="middle" style={{ marginTop: "1vw" }}>
          <Col>
            <img
              style={{
                height: "4vw",
                width: "4vw",
                objectFit: "cover"
              }}
              src={meso}
            />
            <div>
              <Text
                style={{
                  fontWeight: "bold",
                  fontSize: ".95dvw",
                  bottom: "1vw",
                  position: "relative",
                  color: Theme.TEXT,
                  justify: "center"
                }}
              >
                Create Block
              </Text>
            </div>
          </Col>

          <Row
            aling="middle"
            justify={"center"}
            style={{ marginTop: "1vw", paddingBottom: "1.5vw" }}
          >
            <CreateBlockForm mesoModalVisible={mesoModalVisible} />
          </Row>
        </Col>
      </Modal>
      <Modal
        open={macroModalVisible}
        // title="Create Workout"

        onCancel={() => setMacroModalVisible(false)}
        cancelButtonProps={{ style: { display: "none" } }}
        // closeIcon={<span></span>}
        footer={[<Col align={"center"} justify={"center"}></Col>]}
        width={"35vw"}
        // style={{ top: "2vw" }}
        className={
          Theme.BACKGROUND === "#000"
            ? "customAntdModalDark"
            : "customAntdModalLight"
        }
      >
        <Col align="middle" style={{ marginTop: "1vw" }}>
          <Col>
            {" "}
            <img
              style={{
                height: "4vw",
                width: "4vw",
                marginBottom: "1vw",
                objectFit: "cover"
              }}
              src={macro}
            />{" "}
            <div>
              {" "}
              <Text
                style={{
                  fontWeight: "bold",
                  fontSize: ".95dvw",
                  bottom: "1vw",
                  position: "relative",
                  color: Theme.TEXT,
                  justify: "center"
                }}
              >
                Create Program
              </Text>
            </div>
          </Col>

          <Row
            aling="middle"
            justify={"center"}
            style={{ marginTop: "1vw", paddingBottom: "1.5vw" }}
          >
            <CreateProgramForm mesoModalVisible={mesoModalVisible} />
          </Row>
        </Col>
      </Modal>
    </Card>
  );
};
export default Cycles;
