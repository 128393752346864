import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Col, Modal, Row, Typography, Spin } from "antd";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button } from "react-bootstrap";
import customblank from "../../assets/images/customblank.jpg";
import { analytics } from "../../firebase-config";
import { logEvent } from "firebase/analytics";
import {
  getSingleBlockTemplate,
  getSingleProgramTemplate,
  resetBlockTemplateStatus,
  resetProgramTemplateStatus
} from "../../redux/actions/workouts";
import { PlusCircleOutlined } from "@ant-design/icons";
import { IoPencilSharp } from "react-icons/io5";
import { CiDumbbell } from "react-icons/ci";
import meso from "../../assets/images/mesocycle.png";

import macro from "../../assets/images/macrocycle.png";
import CreateBlockTemplateForm from "./CreateBlockTemplate";
import CreateProgramTemplateForm from "./CreateProgramTemplateForm";
import EditBlockTemplate from "./EditBlockTemplate";
import AssignProgram from "./AssignProgram";

const { Text } = Typography;

const EditProgramTemplate = () => {
  const dispatch = useDispatch();
  const { Theme } = useSelector((state) => state.auth);
  const { client } = useSelector((state) => state.clients);
  const { programTemplate, programTemplateStatus, blockTemplateStatus } =
    useSelector((state) => state.workouts);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [editBlockModalOpen, setEditBlockModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [fullDescript, setFullDescript] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [assignModalVisible, setAssignModalVisible] = useState(false);
  const [assignProgramId, setAssignProgramId] = useState(null);

  useEffect(() => {
    if (programTemplateStatus === "updated") {
      setEditModalOpen(false);
      dispatch(getSingleProgramTemplate(programTemplate?.id));
      dispatch(resetProgramTemplateStatus());
    }
  }, [programTemplateStatus]);

  useEffect(() => {
    logEvent(analytics, "page_view", { title: "shit piss" });

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const handleCloseModal = () => {
    setCreateModalVisible(false);
    setEditBlockModalOpen(false);
    setEditModalOpen(false);
    if (
      blockTemplateStatus === "success" ||
      programTemplateStatus === "success" ||
      programTemplateStatus === "updated"
    ) {
      dispatch(getSingleProgramTemplate(programTemplate?.id));
      dispatch(resetBlockTemplateStatus());
      dispatch(resetProgramTemplateStatus());
    }
  };

  return (
    <Col
      align="middle"
      justify="center"
      style={{ marginTop: "9vw", position: "relative", width: "100%" }}
      className={`fade-in-slide-up-element ${
        true ? "fade-in-slide-up-animation" : ""
      }`}
    >
      <Col
        style={{
          width: "100%",
          position: "relative",
          bottom: "9vw",
          minHeight: "100vh",

          borderWidth: 0,
          borderRadius: 10,
          paddingBottom: "2vw"
        }}
      >
        {loading ? (
          <Col
            align="middle"
            justify="center"
            style={{ marginTop: "25%", position: "relative", top: "15%" }}
          >
            <Spin
              style={{ marginTop: "25%", position: "relative", top: "15%" }}
              size="large"
            />
          </Col>
        ) : (
          <div>
            {programTemplate && (
              <div style={{ width: "100%" }}>
                <Col
                  style={{
                    marginBottom: programTemplate?.description ? "1vw" : "2vw",

                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                    paddingTop: ".5vw",
                    paddingBottom: "1vw"
                  }}
                >
                  <Row
                    align="end"
                    style={{
                      position: "absolute",
                      right: "2vw",
                      zIndex: 20,
                      top: "2vw"
                    }}
                  >
                    <Button
                      onClick={() => {
                        setAssignProgramId(programTemplate?.id);
                        setAssignModalVisible(true);
                      }}
                      style={{
                        cursor: "pointer",
                        height: "2dvw",
                        width: "3vw",
                        // backgroundImage:
                        //   "linear-gradient(60deg, #C04848 25%, #480048 120%)",
                        borderRadius: 10,
                        borderWidth: 0
                      }}
                    >
                      <div style={{ flexDirection: "column" }}>
                        <div style={{ flexDirection: "row" }}>
                          <Text
                            style={{
                              color: "black",
                              fontSize: ".65vw",
                              textAlign: "center",

                              fontWeight: "bold"
                            }}
                          >
                            <Text style={{ fontSize: ".65vw" }}>Assign </Text>
                          </Text>
                        </div>
                      </div>
                    </Button>
                    <Button
                      color={"PRIMARY"}
                      style={{
                        cursor: "pointer",
                        height: "2dvw",
                        width: "3vw",
                        marginLeft: "1vw",
                        borderRadius: 10,
                        borderWidth: 0
                      }}
                      onClick={() => setEditModalOpen(true)}
                    >
                      <div style={{ flexDirection: "column" }}>
                        <div style={{ flexDirection: "row" }}>
                          <Text
                            style={{
                              color: "black",
                              fontSize: ".65vw",
                              textAlign: "center",

                              fontWeight: "bold"
                            }}
                          >
                            Edit
                          </Text>
                        </div>
                      </div>
                    </Button>
                  </Row>
                  <Col
                    align="start"
                    style={{ position: "absolute", left: "2vw", zIndex: 1 }}
                  >
                    <Row align="middle" justify="center"></Row>
                  </Col>
                  <Row
                    align="middle"
                    justify="start"
                    style={{
                      marginBottom: programTemplate?.description
                        ? "1vw"
                        : "2vw",
                      zIndex: 0,
                      textAlign: "left"
                    }}
                  >
                    <img
                      style={{
                        height: "4vw",
                        width: "5.5vw",
                        borderRadius: 10,
                        objectFit: "contain",
                        marginTop: ".75vw"
                      }}
                      src={macro}
                      alt="macro"
                    />
                    <Col style={{ marginTop: ".75vw" }}>
                      <Text
                        style={{
                          fontSize: "1.3vw",
                          color: Theme.TEXT,
                          fontWeight: "bold"
                        }}
                      >
                        {programTemplate?.name}
                      </Text>
                      {programTemplate?.description && (
                        <div style={{ width: "90%" }}>
                          {programTemplate?.description.length < 230 ? (
                            <Text
                              style={{ fontSize: ".75vw", color: Theme.TEXT }}
                            >
                              {programTemplate?.description}
                            </Text>
                          ) : fullDescript ? (
                            <Text
                              style={{ fontSize: ".75vw", color: Theme.TEXT }}
                            >
                              {programTemplate?.description}{" "}
                              <span
                                onClick={() => setFullDescript(false)}
                                style={{
                                  fontSize: ".75vw",
                                  color: Theme.PRIMARY,
                                  cursor: "pointer"
                                }}
                              >
                                Show Less
                              </span>
                            </Text>
                          ) : (
                            <Row>
                              <Text
                                style={{
                                  fontSize: ".75vw",
                                  color: Theme.TEXT
                                }}
                              >
                                {programTemplate?.description.slice(0, 230)}
                                ...{" "}
                                <span
                                  onClick={() => setFullDescript(true)}
                                  style={{
                                    fontSize: ".75vw",
                                    color: Theme.PRIMARY,
                                    cursor: "pointer"
                                  }}
                                >
                                  Show More
                                </span>
                              </Text>{" "}
                            </Row>
                          )}
                        </div>
                      )}
                    </Col>
                  </Row>
                  <hr color={Theme.TEXT2} style={{ borderWidth: 0 }} />
                </Col>
                <Row
                  align={"middle"}
                  justify={"center"}
                  style={{
                    marginLeft: "0vw",
                    width: "100%",
                    top: 0,
                    position: "absolute"
                  }}
                >
                  {!programTemplate?.mesocycles?.length && (
                    <Col
                      align="middle"
                      style={{
                        // marginLeft: "5vw",
                        position: "relative",
                        // right: "5%",
                        marginTop: "15vw",
                        width: "100%"
                      }}
                    >
                      <CiDumbbell size={"3vw"} style={{ color: Theme.TEXT }} />
                      <div>
                        <Text style={{ fontSize: "1vw", color: Theme.TEXT }}>
                          No Blocks
                        </Text>
                      </div>
                      <div>
                        <Button
                          color={"PRIMARY"}
                          style={{
                            padding: ".5vw",
                            marginTop: "1vw",
                            paddingRight: ".5vw",
                            cursor: "pointer",
                            backgroundImage:
                              "linear-gradient(60deg, #C04848 25%, #480048 120%)",
                            borderRadius: 10,
                            borderWidth: 0
                          }}
                          onClick={() => setCreateModalVisible(true)}
                        >
                          <div style={{ flexDirection: "column" }}>
                            <div style={{ flexDirection: "row" }}>
                              <Text
                                style={{
                                  color: "white",
                                  fontSize: ".65vw",
                                  textAlign: "center",

                                  fontWeight: "bold"
                                }}
                              >
                                <PlusCircleOutlined
                                  style={{
                                    position: "relative",
                                    fontSize: ".7vw",
                                    color: Theme.WHITE,
                                    cursor: "pointer"
                                  }}
                                />{" "}
                                Add Block
                              </Text>
                            </div>
                          </div>
                        </Button>
                      </div>
                    </Col>
                  )}
                </Row>
              </div>
            )}

            <Col
              align={"middle"}
              justify={"center"}
              style={{ marginTop: "4vw" }}
            >
              {programTemplate?.mesocycles?.length > 0 && (
                <Col>
                  <Col align={"center"} justify={"center"}>
                    <Col align={"end"}>
                      <Button
                        color={"PRIMARY"}
                        style={{
                          padding: ".5vw",
                          marginRight: "1.5vw",
                          marginBottom: "1vw",
                          cursor: "pointer",
                          backgroundImage:
                            "linear-gradient(60deg, #C04848 25%, #480048 120%)",
                          borderRadius: 10,
                          borderWidth: 0,
                          position: "absolute",
                          top: -75,
                          width: "6vw",
                          right: -25
                        }}
                        onClick={() => setCreateModalVisible(true)}
                      >
                        <div style={{ flexDirection: "column" }}>
                          <div style={{ flexDirection: "row" }}>
                            <Text
                              style={{
                                color: "white",
                                fontSize: ".55vw",
                                textAlign: "center",

                                fontWeight: "bold"
                              }}
                            >
                              <PlusCircleOutlined
                                style={{
                                  position: "relative",
                                  fontSize: ".55vw",
                                  color: Theme.WHITE,
                                  cursor: "pointer"
                                }}
                              />{" "}
                              Add Block
                            </Text>
                          </div>
                        </div>
                      </Button>
                    </Col>
                  </Col>
                  <Col>
                    {programTemplate &&
                      programTemplate?.mesocycles
                        .sort((a, b) => new Date(a.order) - new Date(b.order))
                        .map((block) => {
                          return (
                            <Card
                              key={block.id}
                              bordered={false}
                              style={{
                                width: "70%",
                                borderWidth: 0,
                                cursor: "pointer",
                                marginBottom: "1vw",
                                boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.175)",
                                background: Theme.BACKGROUND
                              }}
                              onClick={() => {
                                setEditBlockModalOpen(true);
                                dispatch(getSingleBlockTemplate(block?.id));
                              }}
                            >
                              <Row justify={"center"} align={"middle"}>
                                <Row
                                  justify={"start"}
                                  align={"middle"}
                                  style={{ marginRight: "2vw" }}
                                >
                                  <Col
                                    justify={"start"}
                                    align={"middle"}
                                    style={{ width: "20vw" }}
                                  >
                                    <Text
                                      style={{
                                        color: Theme.TEXT,
                                        fontWeight: "bold",
                                        fontSize: "1vw",
                                        width: "20vw", // Adjusted for more realistic width
                                        textAlign: "left",
                                        overflow: "hidden",
                                        display: "-webkit-box",
                                        WebkitBoxOrient: "vertical",
                                        WebkitLineClamp: 2, // Limits the text to 2 lines
                                        whiteSpace: "normal" // Allows text wrapping
                                      }}
                                    >
                                      {block?.name}
                                    </Text>
                                  </Col>
                                  <Row align={"start"} justify={"space-evenly"}>
                                    <Col
                                      align={"start"}
                                      style={{
                                        position: "relative",
                                        right: "5%",
                                        textAlign: "center",
                                        width: "4vw"
                                      }}
                                    >
                                      <div>
                                        <Text
                                          style={{
                                            color: Theme.TEXT2,
                                            fontWeight: "bold",
                                            fontSize: ".85vw"
                                            // marginLeft: "5vw",
                                          }}
                                        >
                                          Length
                                        </Text>
                                      </div>

                                      <Text
                                        style={{
                                          color: Theme.PRIMARY,
                                          fontWeight: "bold",
                                          fontSize: ".85vw"
                                        }}
                                      >
                                        {block.numOfWeeks} Weeks
                                      </Text>
                                    </Col>
                                    <Col
                                      align={"start"}
                                      style={{
                                        position: "relative",
                                        left: "35%",
                                        textAlign: "center"
                                      }}
                                    >
                                      <div>
                                        <Text
                                          style={{
                                            color: Theme.TEXT2,
                                            fontWeight: "bold",
                                            fontSize: ".85vw"
                                            // marginLeft: "5vw",
                                          }}
                                        >
                                          Order
                                        </Text>
                                      </div>

                                      <Text
                                        style={{
                                          color: Theme.PRIMARY,
                                          fontWeight: "bold",
                                          fontSize: ".85vw"
                                        }}
                                      >
                                        {block.order}
                                      </Text>
                                    </Col>
                                  </Row>
                                </Row>
                              </Row>
                            </Card>
                          );
                        })}
                  </Col>
                </Col>
              )}
            </Col>
          </div>
        )}

        <Modal
          open={editModalOpen}
          onCancel={() => setEditModalOpen(false)}
          cancelButtonProps={{ style: { display: "none" } }}
          footer={[<Col align={"center"} justify={"center"}></Col>]}
          width={"35vw"}
          className={
            Theme.BACKGROUND === "#000"
              ? "customAntdModalDark"
              : "customAntdModalLight"
          }
        >
          <Col align="middle" style={{ marginTop: "1vw" }}>
            <Col>
              {" "}
              <img
                style={{
                  height: "4vw",
                  width: "4vw",
                  objectFit: "cover",
                  marginBottom: "1vw"
                }}
                alt="macro"
                src={macro}
              />{" "}
              <div>
                {" "}
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: ".95dvw",
                    bottom: "1vw",
                    position: "relative",
                    color: Theme.TEXT,
                    justify: "center",
                    marginTop: "1vw"
                  }}
                >
                  Update Program Template
                </Text>
              </div>
            </Col>

            <Row
              aling="middle"
              justify={"center"}
              style={{ marginTop: "1vw", paddingBottom: "1.5vw" }}
            >
              <CreateProgramTemplateForm
                programTemplateData={programTemplate}
                modalOpen={editModalOpen}
                closeModal={handleCloseModal}
              />
            </Row>
          </Col>
        </Modal>
        <Modal
          open={createModalVisible}
          onCancel={() => setCreateModalVisible(false)}
          cancelButtonProps={{ style: { display: "none" } }}
          // closeIcon={<span></span>}
          footer={[<Col align={"center"} justify={"center"}></Col>]}
          width={"35vw"}
          style={{ top: "2vw" }}
          className={
            Theme.BACKGROUND === "#000"
              ? "customAntdModalDark"
              : "customAntdModalLight"
          }
        >
          <Col align="middle" style={{ marginTop: "1vw" }}>
            <Col>
              {" "}
              <img
                style={{
                  height: "4vw",
                  width: "4vw",
                  objectFit: "cover"
                }}
                src={meso}
                alt="Block"
              />{" "}
              <div>
                {" "}
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: ".95dvw",
                    bottom: "1vw",
                    position: "relative",
                    color: Theme.TEXT,
                    justify: "center"
                  }}
                >
                  Create Block Template
                </Text>
              </div>
            </Col>

            <Row
              aling="middle"
              justify={"center"}
              style={{ marginTop: "1vw", paddingBottom: "1.5vw" }}
            >
              <CreateBlockTemplateForm
                mesoModalVisible={createModalVisible}
                prog={programTemplate?.id}
                closeModal={() => setCreateModalVisible(false)}
                editModalOpen={setEditBlockModalOpen}
              />
            </Row>
          </Col>
        </Modal>
        <Modal
          open={editBlockModalOpen}
          onCancel={() => setEditBlockModalOpen(false)}
          cancelButtonProps={{ style: { display: "none" } }}
          footer={[<Col align={"center"} justify={"center"}></Col>]}
          width={"90vw"}
          style={{ top: "1vw" }}
          className={
            Theme.BACKGROUND === "#000"
              ? "customAntdModalDark"
              : "customAntdModalLight"
          }
        >
          <EditBlockTemplate closeModal={() => setEditBlockModalOpen(false)} />
        </Modal>
        <Modal
          open={assignModalVisible}
          onCancel={() => setAssignModalVisible(false)}
          cancelButtonProps={{ style: { display: "none" } }}
          footer={[<Col align={"center"} justify={"center"}></Col>]}
          width={"30vw"}
          style={{ top: "5vw" }}
          className={
            Theme.BACKGROUND === "#000"
              ? "customAntdModalDark"
              : "customAntdModalLight"
          }
        >
          <Col>
            <AssignProgram
              closeModal={() => setAssignModalVisible(false)}
              assignProgramId={assignProgramId}
            />
          </Col>
        </Modal>
      </Col>
    </Col>
  );
};

export default EditProgramTemplate;
