import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col } from "antd";
import { Formik } from "formik";
import * as yup from "yup";
import { css, StyleSheet } from "aphrodite";
import Theme from "../../constants/Theme";
import axios from "axios";
import { REACT_APP_API_URL } from "../../env";
import DebounceSelect from "../loaders/DebounceSelect";
import { assignBlockTemplate } from "../../redux/actions/workouts";
import "../../assets/styles/calendar.css";
import StyledDatePickerComponent from "../common/DatePickerWorkouts";

const AssignBlock = ({ closeModal, assignBlockId }) => {
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  const [selectedStartDate, setSelectedDate] = useState(null);
  const [clientIds, setClientIds] = useState([]);

  const { Theme } = useSelector((state) => state.auth);

  const handleClientAdd = (selectedClients) => {
    setClientIds(selectedClients); // Update clientIds directly with the selected clients
  };

  const handleSubmit = async (values, { resetForm }) => {
    const newDate = new Date(selectedStartDate);
    const formattedDate = newDate.toISOString();
    const indexOfT = formattedDate.indexOf("T");
    const dateBeforeT = formattedDate.substring(0, indexOfT);
    const data = {
      client_ids: clientIds,
      start_date: dateBeforeT
    };

    // Dispatch action to update trainer groups
    dispatch(assignBlockTemplate(assignBlockId, data));

    // Reset the form
    resetForm();

    // Close the modal
    closeModal();
  };

  async function fetchClientsList(searchTerm) {
    try {
      const token = await localStorage.getItem("access");
      const config = {
        headers: {
          Authorization: `JWT ${token}`
        }
      };

      const response = await axios.get(
        `${REACT_APP_API_URL}/api/clientsnew/?search=${searchTerm}`,
        config
      );
      const filteredClients = response.data.results.filter((client) => {
        // Exclude clients already in the group + Guests
        const isGuest = client.isGuest === true;
        return !isGuest;
      });
      return filteredClients.map((client) => ({
        label: `${client.first_name} ${client.last_name}`,
        value: client.id.toString()
      }));
    } catch (error) {
      return [];
    }
  }

  return (
    <Col
      bordered={true}
      style={{
        zIndex: 100,
        position: "relative"
      }}
      className="criclebox h-full"
    >
      <div>
        <Formik
          initialValues={{
            trainer_id: [],
            account_type: "",
            dates: []
          }}
          ref={formikRef}
          onSubmit={handleSubmit}
          enableReinitialize={true}
          validationSchema={yup.object().shape({
            trainer_id: yup.array().required("Required")
          })}
        >
          {({
            values,
            setFieldValue,
            handleChange,
            handleSubmit,
            resetForm,
            touched,
            errors
          }) => (
            <Col align="center" className={css(styles.registerContainer)}>
              <div className={css(styles.block, styles.center)}>
                <div className={css(styles.title)}>
                  <p
                    className={css(styles.titleText)}
                    style={{ color: Theme.TEXT }}
                  >
                    Assign Block
                  </p>
                </div>

                <Row
                  justify={"center"}
                  className={css(styles.row)}
                  style={{
                    bottom: 15,
                    marginTop: ".75vw"
                  }}
                >
                  <div
                    style={{ marginTop: ".5vw" }}
                    className={css(styles.column, styles.margin5)}
                  >
                    <DebounceSelect
                      style={{ width: "15vw", bottom: 5 }}
                      showSearch
                      suffixIcon={null}
                      placeholder={
                        <span
                          style={{
                            color:
                              Theme.BACKGROUND === "#000" ? "#fff" : "#000",
                            fontSize: ".65vw",
                            top: ".05vw",
                            position: "relative",
                            fontWeight: "normal"
                          }}
                        >
                          Search Clients
                        </span>
                      }
                      mode="multiple"
                      tokenSeparators={[","]}
                      optionFilterProp="children"
                      onChange={handleClientAdd}
                      fetchOptions={fetchClientsList}
                      debounceTimeout={800}
                    />
                  </div>
                  <Col
                    style={{
                      zIndex: 100,
                      flex: 1,
                      position: "relative",
                      width: "35vw",
                      marginBottom: "1vw",
                      marginTop: ".5vw",
                      background: Theme.CARD
                    }}
                    className="criclebox h-full"
                  >
                    <Col
                      justify={"center"}
                      align={"center"}
                      style={{
                        zIndex: 100,
                        flex: 1,
                        position: "relative",
                        width: "30vw",
                        right: ".5vw",
                        background: Theme.CARD
                      }}
                    >
                      <StyledDatePickerComponent
                        selected={selectedStartDate}
                        onChange={(date) => setSelectedDate(date)}
                        placeholder={"Start Date"}
                        width={"2vw"}
                        onClear={() => setSelectedDate(null)}
                      />
                      {touched.dates && errors.dates && (
                        <p className={css(styles.errors)}>{errors.dates}</p>
                      )}
                    </Col>
                  </Col>
                </Row>

                <button
                  className={css(styles.createButton)}
                  type="submit"
                  onClick={handleSubmit}
                  style={{
                    cursor: "pointer"
                  }}
                >
                  <div className={css(styles.buttonGradient)}>
                    <p className={css(styles.buttontext)}>Submit</p>
                  </div>
                </button>
              </div>
            </Col>
          )}
        </Formik>
      </div>
    </Col>
  );
};

export default AssignBlock;

const styles = StyleSheet.create({
  title: {
    color: "#232b2b",
    fontSize: 18,
    bottom: 25,
    fontWeight: "600",
    alignSelf: "center"
  },
  registerContainer: {
    borderRadius: 10,
    shadowColor: Theme.COLORS.BLACK,
    shadowOffset: {
      width: 0,
      height: 4
    },
    justifyContent: "center",
    shadowRadius: 8,
    shadowOpacity: 0.3,
    elevation: 1
  },
  inputIcons: {
    marginRight: 12
  },
  titleText: { fontSize: "1vw" },
  createButton: {
    width: "5vw",
    backgroundImage: "linear-gradient(45deg, #C04848 0%, #480048 80%)",
    borderWidth: 0,
    borderRadius: 10
  },
  buttontext: {
    color: Theme.COLORS.WHITE,
    fontWeight: "bold",
    marginHorizontal: 5
  },
  errors: {
    position: "relative",
    color: "red",
    fontSize: ".75vw"
  },
  checkbox: {
    color: Theme.COLORS.PRIMARY,
    borderRadius: 5,
    width: 15,
    height: 15,
    position: "relative",
    right: "5%",
    top: "10%"
  },
  movementTitle: {
    alignSelf: "center",
    fontSize: "1.3vw",
    fontWeight: "bold",
    color: Theme.COLORS.BLACK,
    textAlign: "center"
  },
  clientDescr: {
    alignSelf: "center",
    fontSize: ".75vw",
    color: Theme.COLORS.BLACK,
    textAlign: "center"
  }
});
