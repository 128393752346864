import { Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import Admin from "./pages/Admin";
import Templates from "./pages/Templates";
import RangeTemplateDetails from "./pages/RangeTemplateDetails";
import Rtl from "./pages/Rtl";
import Profile from "./pages/Profile";
import Signup from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import Main from "./components/layout/Main";
// import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import { store } from "./store";
import { Provider, useDispatch, useSelector } from "react-redux";
import "./assets/styles/forms.css";
import ClientDetails from "./pages/ClientDetails";
import GroupDetails from "./pages/GroupDetails";
import TrainerGroupDetails from "./pages/TrainerGroupDetails";

import Results from "./pages/Results";
import "typeface-inter";
import {
  useHistory,
  useLocation
} from "react-router-dom/cjs/react-router-dom.min";
import { asyncCheckStatus } from "./redux/actions/auth";
import { checkAuthenticated } from "./redux/actions/auth";
import { useEffect, useState } from "react";
import LottieAnimation from "./components/loaders/Loading";
import CreateClient from "./pages/CreateClient";
import TrendReportDashboard from "./pages/TrendDash";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import SubscriptionForm from "./pages/Subscription";

import { analytics } from "./firebase-config"; // Update the path accordingly
import { logEvent } from "firebase/analytics";
import BlockDetails from "./pages/BlockDetails";
import ProgramDetails from "./pages/ProgramDetails";
import JCResults from "./pages/JCResults";
import ResetPasswordConfirm from "./pages/ResetPasswordConfirm";
import PDFResults from "./pages/PDFResults";
import Library from "./pages/Library";

const isProduction = process.env.NODE_ENV === "production";

const stripePromise = loadStripe(
  isProduction
    ? "pk_live_51NSp4VBr2TxWd5qZxWRBgCkbzc4lXzd1KxO2NiCISjvoqIowZWkexE094VzmFvQjwyEzBSLurXkU6Tgp327KoMnd005qiukY5s"
    : "pk_test_51NSp4VBr2TxWd5qZhPh2qztpj9fPT987ayPG3qfWHxwLRMPrwFNRz1WK2aeGuEARN48rv9Hj0FaW83ALIIr9tZfO00pOuysQrT"
);

const AuthWrapper = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  const { isAuthenticated, Theme } = useSelector((state) => state.auth);
  const { subscriptionStatus, statusChecked } = useSelector(
    (state) => state.payment
  );
  useEffect(() => {
    const pathSegments = location.pathname.split("/").filter(Boolean);
    const pageTitle = pathSegments.length > 0 ? pathSegments[0] : "home";

    logEvent(analytics, "page_view", {
      page_location: location.pathname,
      page_path: location.pathname,
      page_title: pageTitle
    });
  }, [location]);

  useEffect(() => {
    let isMounted = true;

    const checkAuthAndStatus = async () => {
      await dispatch(checkAuthenticated());

      if (isAuthenticated) {
        await dispatch(asyncCheckStatus());
      }
      if (isAuthenticated !== null && !isAuthenticated) {
        setIsLoading(false);
      }
      if (isMounted && statusChecked) {
        setIsLoading(false);
      }
    };

    checkAuthAndStatus();

    return () => {
      isMounted = false;
    };
  }, [dispatch, isAuthenticated, statusChecked]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "150vh",
          width: "100%",
          position: "relative",
          right: "1vw",
          bottom: "1vw",
          backgroundImage: "linear-gradient(45deg, #000 30%,  #1C1F22 100%)"
        }}
      >
        <LottieAnimation />
      </div>
    );
  }

  return <>{children}</>;
};

function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <Switch>
          <Route path="/sign-up" exact component={Signup} />
          <Route path="/sign-in" exact component={SignIn} />
          <Route
            path="/set-password/:uid/:token"
            exact
            component={ResetPasswordConfirm}
          />
          <Route
            exact
            path="/subscribe"
            component={() => (
              <Elements stripe={stripePromise}>
                <SubscriptionForm />
              </Elements>
            )}
          />
          <Route exact path="/pdfresults" component={PDFResults} />
          <AuthWrapper>
            <Main>
              <Elements stripe={stripePromise}>
                <Route exact path="/" component={Home} />
              </Elements>

              <Route exact path="/client/:id" component={ClientDetails} />
              <Route exact path="/group/:id" component={GroupDetails} />
              <Route
                exact
                path="/trainergroup/:id"
                component={TrainerGroupDetails}
              />
              <Route
                exact
                path="/block/:clientId/:id"
                component={BlockDetails}
              />
              <Route
                exact
                path="/program/:clientId/:id"
                component={ProgramDetails}
              />
              <Route exact path="/createclient" component={CreateClient} />
              <Route exact path="/admin" component={Admin} />
              <Route exact path="/rtl" component={Rtl} />
              <Route exact path="/account" component={Profile} />
              <Route exact path="/templates" component={Templates} />
              <Route exact path="/library" component={Library} />

              <Route
                exact
                path="/templates/ranges/:id"
                component={RangeTemplateDetails}
              />
              <Route exact path="/trends" component={TrendReportDashboard} />
              <Route exact path="/results/:clientId/:id" component={Results} />
              <Route
                exact
                path="/jcresults/:clientId/:id"
                component={JCResults}
              />
            </Main>
          </AuthWrapper>
        </Switch>
      </div>
    </Provider>
  );
}

export default App;
