import { useCallback, useEffect, useState } from "react";

import {
  Card,
  Col,
  Input,
  Modal,
  Row,
  Typography,
  Tabs,
  TabsProps,
  Collapse,
  Pagination
} from "antd";
import {
  DotChartOutlined,
  UserOutlined,
  UserAddOutlined
} from "@ant-design/icons";

import genericPic from "../assets/images/profilepic.jpg";

import clientpic from "../assets/images/clientpic.png";
import headerPic from "../assets/images/trainer.jpeg";
import { useDispatch, useSelector } from "react-redux";
import {
  checkAuthenticated,
  checkStatus,
  load_user
} from "../redux/actions/auth";
import quotes from "../constants/quotes";
import Theme from "../constants/Theme";
import {
  getTrainers,
  getSingleTrainer,
  searchTrainers
} from "../redux/actions/trainers";
import {
  getGroups,
  getSingleGroup,
  searchGroups,
  resetGroup
} from "../redux/actions/groups";
import { StyleSheet, css } from "aphrodite";
import AOS from "aos";
import "aos/dist/aos.css";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
import {
  Link,
  useHistory,
  useLocation
} from "react-router-dom/cjs/react-router-dom.min";
import { Button } from "react-bootstrap";
import { IoAddCircleOutline, IoEllipsisHorizontalSharp } from "react-icons/io5";
import CreateTrainerForm from "../components/results/CreateTrainerForm";
import ProfileComponent from "../components/ProfileComponent";
import GroupProfile from "../components/GroupProfile";
import CreateGroupForm from "../components/results/CreateGroupForm";
import BootstrapSearch from "../components/common/Search";
import { resetFilters } from "../redux/actions/session";

function Admin() {
  const { Title, Text } = Typography;

  const { user, isAuthenticated, Theme } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const fetchUser = () => dispatch(load_user());
  const [query, setQuery] = useState("");
  const [trainerModalVisible, setTrainerModalVisible] = useState(false);
  const [addGroupModalVisible, setAddGroupModalVisible] = useState(false);
  const [editTrainerModalVisible, setEditTrainerModalVisible] = useState(false);
  const [editGroupModalVisible, setEditGroupModalVisible] = useState(false);

  const [isReset, resetProfileComponenet] = useState(false);
  const [trainerId, setTrainerID] = useState(null);
  const [groupId, setGroupID] = useState(null);

  const location = useLocation();
  const [activeTab, setActiveTab] = useState("trainers");

  useEffect(() => {
    if (location.state && location.state.fromGroups) {
      setActiveTab("groups");
    } else {
      setActiveTab("trainers");
    }
  }, [location.state]);

  const handleTrainerSearch = (text) => {
    dispatch(searchTrainers(text));
    setQuery(text);
  };

  const handleGroupSearch = (text) => {
    dispatch(searchGroups(text));
    setQuery(text);
  };

  const { trainers, curr_trainers, total_trainers } = useSelector(
    (state) => state.trainers
  );

  useEffect(() => {
    fetchUser();
    AOS.init();
    tokenCheck();
    dispatch(getTrainers(1, false));
    dispatch(getGroups());
    dispatch(checkStatus);
    dispatch(resetGroup());
    dispatch(resetFilters());
  }, []);
  const { groups } = useSelector((state) => state.groups);
  const tokenCheck = () => dispatch(checkAuthenticated());
  const history = useHistory();

  const handleClick = (id) => {
    dispatch(getSingleGroup(id));
    return history.push(`/group/${id}`);
  };

  const handleEditTrainerClick = (trainer) => {
    setTrainerID(trainer);
    setEditTrainerModalVisible(true);
  };

  const handleEditGroupClick = (group) => {
    setGroupID(group);
    setEditGroupModalVisible(true);
  };

  useEffect(() => {
    if (!isAuthenticated) {
      history.push("/sign-in");
    }
  }, [isAuthenticated]);

  const itemRender = (current, type, originalElement) => {
    if (type === "page") {
      const color = Theme.PRIMARY;
      return <span style={{ color }}>{current}</span>;
    }
    return originalElement;
  };

  const paginateChange = (page) => {
    if (query == "") {
      dispatch(getTrainers(page, false));
    } else {
      dispatch(searchTrainers(query, page));
    }
  };

  const count = [
    {
      today: "Trainers",
      title: user && total_trainers,
      icon: <UserOutlined style={styles.icon} />,
      bnb: "bnb2"
    },

    {
      today: "Teams",
      title: user && groups.length,
      icon: <DotChartOutlined style={styles.icon} />,
      bnb: "bnb2"
    }
  ];
  const filteredCount = count.filter(
    (c) => c.today !== "Teams" || user?.profile?.account_type === 2
  );

  const [isRowLayout, setIsRowLayout] = useState(window.innerWidth >= 1000);
  const flexDirection = isRowLayout ? "row" : "column";

  const handleWindowResize = () => {
    setIsRowLayout(window.innerWidth >= 992);
  };

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener("resize", handleWindowResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  return (
    <Col
      style={{
        position: "relative"
      }}
    >
      <Row
        data-aos="fade-right"
        data-aos-anchor-placement="bottom-bottom"
        data-aos-duration="1000"
        align={"center"}
        style={{
          position: "relative",
          // width: "100vw",
          left: "7vw",
          top: "1.5vw",
          justifyContent: "center", // Center the content horizontally
          alignSelf: "center" // Center the content vertically
        }}
      >
        {filteredCount.map((c, index) => (
          <Card
            key={index}
            style={{
              width: "20vw", // Adjust the width of each Col using vw units
              marginLeft: "1vw",
              background: Theme.CARD,
              borderWidth: 0,
              alignSelf: "center"
            }}
          >
            <div className="number">
              <Row align="middle">
                <Col style={{ left: "1vw", position: "relative" }}>
                  <span style={{ fontSize: "1vw", color: Theme.TEXT2 }}>
                    {c.today}
                  </span>{" "}
                  {/* Adjust font size */}
                  {c.title ? (
                    <Title
                      level={3}
                      style={{
                        fontSize: "1.25vw",
                        position: "relative",
                        left: ".25vw",
                        bottom: ".75vw",
                        color: Theme.TEXT
                      }}
                    >
                      {c.title}
                    </Title>
                  ) : (
                    <Title
                      level={3}
                      style={{
                        fontSize: "1.25vw",
                        position: "relative",
                        left: ".25vw",
                        bottom: ".75vw",
                        color: Theme.TEXT
                      }}
                    >
                      {c.title}
                    </Title>
                  )}
                </Col>
                <Col style={{ position: "relative", left: "65%" }}>
                  <div
                    style={{
                      right: "2vw", // Adjust the right position using vw units
                      position: "relative"
                    }}
                    className="icon-box"
                  >
                    {c.icon}
                  </div>
                </Col>
              </Row>
            </div>
          </Card>
        ))}
      </Row>
      <Row
        data-aos="fade-up"
        data-aos-easing="linear"
        data-aos-duration="750"
        style={{
          position: "relative",
          // width: isRowLayout ? "100vw" : '90vw',
          top: "3vw",
          // justifyContent: "center", // Center the content horizontally
          // alignItems: "center", // Center the content vertically
          marginBottom: 500
        }}
        gutter={[24, 0]}
      >
        <Col
          style={{
            paddingLeft: "4vw", // Adjust the left padding using vw units
            paddingRight: "4vw" // Adjust the right padding using vw units
          }}
          xs={28}
          sm={24}
          md={18}
          lg={18}
          xl={16}
        >
          <Tabs
            style={{ width: "10vw", outline: "none" }}
            className={Theme.BACKGROUND === "#000" ? "dark-mode" : "light-mode"}
            activeKey={activeTab}
            onChange={setActiveTab}
          >
            <Tabs.TabPane tab="Trainers" key="trainers">
              <Card
                bordered={true}
                style={{
                  width: "50vw",
                  background: Theme.CARD,
                  borderWidth: 0
                }}
                className="criclebox cardbody"
              >
                <Row className="project-ant" style={{ marginBottom: "1.5vw" }}>
                  <Col style={styles.fieldrow}>
                    <BootstrapSearch
                      placeholder="Search Trainers"
                      onChange={(e) => handleTrainerSearch(e.target.value)}
                      width="15vw" // Set the width here
                    />
                  </Col>
                  <Button
                    // onClick={() => history.push('/createclient')}
                    onClick={() => setTrainerModalVisible(true)}
                    style={{
                      color: "white",
                      borderWidth: 0,
                      borderRadius: 12,
                      height: "2vw",
                      width: "7vw",
                      fontWeight: "bold",
                      fontSize: ".75vw",
                      cursor: "pointer",
                      backgroundImage:
                        "linear-gradient(45deg, #C04848 0%, #480048 80%)"
                    }}
                  >
                    <IoAddCircleOutline
                      style={{
                        top: "5%",
                        marginRight: "4%",
                        position: "relative"
                      }}
                    />
                    New Trainer
                  </Button>
                </Row>
                {trainers.length > 0 ? (
                  <div
                    style={{ paddingLeft: "4vw" }}
                    className="ant-list-box table-responsive"
                  >
                    <table className="width-100">
                      <thead>
                        <tr>
                          <th style={{ fontSize: ".85vw", color: Theme.TEXT2 }}>
                            NAME
                          </th>{" "}
                          <th
                            style={{
                              fontSize: ".85vw",
                              right: "3.5vw",
                              position: "relative"
                            }}
                          ></th>{" "}
                          <th style={{ fontSize: ".85vw", color: Theme.TEXT2 }}>
                            EMAIL
                          </th>{" "}
                        </tr>
                      </thead>
                      <tbody>
                        {trainers &&
                          trainers.map((d, index) => {
                            const last_assessment = new Date("09/17/2023");
                            var trainerPhotoPath = genericPic;
                            if (d.profile_picture != null) {
                              trainerPhotoPath = d.profile_picture;
                            }

                            return (
                              <tr key={index}>
                                <td style={{ fontSize: ".75vw", width: "50%" }}>
                                  <Row style={{ fontSize: "1vw" }}>
                                    <img
                                      src={trainerPhotoPath}
                                      alt=""
                                      className="avatar-sm mr-10"
                                      style={{
                                        height: "3vw", // Adjust the image height using vw units
                                        width: "3vw",
                                        position: "relative",
                                        borderRadius: 100
                                      }}
                                    />
                                    {"   "}
                                    <p
                                      style={{
                                        position: "relative",
                                        left: "1vw",
                                        fontSize: ".85vw",
                                        color: Theme.TEXT
                                      }}
                                    >
                                      {" "}
                                      {d.user_account.first_name}{" "}
                                      {d.user_account.last_name}
                                    </p>
                                  </Row>
                                </td>

                                <td
                                  style={{
                                    fontSize: ".85vw",
                                    color: Theme.TEXT
                                  }}
                                >
                                  <span className="text-xs font-weight-bold">
                                    {d.total_assessments}{" "}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    fontSize: ".85vw",
                                    color: Theme.TEXT
                                  }}
                                >
                                  {d.user_account.email}
                                </td>
                                <td>
                                  <IoEllipsisHorizontalSharp
                                    style={{
                                      cursor: "pointer",
                                      color: Theme.TEXT
                                    }}
                                    onClick={() => handleEditTrainerClick(d)}
                                  >
                                    {" "}
                                  </IoEllipsisHorizontalSharp>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <Col
                    style={{
                      textAlign: "center",
                      marginTop: "3vw",
                      marginBottom: "2vw"
                    }}
                  >
                    <UserAddOutlined
                      style={{ fontSize: "3vw", color: Theme.PRIMARY }}
                    />
                    <Text
                      style={{
                        display: "block",
                        color: Theme.TEXT,
                        marginTop: 10,
                        fontSize: "1vw"
                      }}
                    >
                      No Trainers
                    </Text>
                  </Col>
                )}
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    paddingTop: "1vw"
                  }}
                >
                  <Pagination
                    onChange={paginateChange}
                    total={curr_trainers}
                    defaultCurrent={1}
                    defaultPageSize={10}
                    itemRender={itemRender}
                    showSizeChanger={false}
                  />
                </Row>
                <Modal
                  open={editTrainerModalVisible}
                  onCancel={() => {
                    setEditTrainerModalVisible(false);
                  }}
                  footer={null}
                  width={"30vw"}
                  height={"30vw"}
                  bodyStyle={{
                    height: "60vw",
                    width: "auto",
                    padding: ".5vw",
                    position: "relative",
                    bottom: "1vw"
                  }} // Set height and width to "auto"
                  className={
                    Theme.BACKGROUND === "#000"
                      ? "customAntdModalDark"
                      : "customAntdModalLight"
                  }
                >
                  <ProfileComponent
                    trainer={trainerId}
                    closeModal={() => setEditTrainerModalVisible(false)}
                  ></ProfileComponent>
                </Modal>
              </Card>
            </Tabs.TabPane>
            {(user?.profile?.account_type === 2 || groups.length > 0) && (
              <Tabs.TabPane tab="Teams" key="groups">
                <Card
                  bordered={true}
                  style={{
                    width: "50vw",
                    background: Theme.CARD,
                    borderWidth: 0
                  }}
                  className="criclebox cardbody"
                >
                  <Row
                    className="project-ant"
                    style={{ marginBottom: "1.5vw" }}
                  >
                    <BootstrapSearch
                      placeholder="Search Teams"
                      onChange={(e) => handleGroupSearch(e.target.value)}
                      width="15vw" // Set the width here
                    />
                    {user?.profile?.account_type == 2 && (
                      <Button
                        onClick={() => setAddGroupModalVisible(true)}
                        style={{
                          color: "white",
                          borderWidth: 0,
                          borderRadius: 12,
                          height: "2vw",
                          width: "7vw",
                          fontWeight: "bold",
                          fontSize: ".75vw",
                          cursor: "pointer",
                          backgroundImage:
                            "linear-gradient(45deg, #C04848 0%, #480048 80%)"
                        }}
                      >
                        <IoAddCircleOutline
                          style={{
                            top: "5%",
                            marginRight: "4%",
                            position: "relative"
                          }}
                        />
                        New Team
                      </Button>
                    )}
                  </Row>
                  <div
                    style={{ paddingLeft: "4vw" }}
                    className="ant-list-box table-responsive"
                  >
                    <table className="width-100">
                      <thead>
                        <tr>
                          <th style={{ fontSize: ".85vw", color: Theme.TEXT2 }}>
                            TEAM
                          </th>{" "}
                          <th
                            style={{
                              fontSize: ".85vw",
                              // right: "3.5vw",
                              position: "relative",
                              color: Theme.TEXT2
                            }}
                          >
                            TOTAL TRAINERS
                          </th>{" "}
                          <th style={{ fontSize: ".85vw", color: Theme.TEXT2 }}>
                            TOTAL {user?.profile.client_label.toUpperCase()}S
                          </th>{" "}
                        </tr>
                      </thead>
                      <tbody>
                        {groups
                          .sort((a, b) => b.id - a.id)
                          .map((d, index) => {
                            return (
                              <tr key={index}>
                                <td
                                  onClick={() => handleClick(d.id)}
                                  style={{
                                    fontSize: ".75vw",
                                    cursor: "pointer"
                                  }}
                                >
                                  <Row style={{ fontSize: "1vw" }}>
                                    <p
                                      style={{
                                        position: "relative",
                                        // left: "1vw",
                                        fontSize: ".85vw",
                                        color: Theme.TEXT
                                      }}
                                    >
                                      {" "}
                                      {d.name} {}
                                    </p>
                                  </Row>
                                </td>

                                <td
                                  onClick={() => handleClick(d.id)}
                                  style={{
                                    fontSize: ".85vw",
                                    color: Theme.TEXT,
                                    left: "2.7vw",
                                    position: "relative"
                                  }}
                                >
                                  <span
                                    className="text-xs font-weight-bold"
                                    style={{ color: Theme.TEXT }}
                                  >
                                    {d.trainers.length + d.group_admins.length}{" "}
                                  </span>
                                </td>

                                <td
                                  style={{
                                    fontSize: ".85vw",
                                    position: "relative",
                                    left: "2.5vw"
                                  }}
                                >
                                  <span
                                    className="text-xs font-weight-bold"
                                    style={{ color: Theme.TEXT }}
                                  >
                                    {d.clients.length}{" "}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    cursor: "pointer",
                                    right: "2vw",
                                    position: "relative"
                                  }}
                                  onClick={(e) => {
                                    // Prevent the click event from bubbling up to the parent <tr>
                                    e.stopPropagation();
                                    handleEditGroupClick(d);
                                  }}
                                >
                                  {user?.profile.account_type == 2 && (
                                    <IoEllipsisHorizontalSharp
                                      style={{
                                        cursor: "pointer",
                                        color: Theme.TEXT
                                      }}
                                    ></IoEllipsisHorizontalSharp>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                  <Modal
                    open={editGroupModalVisible}
                    onCancel={() => {
                      setEditGroupModalVisible(false);
                    }}
                    footer={null}
                    width={"25vw"}
                    bodyStyle={{ height: "15vw", padding: ".5vw" }}
                    className={
                      Theme.BACKGROUND === "#000"
                        ? "customAntdModalDark"
                        : "customAntdModalLight"
                    }
                  >
                    <GroupProfile
                      group={groupId}
                      closeModal={() => setEditGroupModalVisible(false)}
                    ></GroupProfile>
                  </Modal>
                </Card>
              </Tabs.TabPane>
            )}
          </Tabs>
        </Col>
      </Row>
      <Modal
        open={trainerModalVisible}
        onCancel={() => setTrainerModalVisible(false)}
        footer={null}
        width={"30vw"}
        className={
          Theme.BACKGROUND === "#000"
            ? "customAntdModalDark"
            : "customAntdModalLight"
        }
      >
        <Row style={{ padding: "5%" }}>
          <Col span={24}>
            <CreateTrainerForm closeModal={setTrainerModalVisible} />
          </Col>
        </Row>
      </Modal>
      <Modal
        open={addGroupModalVisible}
        onCancel={() => setAddGroupModalVisible(false)}
        footer={null}
        width={"30vw"}
        className={
          Theme.BACKGROUND === "#000"
            ? "customAntdModalDark"
            : "customAntdModalLight"
        }
      >
        <Row style={{ padding: "5%" }}>
          <Col span={24}>
            <CreateGroupForm
              closeModal={() => setAddGroupModalVisible(false)}
            />
          </Col>
        </Row>
      </Modal>
    </Col>
  );
}

export default Admin;

const styles = StyleSheet.create({
  icon: {
    "@media (max-width: 1200px)": {
      fontSize: "15%",
      height: 15,
      position: "relative",
      bottom: 25
    }
  }
});
