// Import necessary components and libraries
import React, { useEffect, useState } from "react";
import { Form, Input, Button, Row, Typography, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  createBlockTemplate,
  getBlockTemplates,
  getSingleProgramTemplate,
  resetBlockTemplateStatus,
  updateBlockTemplate
} from "../../redux/actions/workouts";

const { Text } = Typography;

const CreateBlockTemplateForm = ({
  blockData,
  editModalOpen,
  closeModal,
  prog
}) => {
  const dispatch = useDispatch();
  const { Theme } = useSelector((state) => state.auth);
  useSelector((state) => state.clients);
  const { blockTemplateStatus, programTemplate, blockTemplate } = useSelector(
    (state) => state.workouts
  );

  const [form] = Form.useForm();
  const [error, setError] = useState(false);

  const [showStartAfterPrevious, setShowStartAfterPrevious] = useState(false);

  const handleValuesChange = (changedValues, allValues) => {
    if (changedValues.order !== undefined) {
      setShowStartAfterPrevious(changedValues.order > 1);
    }
  };

  const onFinish = async (values) => {
    try {
      setError(false);
      const { name, description, numOfWeeks, order, start_after_previous } =
        values;

      const mesdata = {
        name,
        description,
        numOfWeeks: parseInt(numOfWeeks),
        macro_template: prog
          ? prog
          : blockTemplate?.macro_template
          ? blockTemplate?.macro_template
          : null,
        order: order,
        start_after_previous: start_after_previous
      };

      if (blockData) {
        dispatch(updateBlockTemplate(blockData.id, mesdata));
      } else {
        dispatch(createBlockTemplate(mesdata));
      }
    } catch (error) {
      setError(true);
    }
  };

  useEffect(() => {
    if (blockData) {
      form.setFieldsValue({
        name: blockData.name,
        description: blockData.description,
        numOfWeeks: blockData.numOfWeeks,
        order: blockData.order,
        start_after_previous: blockData.start_after_previous
      });
    }
  }, [blockData]);

  useEffect(() => {
    if (
      blockTemplateStatus === "success" ||
      blockTemplateStatus === "updated"
    ) {
      form.resetFields();
      dispatch(resetBlockTemplateStatus());
      dispatch(getBlockTemplates());
      if (prog) {
        dispatch(getSingleProgramTemplate(programTemplate?.id));
      }
      if (blockTemplate?.macro_template) {
        dispatch(getSingleProgramTemplate(blockTemplate?.macro_template));
      }
    }
    if (blockTemplateStatus === "success") {
      closeModal();
      editModalOpen(true);
    }
  }, [blockTemplateStatus]);

  return (
    <Form
      name="createMesocycle"
      onFinish={onFinish}
      initialValues={{ remember: true }}
      layout="vertical"
      align="middle"
      justify="center"
      form={form}
      style={{ background: Theme.BACKGROUND === "#000" ? Theme.CARD : null }}
      onValuesChange={handleValuesChange}
    >
      <Col align="middle" justify="center">
        <Form.Item
          className="custom-form-item"
          //   label="Name"
          name="name"
          style={{ fontSize: "0.75vw", marginTop: ".5vw" }}
          rules={[{ required: true, message: "Please enter the block name" }]}
        >
          <Input
            placeholder="Name"
            className={
              Theme.BACKGROUND === "#000"
                ? "custom-placeholder-color-dark"
                : "custom-placeholder-color-light"
            }
            style={{
              width: "15vw",
              color: Theme.TEXT,
              background: Theme.BACKGROUND,
              borderWidth: Theme.BACKGROUND === "#000" ? 0 : 1,
              fontSize: ".75vw"
            }}
          />
        </Form.Item>
      </Col>
      <Row align="middle" justify="center">
        <Form.Item
          className="custom-form-item"
          name="numOfWeeks"
          rules={[
            { required: true, message: "Please enter the number of weeks" }
          ]}
        >
          <Input
            className={
              Theme.BACKGROUND === "#000"
                ? "custom-placeholder-color-dark"
                : "custom-placeholder-color-light"
            }
            placeholder="Number of Weeks"
            type="number"
            min="1"
            step="1"
            style={{
              color: Theme.TEXT,
              fontSize: ".75vw",
              height: "2vw",
              width: "15vw",
              background: Theme.BACKGROUND,
              borderWidth: Theme.BACKGROUND === "#000" ? 0 : 1
            }}
          />
        </Form.Item>
      </Row>
      {(prog || blockTemplate?.macro_template) && (
        <Form.Item
          className="custom-form-item"
          name="order"
          rules={[{ required: true, message: "Please enter the order #" }]}
        >
          <Input
            className={
              Theme.BACKGROUND === "#000"
                ? "custom-placeholder-color-dark"
                : "custom-placeholder-color-light"
            }
            placeholder="Order of Block in Program"
            type="number"
            min="1"
            step="1"
            style={{
              color: Theme.TEXT,
              fontSize: ".75vw",
              height: "2vw",
              width: "15vw",
              background: Theme.BACKGROUND,
              borderWidth: Theme.BACKGROUND === "#000" ? 0 : 1
            }}
          />
        </Form.Item>
      )}

      {showStartAfterPrevious && (
        <Form.Item
          className="custom-form-item"
          name="start_after_previous"
          rules={[
            { required: true, message: "Please enter the number of days" }
          ]}
        >
          <Input
            className={
              Theme.BACKGROUND === "#000"
                ? "custom-placeholder-color-dark"
                : "custom-placeholder-color-light"
            }
            placeholder="Number of Days after Previous Block to Start"
            type="number"
            min="1"
            step="1"
            style={{
              color: Theme.TEXT,
              fontSize: ".75vw",
              height: "2vw",
              width: "19vw",
              background: Theme.BACKGROUND,
              borderWidth: Theme.BACKGROUND === "#000" ? 0 : 1
            }}
          />
        </Form.Item>
      )}
      <Col align="middle" justify="center">
        <Form.Item className="custom-form-item" name="description">
          <Input.TextArea
            className={
              Theme.BACKGROUND === "#000"
                ? "custom-placeholder-color-dark"
                : "custom-placeholder-color-light"
            }
            placeholder="Description"
            autosize={{ minRows: 1, maxRows: 6 }}
            style={{
              width: "19vw",
              resize: "none",
              minHeight: "5vw",
              color: Theme.TEXT,
              fontSize: ".75vw",
              background: Theme.BACKGROUND,
              borderWidth: Theme.BACKGROUND === "#000" ? 0 : 1
            }}
          />
        </Form.Item>
      </Col>

      <Row style={{ marginTop: "1vw" }}>
        <div>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              color={"PRIMARY"}
              style={{
                zIndex: 999,
                backgroundImage:
                  "linear-gradient(60deg, #C04848 25%, #480048 120%)",
                borderRadius: 10,
                borderWidth: 0,

                textAlign: "center"
              }}
            >
              {blockData ? (
                <Text
                  style={{
                    color: "white",
                    fontSize: ".75vw",
                    textAlign: "center"
                  }}
                >
                  Update Template
                </Text>
              ) : (
                <Text style={{ color: "white", fontSize: ".75vw" }}>
                  Create Template
                </Text>
              )}
            </Button>
          </Form.Item>
        </div>
      </Row>

      {error === true && (
        <Text style={{ fontsize: ".65vw", color: "red" }}>
          Error - Please Check the Fields and Try Again
        </Text>
      )}
    </Form>
  );
};

export default CreateBlockTemplateForm;
