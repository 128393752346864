import React from "react";
import styled, { css } from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import moment from "moment";

const commonStyles = css`
  height: 2vw;
  text-align: left !important;
  border-radius: 10px;
  font-size: 0.65vw;
  font-family: Nunito;
  border: none;
`;

const darkModeStyles = css`
  color: #fff;
  background: #000;
  border: none;
  .react-datepicker-wrapper {
    .react-datepicker__input-container input {
      color: #fff;
      background-color: ${({ darkModeBackground }) =>
        darkModeBackground || "#000"};
    }
    .react-datepicker__current-month {
      color: #f1f1fb;
    }
    .react-datepicker__day-names {
      .react-datepicker__day-name {
        color: #000;
      }
    }
  }
  &::placeholder {
    color: #fff; /* Sets placeholder text color to white */
  }
  .react-datepicker__day--highlighted,
  .react-datepicker__month-text--highlighted,
  .react-datepicker__quarter-text--highlighted,
  .react-datepicker {
    background-color: ${({ darkModeBackground }) =>
      darkModeBackground || "#000"};
    color: #f1f1fb;
  }
`;

const lightModeStyles = css`
  color: #000;
  background: #f1f1fb;

  .react-datepicker-wrapper {
    .react-datepicker__input-container input {
      color: #000;
      background-color: ${({ lightModeBackground }) =>
        lightModeBackground || "#f1f1fb"};
    }
    .react-datepicker__current-month {
      color: #000;
    }
    .react-datepicker__day-names {
      .react-datepicker__day-name {
        color: #000;
      }
    }
  }
  .react-datepicker__day--highlight {
    background-color: #c04848 !important;
  }

  &::placeholder {
    color: #000; /* Sets placeholder text color to white */
  }

  .react-datepicker {
    background-color: ${({ lightModeBackground }) =>
      lightModeBackground || "#f1f1fb"};
    color: #000;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  ${commonStyles}

  &.customDarkMode {
    ${darkModeStyles}
  }

  &.customLightMode {
    ${lightModeStyles}
  }
`;

const StyledDatePickerComponent = ({
  selected,
  onChange,
  disabled,
  theme,
  lightModeBackground,
  darkModeBackground,
  placeholder,
  workouts,
  width
}) => {
  const { Theme } = useSelector((state) => state.auth); // Assuming Theme.BACKGROUND is accessible from state.auth
  const conditionalClassName =
    Theme.BACKGROUND === "#000" ? "customDarkMode" : "customLightMode";

  // Extracting and parsing dates from workouts using Moment.js
  const workoutDates = workouts
    ? workouts.map((workout) => moment(workout.date, "YYYY-MM-DD"))
    : [];

  // Function to mark completed workouts
  const highlightCompletedWorkouts = (date) => {
    const workout = workouts
      ? workouts.find((workout) =>
          moment(workout.date, "YYYY-MM-DD").isSame(date, "day")
        )
      : null;
    return workout && workout.completed ? "highlight-completed" : null;
  };

  return (
    <StyledDatePicker
      selected={selected ? moment(selected).toDate() : null}
      onChange={onChange}
      dateFormat="MM/dd/yyyy"
      placeholderText={placeholder}
      disabled={disabled}
      className={conditionalClassName}
      excludeDates={workoutDates.map((date) => moment(date).toDate())}
      highlightDates={workoutDates.map((date) => moment(date).toDate())}
      customDayClassName={highlightCompletedWorkouts}
      style={{ width: width }}
    />
  );
};

export default StyledDatePickerComponent;
