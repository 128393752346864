import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Row,
  Typography,
  Modal,
  List,
  Space,
  Pagination
} from "antd";
import { Button } from "react-bootstrap";
import { StyleSheet, css } from "aphrodite";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import customblank from "../../assets/images/customblank.jpg";
import CreateWorkoutForm from "../workouts/CreateWorkoutForm";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { InfoCircleOutlined, UploadOutlined } from "@ant-design/icons";
const { Text } = Typography;

function Recommendations({ exercises }) {
  const { Theme } = useSelector((state) => state.auth);
  const styles = createStyles(Theme);
  const [modalVisible, setModalVisible] = useState(false);
  const [compModalVisible, setCompModalVisible] = useState(false);
  const [selectedExercise, setSelectedExercise] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [createExerModalOpen, setCreateExerModalOpen] = useState(false);
  const [viewCalendarModal, setViewCalendarModal] = useState(false);
  const history = useHistory();

  const pageSize = 4;
  const indexOfLastExercise = currentPage * pageSize;
  const indexOfFirstExercise = indexOfLastExercise - pageSize;
  const currentExercises = exercises.slice(
    indexOfFirstExercise,
    indexOfLastExercise
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleCloseModal = () => {
    setCreateExerModalOpen(false);
  };

  const handleRedirectModal = () => {
    setCreateExerModalOpen(false);
    setViewCalendarModal(true);
  };

  const handleInfoClick = (exercise) => {
    setSelectedExercise(exercise);
    setCompModalVisible(true);
  };

  const itemRender = (current, type, originalElement) => {
    if (type === "page") {
      const color = Theme.PRIMARY;
      return <span style={{ color }}>{current}</span>;
    }
    return originalElement;
  };

  const extractClientId = () => {
    const pathArray = window.location.pathname.split("/");
    const resultsIndex = pathArray.indexOf("results");
    return resultsIndex !== -1 ? pathArray[resultsIndex + 1] : null;
  };

  const clientID = extractClientId();

  const preparedExercises = exercises.map((exercise) => ({
    name: exercise.name,
    video_file: exercise.video_file,
    video_link: exercise.video_link,
    image: exercise.image,
    description: exercise.description,
    sets: [
      {
        weight: { type: "% Difficulty", value: "" },
        params: [
          { type: "Reps", value: "" },
          { type: "Rest", value: "" }
        ]
      }
    ]
  }));

  return (
    <Col
      className="aoc"
      style={{
        flexDirection: "column",
        alignItems: "center",
        top: "0vw",
        minHeight: "9vw"
      }}
      justify="center"
      align="center"
    >
      <Row type="flex" style={{ alignItems: "center" }} justify="center">
        <Col
          align="center"
          span={30}
          style={{
            fontWeight: "bold",
            // marginTop: "5%",
            // left: "7.5%",

            width: "100%",
            position: "relative"
          }}
        >
          <Row
            justify="center"
            align="middle"
            style={{ width: "100%", marginBottom: "1.2vw" }}
          >
            {/* Text always centered */}
            <Col>
              <h1
                style={{
                  fontSize: "1.0vw",
                  color: Theme.TEXT,
                  textAlign: "center"
                }}
              >
                Exercise Recommendations
              </h1>
            </Col>

            {/* Button aligned to the right */}
            {exercises.length > 0 && (
              <Col style={{ position: "absolute", right: "3vw" }}>
                <Button
                  type="secondary"
                  style={styles.button2}
                  onClick={() => setCreateExerModalOpen(true)}
                >
                  Create Workout
                </Button>
              </Col>
            )}
          </Row>

          <Row gutter={[16, 16]} justify="center" style={{ minHeight: "10vw" }}>
            {exercises.length > 0 ? (
              currentExercises.map((exercise, index, arr) => (
                <Col
                  key={`${exercise.name}_${index}`}
                  span={arr.length === 1 ? 8 : 10} // Adjust span based on item count
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: "0.2vw",
                    background: Theme.BACKGROUND,
                    marginRight: "1vw",
                    marginLeft: "1vw",
                    borderRadius: 10,
                    height: "4vw",
                    cursor: "pointer",
                    boxShadow: "0px 6px 6px rgba(0, 0, 0, 0.175)",
                    minWidth: "15vw"
                  }}
                  onClick={() => handleInfoClick(exercise)}
                >
                  <img
                    style={{
                      height: "4vw",
                      right: "5%",
                      position: "relative",
                      maxWidth: "50%",
                      objectFit: "cover",
                      borderTopLeftRadius: 10,
                      borderBottomLeftRadius: 10
                    }}
                    alt={exercise.name}
                    src={exercise.image ? exercise.image : customblank}
                  />

                  <Text
                    style={{
                      color: Theme.TEXT,
                      fontSize: ".55vw"
                    }}
                  >
                    {exercise.name}
                  </Text>
                </Col>
              ))
            ) : (
              <Col
                style={{
                  position: "relative",
                  top: "2.5vw",
                  paddingBottom: "1vw"
                }}
              >
                <div style={{ position: "relative" }}>
                  <IoCheckmarkCircleOutline
                    size={"1vw"}
                    color={Theme.PRIMARY}
                  />
                </div>
                <Text
                  style={{
                    fontSize: ".75vw",
                    position: "relative",
                    color: Theme.TEXT,
                    fontWeight: "bold"
                  }}
                >
                  No Recommendations
                </Text>
              </Col>
            )}
          </Row>
          <div
            style={{
              width: "100%",
              textAlign: "center",
              top: "1vw",
              position: "relative"
            }}
          >
            {exercises.length > 0 && (
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                onChange={(page) => setCurrentPage(page)}
                total={exercises.length}
                hideOnSinglePage={true}
                itemRender={itemRender}
              />
            )}
          </div>
        </Col>
      </Row>

      <Modal
        open={compModalVisible}
        onCancel={() => setCompModalVisible(false)}
        width={"65vw"}
        bodyStyle={{
          paddingBottom:
            selectedExercise &&
            selectedExercise.video_file &&
            selectedExercise.exercise_type == "custom"
              ? "20vw"
              : "10vw",
          paddingTop: "1vw",
          paddingLeft: "1vw",
          paddingRight: "1vw",
          minHeight: "25vw"
        }}
        className={
          Theme.BACKGROUND === "#000"
            ? "customAntdModalDark"
            : "customAntdModalLight"
        }
        footer={[<div></div>]}
      >
        {selectedExercise && (
          <Col align="middle">
            <Text
              style={{ color: Theme.TEXT, fontSize: "1vw", fontWeight: "bold" }}
            >
              {selectedExercise.name}
            </Text>
            <Row
              gutter={16}
              justify="center"
              style={{ position: "relative", marginTop: "4vw" }}
            >
              <Col span={12}>
                {selectedExercise.video_file ? (
                  <video
                    style={{
                      width: "85%",
                      height: "auto",
                      maxHeight: "20vw",
                      objectFit: "contain",
                      borderRadius: 20
                    }}
                    controls
                    src={selectedExercise.video_file}
                  />
                ) : selectedExercise.video_link ? (
                  <iframe
                    title="Exercise Video"
                    width="100%"
                    height="auto"
                    style={{
                      width: "85%",
                      height: "auto",
                      maxHeight: "20vw"
                    }}
                    src={selectedExercise.video_link}
                    frameBorder="0"
                    allowFullScreen
                  />
                ) : selectedExercise.image ? (
                  <img
                    style={{
                      width: "85%",
                      height: "auto",
                      maxHeight: "20vw",
                      borderRadius: 20
                    }}
                    alt={selectedExercise.name}
                    src={selectedExercise.image}
                  />
                ) : (
                  <img
                    style={{
                      width: "85%", // Use 100% width
                      height: "auto", // Maintain aspect ratio
                      objectFit: "contain", // Maintain aspect ratio and fill container
                      borderRadius: 20
                    }}
                    alt={selectedExercise.name}
                    src={customblank}
                  />
                )}
              </Col>
              <Col span={12} style={{ textAlign: "left" }}>
                <div>
                  <Text
                    style={{
                      fontWeight: "bold",
                      fontSize: ".75vw",
                      marginBottom: ".5vw",
                      marginTop: ".5vw",
                      color: Theme.TEXT
                    }}
                  >
                    Instructions
                  </Text>
                </div>
                {selectedExercise.instructions &&
                selectedExercise.instructions !== "undefined" ? (
                  <Text style={{ fontSize: ".85vw", textAlign: "left" }}>
                    {selectedExercise.instructions
                      .split("\n")
                      .map((instruction, index) => (
                        <Text
                          style={{ textAlign: "left", color: Theme.TEXT2 }}
                          key={index}
                        >
                          {instruction}
                          <br />
                        </Text>
                      ))}
                  </Text>
                ) : (
                  <Text style={{ fontSize: ".85vw", color: Theme.TEXT2 }}>
                    No Instructions Provided
                  </Text>
                )}
              </Col>
            </Row>
          </Col>
        )}
      </Modal>

      <Modal
        open={createExerModalOpen}
        onCancel={handleCloseModal}
        cancelButtonProps={{ style: { display: "none" } }}
        closeIcon={<span></span>}
        footer={[<Col align={"center"} justify={"center"}></Col>]}
        width={"90vw"}
        style={{ top: "2vw" }}
        className={
          Theme.BACKGROUND === "#000"
            ? "customAntdModalDark"
            : "customAntdModalLight"
        }
      >
        <CreateWorkoutForm
          initExercises={preparedExercises}
          closeModal={handleCloseModal}
          closeModalSuccess={handleRedirectModal}
        />
      </Modal>

      <Modal
        open={viewCalendarModal}
        onCancel={() => setViewCalendarModal(false)}
        footer={null}
        bodyStyle={{ padding: 0 }} // Remove padding from Modal body
        className={
          Theme.BACKGROUND === "#000"
            ? "customAntdModalDark"
            : "customAntdModalLight"
        }
      >
        <Card
          bodyStyle={{ padding: "10px" }}
          style={{
            background: Theme.CARD,
            borderWidth: 0,
            zIndex: 100,
            flex: 1,
            position: "relative",
            width: "20vw",
            borderRadius: 10
          }}
        >
          <h1
            style={{
              fontSize: "1vw",
              color: Theme.TEXT,
              // fontFamily: "ArialBold",
              // bottom: 10,
              marginBottom: "1vw"
            }}
          >
            Workout Created Successfully!
          </h1>
          <Space>
            {/*<Button style={styles.button2} onClick={() => setViewCalendarModal(false)}>Close</Button>*/}
            <Button
              style={styles.button2}
              type="primary"
              onClick={() => {
                history.push(`/client/${clientID}/`, { fromWorkout: true });
                setViewCalendarModal(false);
              }}
            >
              View Calendar
            </Button>
          </Space>
        </Card>
      </Modal>
    </Col>
  );
}

const createStyles = (theme) => ({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    width: "100%"
  },
  list: {
    alignSelf: "auto",
    padding: 10
  },
  button: {
    backgroundColor: theme.BACKGROUND,
    borderRadius: 10,
    border: 0,
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.175)",
    width: "12vw",
    height: "2.5vw",
    fontSize: ".75vw",
    cursor: "pointer",
    fontWeight: "600",
    marginTop: 5,
    color: theme.TEXT
    // fontFamily: "sanserif",
  },
  button2: {
    backgroundColor: theme.BODY,
    borderRadius: 10,
    border: 0,
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.175)",
    width: "5.5svw",
    height: "2vw",
    fontSize: ".55vw",
    cursor: "pointer",
    fontWeight: "600",
    color: theme.WHITE
    // fontFamily: "sanserif",
  },
  title: {
    fontSize: 20,
    color: theme.BLACK,
    fontWeight: "600",
    fontFamily: "ArialBold"
  },
  accuracyText: {
    marginTop: 10,
    fontWeight: "400",
    color: theme.WHITE,
    fontSize: 15
  },
  howtoText: {
    fontWeight: "400",
    marginTop: 10,
    color: theme.WHITE
  },
  sectionText: {
    marginTop: 12,
    left: "7%",
    fontSize: ".85vw",
    fontWeight: "600",
    alignSelf: "center",
    position: "relative",
    color: theme.BLACK
  },
  video: {
    alignSelf: "center",
    width: 250,
    height: 250,
    alignSelf: "center",
    marginVertical: "10%",
    marginHorizontal: "2%"
  },

  modalText: {
    color: theme.BLACK,
    maxWidth: "85%",
    marginBottom: 20,
    textAlign: "center",
    fontSize: ".85vw",
    marginTop: 5
  }
});

export default Recommendations;
