import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Card, Col, Row, Modal, Typography, Button, Spin } from "antd";
import { ExclamationCircleOutlined, FileAddOutlined } from "@ant-design/icons";
import { IoAddCircleOutline } from "react-icons/io5";
import { StyleSheet } from "aphrodite";

import { load_user } from "../../redux/actions/auth";
import BootstrapSearch from "../common/Search";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  deleteWorkoutTemplate,
  getSingleWorkoutTemplate,
  getWorkoutTemplates,
  resetWorkoutTemplateStatus
} from "../../redux/actions/workouts";
import CreateWorkoutTemplateForm from "./CreateWorkoutTemplate";
import AssignWorkout from "./AssignWorkout";
import EditWorkoutTemplateForm from "./EditWorkoutTemplate";
import { Switch } from "react-router-dom/cjs/react-router-dom.min";
const { Text } = Typography;

function WorkoutTemplates() {
  const dispatch = useDispatch();
  const [workoutTemplateCreateModalVisible, setWorkoutTemplateCreateModal] =
    useState(false);
  const [workoutTemplateEditModalVisible, setWorkoutTemplateEditModal] =
    useState(false);
  const [assignModalVisible, setAssignModalVisible] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [assignWorkoutId, setAssignWorkoutId] = useState(null);
  const { workoutTemplates, workoutTemplateStatus } = useSelector(
    (state) => state.workouts
  );
  const { Theme } = useSelector((state) => state.auth);
  const fetchUser = () => dispatch(load_user());
  useEffect(() => {
    AOS.init();
    fetchUser();
    dispatch(getWorkoutTemplates());
    setLoading(false);
  }, []);

  const deleteAlert = (id) => {
    Modal.confirm({
      title: "Are you sure you want to delete this Template?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "Cancel",
      onOk: () => dispatch(deleteWorkoutTemplate(id))
    });
  };

  useEffect(() => {
    if (workoutTemplateStatus === "deleted") {
      dispatch(getWorkoutTemplates());
      dispatch(resetWorkoutTemplateStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workoutTemplateStatus]);

  const handleCloseModal = () => {
    setWorkoutTemplateCreateModal(false);
    setWorkoutTemplateEditModal(false);
  };

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value.toLowerCase());
  };

  const filteredTemplates = workoutTemplates
    ? workoutTemplates.filter(
        (template) =>
          template?.title?.toLowerCase().includes(searchInput) &&
          template?.meso_template === null
      )
    : workoutTemplates.filter((template) => template?.meso_template === null);

  if (isLoading) {
    return (
      <Spin
        size="large"
        style={{ position: "absolute", top: "50%", left: "50%" }}
      />
    );
  }

  return (
    <Col style={{ position: "relative" }}>
      <Card
        bordered={true}
        style={{
          width: "60vw",
          background: Theme.CARD,
          borderWidth: 0
        }}
        className="criclebox cardbody"
      >
        <Row className="project-ant" style={{ marginBottom: "1.5vw" }}>
          <Row span={12} style={styles.fieldrow}>
            <BootstrapSearch
              placeholder="Search Templates"
              value={searchInput}
              width="15vw"
              onChange={handleSearchChange}
            />
          </Row>
          <Col style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={() => setWorkoutTemplateCreateModal(true)}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                borderWidth: 0,
                borderRadius: 12,
                height: "2.2vw",
                width: "9vw",
                fontWeight: "bold",

                cursor: "pointer",
                backgroundImage:
                  "linear-gradient(45deg, #C04848 0%, #480048 80%)"
              }}
            >
              <Row align={"middle"}>
                <IoAddCircleOutline size={"1vw"} style={{}} />
                <div>
                  <Text style={{ fontSize: ".75vw", color: "white" }}>
                    New Template
                  </Text>
                </div>
              </Row>
            </Button>
          </Col>
        </Row>

        {/* Render Profiles */}
        {filteredTemplates.length > 0 ? (
          <div
            style={{ paddingLeft: "4vw" }}
            className="ant-list-box table-responsive"
          >
            <table className="width-100">
              <thead>
                <tr>
                  <th style={{ fontSize: ".85vw", color: Theme.TEXT2 }}>
                    NAME
                  </th>
                  <th
                    style={{
                      fontSize: ".85vw",
                      right: "3.5vw",
                      position: "relative"
                    }}
                  ></th>
                </tr>
              </thead>
              <tbody>
                {filteredTemplates.map((d, index) => (
                  <tr key={index}>
                    <td
                      style={{
                        fontSize: ".75vw",
                        width: "50%",
                        cursor: "pointer"
                      }}
                    >
                      <Row style={{ fontSize: "1vw" }}>
                        <p
                          style={{
                            position: "relative",

                            fontSize: ".85vw",
                            color: Theme.TEXT
                          }}
                        >
                          {d?.title}
                        </p>
                      </Row>
                    </td>
                    <td style={{ fontSize: ".85vw", color: Theme.TEXT }}>
                      <span className="text-xs font-weight-bold"></span>
                    </td>
                    <td
                      style={{
                        fontSize: ".85vw",
                        color: Theme.TEXT,
                        left: "4vw",
                        position: "relative"
                      }}
                    >
                      <Row>
                        <Button
                          onClick={() => {
                            setAssignWorkoutId(d?.id);
                            setAssignModalVisible(true);
                          }}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "white",
                            borderWidth: 0,
                            borderRadius: 12,
                            height: "1.5vw",
                            width: "4vw",
                            fontWeight: "bold",

                            cursor: "pointer",
                            background: "#000"
                          }}
                        >
                          <Text style={{ fontSize: ".75vw", color: "white" }}>
                            Assign
                          </Text>
                        </Button>
                        <Button
                          onClick={() => {
                            setWorkoutTemplateEditModal(true);
                            dispatch(getSingleWorkoutTemplate(d?.id));
                          }}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "white",
                            borderWidth: 0,
                            borderRadius: 12,
                            height: "1.5vw",
                            width: "4vw",
                            fontWeight: "bold",
                            marginRight: "1vw",
                            marginLeft: "1vw",
                            cursor: "pointer",
                            background: "#000"
                          }}
                        >
                          <Text style={{ fontSize: ".75vw", color: "white" }}>
                            Edit
                          </Text>
                        </Button>
                        <Button
                          onClick={() => deleteAlert(d.id)}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "white",
                            borderWidth: 0,
                            borderRadius: 12,
                            height: "1.5vw",
                            width: "4vw",
                            fontWeight: "bold",

                            cursor: "pointer",
                            background: "#000"
                          }}
                        >
                          <Text style={{ fontSize: ".75vw", color: "white" }}>
                            Delete
                          </Text>
                        </Button>
                      </Row>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <Col
            style={{
              textAlign: "center",
              marginTop: "3vw",
              marginBottom: "2vw"
            }}
          >
            <FileAddOutlined
              style={{ fontSize: "3vw", color: Theme.PRIMARY }}
            />
            <Text
              style={{
                display: "block",
                color: Theme.TEXT,
                marginTop: 10,
                fontSize: "1vw"
              }}
            >
              No Workout Templates
            </Text>
          </Col>
        )}
      </Card>

      <Modal
        open={workoutTemplateCreateModalVisible}
        onCancel={() => setWorkoutTemplateCreateModal(false)}
        cancelButtonProps={{ style: { display: "none" } }}
        closeIcon={<span></span>}
        footer={[<Col align={"center"} justify={"center"}></Col>]}
        width={"90vw"}
        style={{ top: "1vw" }}
        className={
          Theme.BACKGROUND === "#000"
            ? "customAntdModalDark"
            : "customAntdModalLight"
        }
      >
        <CreateWorkoutTemplateForm closeModal={() => handleCloseModal()} />
      </Modal>

      <Modal
        open={workoutTemplateEditModalVisible}
        onCancel={() => setWorkoutTemplateEditModal(false)}
        cancelButtonProps={{ style: { display: "none" } }}
        closeIcon={<span></span>}
        footer={[<Col align={"center"} justify={"center"}></Col>]}
        width={"90vw"}
        style={{ top: "1vw" }}
        className={
          Theme.BACKGROUND === "#000"
            ? "customAntdModalDark"
            : "customAntdModalLight"
        }
      >
        <EditWorkoutTemplateForm
          workoutData={[]}
          closeModal={() => handleCloseModal()}
        />
      </Modal>

      <Modal
        open={assignModalVisible}
        onCancel={() => setAssignModalVisible(false)}
        cancelButtonProps={{ style: { display: "none" } }}
        footer={[<Col align={"center"} justify={"center"}></Col>]}
        width={"30vw"}
        style={{ top: "2vw" }}
        className={
          Theme.BACKGROUND === "#000"
            ? "customAntdModalDark"
            : "customAntdModalLight"
        }
      >
        <Col>
          <AssignWorkout
            closeModal={() => setAssignModalVisible(false)}
            assignWorkoutId={assignWorkoutId}
          />
        </Col>
      </Modal>
    </Col>
  );
}

export default WorkoutTemplates;

const styles = StyleSheet.create({
  fieldrow: {
    marginBottom: "1vw"
  }
});
