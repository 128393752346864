import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Col, Modal, Row, Typography } from "antd";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import "react-calendar/dist/Calendar.css";

import { Button } from "react-bootstrap";
import WorkoutCalendar from "./WorkoutCalendar";
import CreateWorkoutForm from "./CreateWorkoutForm";
import { getWorkouts } from "../../redux/actions/workouts";
import WorkoutTemplateModal from "../templates/WorkoutTemplateModal";

const { Text } = Typography;

const WorkoutSchedule = () => {
  const { assessments } = useSelector((state) => state.assessments);
  const dispatch = useDispatch();
  const { Theme } = useSelector((state) => state.auth);
  const { client } = useSelector((state) => state.clients);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [choiceModalVisible, setChoiceModalVisible] = useState(false);
  const [templateModalVisible, setTemplateModalVisible] = useState(false);

  useEffect(() => {
    dispatch(getWorkouts(client.id));
  }, [client]);

  const handleCloseModal = () => {
    setCreateModalVisible(false);
    setTemplateModalVisible(false);
    setChoiceModalVisible(false);
  };

  const [isRowLayout, setIsRowLayout] = useState(window.innerWidth >= 1000);

  // Function to handle window resize event and update layout mode
  const handleWindowResize = () => {
    setIsRowLayout(window.innerWidth >= 996);
  };

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener("resize", handleWindowResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  return (
    <Card
      style={{
        zIndex: 100,
        flex: 1,
        position: "relative",
        width: isRowLayout ? "38vw" : "55vw",
        minHeight: "15vw",
        right: isRowLayout ? ".75vw" : null,
        marginBottom: isRowLayout || assessments.length > 0 ? 10 : 50,
        background: Theme.CARD,
        borderWidth: 0
      }}
      className="criclebox h-full"
    >
      <Button
        color={"PRIMARY"}
        style={{
          // width: "5vw",
          position: "absolute",
          right: "1vw",
          paddingLeft: "1vw",

          paddingRight: "1vw",
          height: "1.5vw",
          cursor: "pointer",
          zIndex: 999,
          top: "1.85vw",
          backgroundImage: "linear-gradient(60deg, #C04848 25%, #480048 120%)",
          borderRadius: 10,
          borderWidth: 0,
          opacity: 0.9
        }}
        onClick={() => setChoiceModalVisible(true)}
      >
        <div style={{ flexDirection: "column" }}>
          <div style={{ flexDirection: "row" }}>
            <Text
              style={{
                color: "white",
                fontSize: ".75vw",
                textAlign: "center",
                opacity: 1,
                fontWeight: "bold"
              }}
            >
              Create Workout
            </Text>
          </div>
        </div>
      </Button>
      <Col
        justify={"center"}
        align={"middle"}
        style={{
          zIndex: 100,
          flex: 1,
          position: "relative",

          marginBottom: isRowLayout || assessments.length > 0 ? 10 : 100
        }}
      >
        <Row justify={"center"} align={"middle"} style={{ marginBottom: "2%" }}>
          <h3
            style={{
              right: isRowLayout ? ".5vw" : "5vw",
              color: Theme.TEXT,
              fontSize: "1.1vw",
              position: "relative"
            }}
          >
            Schedule
          </h3>
        </Row>
        <Modal
          open={choiceModalVisible}
          onCancel={() => setChoiceModalVisible(false)}
          footer={[<Col align={"center"} justify={"center"}></Col>]}
          width={"30vw"}
          style={{ top: "15vh" }}
          className={
            Theme.BACKGROUND === "#000"
              ? "customAntdModalDark"
              : "customAntdModalLight"
          }
        >
          <Col
            align="middle"
            justify="center"
            style={{ marginTop: "2.5vw", paddingBottom: "1vw" }}
          >
            <Text
              style={{
                fontWeight: "bold",
                fontSize: "1dvw",
                bottom: "1vw",
                position: "relative",
                color: Theme.TEXT,
                justify: "center"
              }}
            >
              How would you like to create this workout?
            </Text>
            <Row align="middle" justify="center" style={{ marginTop: "1vw" }}>
              <Button
                color={"PRIMARY"}
                style={{
                  mrginLeft: "1vw",
                  right: "1vw",
                  padding: ".5vw",
                  width: "8vw",
                  cursor: "pointer",
                  zIndex: 999,
                  top: "1.85vw",
                  backgroundImage:
                    "linear-gradient(60deg, #C04848 25%, #480048 120%)",
                  borderRadius: 10,
                  borderWidth: 0,
                  opacity: 0.9
                }}
                onClick={() => setTemplateModalVisible(true)}
              >
                <div style={{ flexDirection: "column" }}>
                  <div style={{ flexDirection: "row" }}>
                    <Text
                      style={{
                        color: "white",
                        fontSize: ".75vw",
                        textAlign: "center",
                        opacity: 1,
                        fontWeight: "bold"
                      }}
                    >
                      Use a Template
                    </Text>
                  </div>
                </div>
              </Button>
              <Button
                color={"PRIMARY"}
                style={{
                  marginLeft: "1vw",
                  padding: ".5vw",
                  width: "8vw",
                  cursor: "pointer",
                  zIndex: 999,
                  top: "1.85vw",
                  backgroundImage:
                    "linear-gradient(60deg, #C04848 25%, #480048 120%)",
                  borderRadius: 10,
                  borderWidth: 0,
                  opacity: 0.9
                }}
                onClick={() => setCreateModalVisible(true)}
              >
                <div style={{ flexDirection: "column" }}>
                  <div style={{ flexDirection: "row" }}>
                    <Text
                      style={{
                        color: "white",
                        fontSize: ".75vw",
                        textAlign: "center",
                        opacity: 1,
                        fontWeight: "bold"
                      }}
                    >
                      Create from Scratch
                    </Text>
                  </div>
                </div>
              </Button>
            </Row>
          </Col>
        </Modal>
        <WorkoutCalendar />
        <Modal
          open={createModalVisible}
          // title="Create Workout"

          onCancel={() => setCreateModalVisible(false)}
          cancelButtonProps={{ style: { display: "none" } }}
          footer={[<Col align={"center"} justify={"center"}></Col>]}
          width={"90vw"}
          style={{ top: "2vw" }}
          className={
            Theme.BACKGROUND === "#000"
              ? "customAntdModalDark"
              : "customAntdModalLight"
          }
        >
          <CreateWorkoutForm closeModal={handleCloseModal} />
        </Modal>
        <Modal
          open={templateModalVisible}
          onCancel={() => setTemplateModalVisible(false)}
          footer={[
            <Col
              align={"center"}
              justify={"center"}
              style={{ padding: "1vw" }}
            ></Col>
          ]}
          width={"75vw"}
          style={{ top: "2vw" }}
          className={
            Theme.BACKGROUND === "#000"
              ? "customAntdModalDark"
              : "customAntdModalLight"
          }
        >
          <WorkoutTemplateModal closeModal={handleCloseModal} />
        </Modal>
        1
      </Col>
    </Card>
  );
};

export default WorkoutSchedule;
