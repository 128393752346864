import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Col, Modal, Row, Typography, Spin } from "antd";

import {
  useHistory,
  useParams
} from "react-router-dom/cjs/react-router-dom.min";
import { Button } from "react-bootstrap";
import { IoCheckmarkDone, IoChevronBackOutline } from "react-icons/io5";
import customblank from "../../assets/images/customblank.jpg";
import { analytics } from "../../firebase-config";
import { logEvent } from "firebase/analytics";
import {
  getPrograms,
  getSingleProgram,
  resetMesocycleStatus,
  resetProgramStatus,
  resetProgramTemplateStatus,
  saveAsBlockTemplate,
  saveAsProgramTemplate
} from "../../redux/actions/workouts";
import { getSingleClient } from "../../redux/actions/clients";
import { PlusCircleOutlined } from "@ant-design/icons";
import { IoPencilSharp } from "react-icons/io5";
import { CiDumbbell } from "react-icons/ci";
import CreateProgramForm from "./ProgramForm";
import meso from "../../assets/images/mesocycle.png";

import macro from "../../assets/images/macrocycle.png";
import CreateBlockForm from "./BlockForm";

const { Text } = Typography;

const Program = () => {
  const dispatch = useDispatch();
  const { Theme } = useSelector((state) => state.auth);
  const { client } = useSelector((state) => state.clients);
  const { clientId, id } = useParams();
  const { program, programStatus, mesocycleStatus, programTemplateStatus } =
    useSelector((state) => state.workouts);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [editWorkoutModalOpen, setEditWorkoutModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [fullDescript, setFullDescript] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedWorkout, setSelectedWorkout] = useState({});
  const [templateLoading, setTemplateLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (programStatus === "updated") {
      setEditModalOpen(false);
      dispatch(getSingleProgram(clientId, id));
      dispatch(resetProgramStatus());
    }
  }, [programStatus]);

  useEffect(() => {
    if (programTemplateStatus === "success") {
      setTimeout(() => {
        setTemplateLoading(false);
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 2500);
      }, 1000);

      dispatch(resetProgramTemplateStatus());
    }
  }, [programTemplateStatus]);

  useEffect(
    () => {
      // Cleanup function to clear selectedWorkout when component unmounts
      return () => {
        setSelectedWorkout({});
      };
    },
    [
      /* add any dependencies for this cleanup effect if needed */
    ]
  );

  useEffect(() => {
    logEvent(analytics, "page_view", { title: "shit piss" });
    dispatch(getPrograms(clientId));
    dispatch(getSingleClient(clientId)).then(() =>
      dispatch(getSingleProgram(clientId, id))
    );
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const history = useHistory();

  const handleGoToClient = () => {
    history.push(`/client/${client.id}`, { fromWorkout: true });
  };

  const formatDate = (dateString) => {
    const splitString = dateString.split("-");
    const formattedString = `${splitString[1]}-${splitString[2]}-${splitString[0]}`;
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    const date = new Date(formattedString);
    return date.toLocaleDateString("en-US", options);
  };

  const handleCloseModal = () => {
    setCreateModalVisible(false);
    setEditWorkoutModalOpen(false);
    setEditModalOpen(false);
    if (
      mesocycleStatus === "success" ||
      programStatus === "success" ||
      programStatus === "updated"
    ) {
      dispatch(getSingleProgram(clientId, id));
      dispatch(resetMesocycleStatus());
      dispatch(resetProgramStatus());
    }
  };

  return (
    <div
      style={{ marginTop: "9vw", position: "relative", right: "2.5%" }}
      className={`fade-in-slide-up-element ${
        true ? "fade-in-slide-up-animation" : ""
      }`}
    >
      <div
        onClick={handleGoToClient}
        style={{
          bottom: "10vw",
          position: "relative",
          cursor: "pointer",
          left: "5%"
        }}
      >
        <IoChevronBackOutline
          style={{
            top: ".1vw",
            marginRight: ".5%",
            position: "relative",
            color: Theme.TEXT
          }}
        />
        <Text style={{ color: Theme.TEXT }}>Back</Text>
      </div>
      <Col
        style={{
          width: "55vw",
          position: "relative",
          bottom: "9vw",
          minHeight: "100vh",
          left: "5%",
          background: Theme.CARD,
          borderWidth: 0,
          bottom: loading ? "22.5vw" : "9vw",
          borderRadius: 10,
          boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.175)",
          paddingBottom: "2vw"
        }}
      >
        {loading ? (
          <Col
            align="middle"
            justify="center"
            style={{ marginTop: "25%", position: "relative", top: "15%" }}
          >
            <Spin
              style={{ marginTop: "25%", position: "relative", top: "15%" }}
              size="large"
            />
          </Col>
        ) : (
          <div>
            {program && program[0] && (
              <div>
                <Col
                  style={{
                    marginBottom: program[0].description ? "1vw" : "2vw",
                    backgroundImage:
                      "linear-gradient(25deg, #353939 -25%, #000000 150%)",

                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                    paddingTop: "1vw",
                    paddingBottom: "1vw"
                  }}
                >
                  <Row
                    align="end"
                    style={{ position: "absolute", right: "2vw", zIndex: 20 }}
                  >
                    <Button
                      color={"PRIMARY"}
                      style={{
                        marginLeft: "4vw",
                        cursor: "pointer",
                        height: "2dvw",
                        width: "3vw",
                        // backgroundImage:
                        //   "linear-gradient(60deg, #C04848 25%, #480048 120%)",
                        borderRadius: 10,
                        borderWidth: 0
                      }}
                      onClick={() => setEditModalOpen(true)}
                    >
                      <div style={{ flexDirection: "column" }}>
                        <div style={{ flexDirection: "row" }}>
                          <Text
                            style={{
                              color: "black",
                              fontSize: ".65vw",
                              textAlign: "center",

                              fontWeight: "bold"
                            }}
                          >
                            <IoPencilSharp size={".55vw"} /> Edit
                          </Text>
                        </div>
                      </div>
                    </Button>
                    <Button
                      color={"PRIMARY"}
                      style={{
                        marginLeft: "2vw",
                        cursor: "pointer",
                        height: "2dvw",
                        width: "6vw",
                        // backgroundImage:
                        //   "linear-gradient(60deg, #C04848 25%, #480048 120%)",
                        borderRadius: 10,
                        borderWidth: 0
                      }}
                      onClick={() => {
                        setTemplateLoading(true);
                        dispatch(saveAsProgramTemplate(program[0].id));
                      }}
                    >
                      <div style={{ flexDirection: "column" }}>
                        <div style={{ flexDirection: "row" }}>
                          {templateLoading ? (
                            <Spin size="small" />
                          ) : !!success ? (
                            <IoCheckmarkDone
                              color={Theme.PRIMARY}
                              style={{ top: 1, position: "relative" }}
                            />
                          ) : (
                            <Text
                              style={{
                                color: Theme.BLACK,
                                fontSize: ".65vw",
                                textAlign: "center",

                                fontWeight: "bold"
                              }}
                            >
                              Save as Template
                            </Text>
                          )}
                        </div>
                      </div>
                    </Button>
                  </Row>
                  <Col
                    align="start"
                    style={{ position: "absolute", left: "2vw", zIndex: 1 }}
                  >
                    <Row align="middle" justify="center">
                      <Col>
                        <Text
                          style={{
                            fontSize: ".75vw",
                            color: Theme.WHITE,
                            fontWeight: "bold"
                          }}
                        >
                          Start Date
                        </Text>
                        <div>
                          <Text
                            style={{
                              fontSize: ".75vw",
                              color: Theme.PRIMARY,
                              fontWeight: "bold"
                            }}
                          >
                            {formatDate(program[0].time_length_start)}
                          </Text>
                        </div>
                      </Col>
                      <Col style={{ marginLeft: "2vw" }}>
                        <Text
                          style={{
                            fontSize: ".75vw",
                            color: Theme.WHITE,
                            fontWeight: "bold"
                          }}
                        >
                          End Date
                        </Text>
                        <div>
                          <Text
                            style={{
                              fontSize: ".75vw",
                              color: Theme.PRIMARY,
                              fontWeight: "bold"
                            }}
                          >
                            {formatDate(program[0].time_length_end)}
                          </Text>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    align="middle"
                    justify="center"
                    style={{
                      marginBottom: program[0].description ? "1vw" : "2vw",
                      zIndex: 0
                    }}
                  >
                    <img
                      style={{
                        height: "4vw",
                        width: "5.5vw",
                        borderRadius: 10,
                        objectFit: "contain",
                        marginTop: ".75vw"
                      }}
                      src={macro}
                    />
                    <Col style={{ width: "70%", marginTop: ".75vw" }}>
                      <Text
                        style={{
                          fontSize: "1.2vw",
                          color: Theme.WHITE,
                          fontWeight: "bold"
                        }}
                      >
                        {program[0].name}
                      </Text>
                      {program[0].description && (
                        <div style={{ width: "90%" }}>
                          {program[0].description.length < 230 ? (
                            <Text
                              style={{ fontSize: ".55vw", color: Theme.WHITE }}
                            >
                              {program[0].description}
                            </Text>
                          ) : fullDescript ? (
                            <Text
                              style={{ fontSize: ".55vw", color: Theme.WHITE }}
                            >
                              {program[0].description}{" "}
                              <span
                                onClick={() => setFullDescript(false)}
                                style={{
                                  fontSize: ".55vw",
                                  color: Theme.PRIMARY,
                                  cursor: "pointer"
                                }}
                              >
                                Show Less
                              </span>
                            </Text>
                          ) : (
                            <Row>
                              <Text
                                style={{
                                  fontSize: ".55vw",
                                  color: Theme.WHITE
                                }}
                              >
                                {program[0].description.slice(0, 230)}...{" "}
                                <span
                                  onClick={() => setFullDescript(true)}
                                  style={{
                                    fontSize: ".55vw",
                                    color: Theme.PRIMARY,
                                    cursor: "pointer"
                                  }}
                                >
                                  Show More
                                </span>
                              </Text>{" "}
                            </Row>
                          )}
                        </div>
                      )}
                    </Col>
                  </Col>
                </Col>

                <Row
                  align={"middle"}
                  justify={"center"}
                  style={{ marginLeft: "0vw", width: "100%" }}
                >
                  {program[0].mesocycles?.length === 0 && (
                    <Col
                      align="middle"
                      style={{
                        // marginLeft: "5vw",
                        position: "relative",
                        // right: "5%",
                        marginTop: "5vw",
                        width: "100%"
                      }}
                    >
                      <CiDumbbell size={"3vw"} style={{ color: Theme.TEXT }} />
                      <div>
                        <Text style={{ fontSize: "1vw", color: Theme.TEXT }}>
                          No Blocks
                        </Text>
                      </div>
                      <div>
                        <Button
                          color={"PRIMARY"}
                          style={{
                            padding: ".5vw",
                            marginTop: "1vw",
                            paddingRight: ".5vw",
                            cursor: "pointer",
                            backgroundImage:
                              "linear-gradient(60deg, #C04848 25%, #480048 120%)",
                            borderRadius: 10,
                            borderWidth: 0
                          }}
                          onClick={() => setCreateModalVisible(true)}
                        >
                          <div style={{ flexDirection: "column" }}>
                            <div style={{ flexDirection: "row" }}>
                              <Text
                                style={{
                                  color: "white",
                                  fontSize: ".65vw",
                                  textAlign: "center",

                                  fontWeight: "bold"
                                }}
                              >
                                <PlusCircleOutlined
                                  style={{
                                    position: "relative",
                                    fontSize: ".7vw",
                                    color: Theme.WHITE,
                                    cursor: "pointer"
                                  }}
                                />{" "}
                                Add Block
                              </Text>
                            </div>
                          </div>
                        </Button>
                      </div>
                    </Col>
                  )}
                </Row>
              </div>
            )}

            <Col
              align={"middle"}
              justify={"center"}
              style={{ marginTop: "1vw" }}
            >
              {program[0].mesocycles?.length > 0 && (
                <Col style={{ marginTop: "1vw" }}>
                  <Col align={"center"} justify={"center"}>
                    <Col align={"end"}>
                      <Button
                        color={"PRIMARY"}
                        style={{
                          padding: ".5vw",
                          marginRight: "1.5vw",
                          marginBottom: "1vw",
                          cursor: "pointer",
                          backgroundImage:
                            "linear-gradient(60deg, #C04848 25%, #480048 120%)",
                          borderRadius: 10,
                          borderWidth: 0
                        }}
                        onClick={() => setCreateModalVisible(true)}
                      >
                        <div style={{ flexDirection: "column" }}>
                          <div style={{ flexDirection: "row" }}>
                            <Text
                              style={{
                                color: "white",
                                fontSize: ".55vw",
                                textAlign: "center",

                                fontWeight: "bold"
                              }}
                            >
                              <PlusCircleOutlined
                                style={{
                                  position: "relative",
                                  fontSize: ".55vw",
                                  color: Theme.WHITE,
                                  cursor: "pointer"
                                }}
                              />{" "}
                              Add Block
                            </Text>
                          </div>
                        </div>
                      </Button>
                    </Col>
                  </Col>
                  {program[0].mesocycles
                    .sort(
                      (a, b) => new Date(a.start_date) - new Date(b.start_date)
                    )
                    .map((block) => {
                      const startDate = new Date(block.start_date);
                      const endDate = new Date(startDate);
                      endDate.setDate(endDate.getDate() + block.numOfWeeks * 7);

                      const timeLengthStart = new Date(
                        program[0].time_length_start
                      );
                      const timeLengthEnd = new Date(
                        program[0].time_length_end
                      );

                      const isOutOfRange =
                        startDate < timeLengthStart || endDate > timeLengthEnd;

                      return (
                        <Card
                          key={block.id}
                          bordered={false}
                          style={{
                            width: "65%",
                            backgroundImage:
                              "linear-gradient(25deg, #353939 -25%, #000000 150%)", // Default background
                            borderWidth: 0,
                            cursor: "pointer",
                            marginBottom: "1vw"
                            // minHeight:"8.55vw"
                          }}
                          onClick={() =>
                            history.push(`/block/${client.id}/${block.id}`, {
                              fromMacro: true
                            })
                          }
                        >
                          {isOutOfRange && (
                            <Col align={"start"}>
                              <Text
                                style={{
                                  color: Theme.RED,
                                  fontWeight: "bold",
                                  fontSize: ".65vw",
                                  position: "absolute",
                                  width: "7vw",
                                  right: "-2.5vw",
                                  top: "-.75vw",
                                  marginRight: "1vw"
                                }}
                              >
                                Outside Date Range
                              </Text>
                            </Col>
                          )}
                          <Row
                            style={{ marginLeft: "1vw" }}
                            align={"middle"}
                            justify={"center"}
                          >
                            <img
                              style={{
                                height: "7vw",
                                width: "7vw",
                                borderRadius: 10,
                                objectFit: "cover"
                              }}
                              src={customblank}
                            />

                            <Col align={"start"} style={{ marginLeft: "2vw" }}>
                              <Col style={{ width: "15vw" }}>
                                <Text
                                  style={{
                                    color: Theme.WHITE,
                                    fontWeight: "bold",
                                    fontSize: "1vw",
                                    width: "2vw"
                                  }}
                                >
                                  {block.name}
                                </Text>
                              </Col>
                              <Row
                                align={"start"}
                                justify={"start"}
                                style={{ marginTop: ".5vw" }}
                              >
                                <Col align={"start"}>
                                  <div>
                                    <Text
                                      style={{
                                        color: Theme.WHITE,
                                        fontWeight: "bold",
                                        fontSize: ".75vw"
                                        // marginLeft: "5vw",
                                      }}
                                    >
                                      Length
                                    </Text>
                                  </div>

                                  <Text
                                    style={{
                                      color: Theme.PRIMARY,
                                      fontWeight: "bold",
                                      fontSize: ".75vw"
                                    }}
                                  >
                                    {block.numOfWeeks} Weeks
                                  </Text>
                                </Col>

                                <Col style={{ marginLeft: "5vw" }}>
                                  <div>
                                    <Text
                                      style={{
                                        color: Theme.WHITE,
                                        fontWeight: "bold",
                                        fontSize: ".75vw"
                                        // marginLeft: "5vw",
                                      }}
                                    >
                                      Start Date
                                    </Text>
                                  </div>
                                  <Text
                                    style={{
                                      color: Theme.PRIMARY,
                                      fontWeight: "bold",
                                      fontSize: ".75vw"
                                    }}
                                  >
                                    {formatDate(block.start_date)}
                                  </Text>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Card>
                      );
                    })}
                </Col>
              )}
            </Col>
          </div>
        )}

        <Modal
          open={editModalOpen}
          onCancel={() => setEditModalOpen(false)}
          cancelButtonProps={{ style: { display: "none" } }}
          footer={[<Col align={"center"} justify={"center"}></Col>]}
          width={"35vw"}
          className={
            Theme.BACKGROUND === "#000"
              ? "customAntdModalDark"
              : "customAntdModalLight"
          }
        >
          <Col align="middle" style={{ marginTop: "1vw" }}>
            <Col>
              {" "}
              <img
                style={{
                  height: "4vw",
                  width: "4vw",
                  objectFit: "cover",
                  marginBottom: "1vw"
                }}
                src={macro}
              />{" "}
              <div>
                {" "}
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: ".95dvw",
                    bottom: "1vw",
                    position: "relative",
                    color: Theme.TEXT,
                    justify: "center",
                    marginTop: "1vw"
                  }}
                >
                  Update Program
                </Text>
              </div>
            </Col>

            <Row
              aling="middle"
              justify={"center"}
              style={{ marginTop: "1vw", paddingBottom: "1.5vw" }}
            >
              <CreateProgramForm
                programData={program}
                modalOpen={editModalOpen}
                closeModal={handleCloseModal}
              />
            </Row>
          </Col>
        </Modal>
        <Modal
          open={createModalVisible}
          onCancel={() => setCreateModalVisible(false)}
          cancelButtonProps={{ style: { display: "none" } }}
          // closeIcon={<span></span>}
          footer={[<Col align={"center"} justify={"center"}></Col>]}
          width={"35vw"}
          style={{ top: "2vw" }}
          className={
            Theme.BACKGROUND === "#000"
              ? "customAntdModalDark"
              : "customAntdModalLight"
          }
        >
          <Col align="middle" style={{ marginTop: "1vw" }}>
            <Col>
              {" "}
              <img
                style={{
                  height: "4vw",
                  width: "4vw",
                  objectFit: "cover"
                }}
                src={meso}
              />{" "}
              <div>
                {" "}
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: ".95dvw",
                    bottom: "1vw",
                    position: "relative",
                    color: Theme.TEXT,
                    justify: "center"
                  }}
                >
                  Create Block
                </Text>
              </div>
            </Col>

            <Row
              aling="middle"
              justify={"center"}
              style={{ marginTop: "1vw", paddingBottom: "1.5vw" }}
            >
              <CreateBlockForm
                mesoModalVisible={createModalVisible}
                prog={program}
                closeModal={handleCloseModal}
              />
            </Row>
          </Col>
        </Modal>
      </Col>
    </div>
  );
};

export default Program;
