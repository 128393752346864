import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Card,
  Col,
  Row,
  Modal,
  Typography,
  Tabs,
  Button,
  Switch,
  Spin,
  message
} from "antd";
import { UserOutlined, FileAddOutlined } from "@ant-design/icons";
import { IoAddCircleOutline } from "react-icons/io5";
import { StyleSheet } from "aphrodite";

import { getProfiles, getSingleProfile } from "../../redux/actions/clients";
import { load_user } from "../../redux/actions/auth";
import { updateOrg, getOrg } from "../../redux/actions/organization";
import BootstrapSearch from "../common/Search";
import CreateRangeProfileTemplate from "./CreateRangeProfileTemplateForm";
import rangePic from "../../assets/images/rangeprofile.png";
import AOS from "aos";
import "aos/dist/aos.css";
const { Title, Text } = Typography;

function RangeProfiles() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [rangeTemplateCreateModalVisible, setRangeTemplateCreateModal] =
    useState(false);
  const [isLoading, setLoading] = useState(true);
  const [searchInput, setSearchInput] = useState("");

  const { profiles } = useSelector((state) => state.clients);
  const { organization } = useSelector((state) => state.organization);
  const { user, Theme } = useSelector((state) => state.auth);
  const fetchUser = () => dispatch(load_user());

  useEffect(() => {
    AOS.init();
    fetchUser();
    dispatch(getProfiles());
    if (user?.profile?.account_type == 2) {
      dispatch(getOrg(user?.profile?.organization));
    }
    setLoading(false);
  }, []);

  const handleClick = (id) => {
    dispatch(getSingleProfile(id));
    history.push(`/templates/ranges/${id}`);
  };

  const handleAllowTemplatesChange = (checked) => {
    dispatch(
      updateOrg(user?.profile?.organization, { allow_templates: checked })
    ).catch(() => message.error("Failed to update organization settings"));
  };

  const handleAllowEditRangesChange = (checked) => {
    dispatch(
      updateOrg(user?.profile?.organization, { allow_edit_ranges: checked })
    ).catch(() => message.error("Failed to update organization settings"));
  };

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value.toLowerCase());
  };

  const filteredProfiles = profiles
    ? profiles.filter((profile) =>
        profile.name?.toLowerCase().includes(searchInput)
      )
    : [];

  if (isLoading) {
    return (
      <Spin
        size="large"
        style={{ position: "absolute", top: "50%", left: "50%" }}
      />
    );
  }

  return (
    <Col style={{ position: "relative" }}>
      <Card
        bordered={true}
        style={{
          width: "60vw",
          background: Theme.CARD,
          borderWidth: 0
        }}
        className="criclebox cardbody"
      >
        {user?.profile?.allow_templates && (
          <Row className="project-ant" style={{ marginBottom: "1.5vw" }}>
            <Col span={12} style={styles.fieldrow}>
              <BootstrapSearch
                placeholder="Search Templates"
                value={searchInput}
                width="15vw"
                onChange={handleSearchChange}
              />
            </Col>
            <Col style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                onClick={() => setRangeTemplateCreateModal(true)}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white",
                  borderWidth: 0,
                  borderRadius: 12,
                  height: "2.2vw",
                  width: "9vw",
                  fontWeight: "bold",

                  cursor: "pointer",
                  backgroundImage:
                    "linear-gradient(45deg, #C04848 0%, #480048 80%)"
                }}
              >
                <Row align={"middle"}>
                  <IoAddCircleOutline size={"1vw"} style={{}} />
                  <div>
                    <Text style={{ fontSize: ".75vw", color: "white" }}>
                      New Template
                    </Text>
                  </div>
                </Row>
              </Button>
            </Col>
          </Row>
        )}

        {user?.profile?.account_type === 2 && (
          <Row
            className="project-ant"
            style={{ marginBottom: "1.5vw", alignItems: "center" }}
          >
            <Col span={12} style={{ display: "flex", alignItems: "center" }}>
              <Switch
                style={{ marginRight: "0.5rem" }}
                checked={organization?.allow_templates}
                onChange={handleAllowTemplatesChange}
              />
              <Text style={{ color: Theme.TEXT }}>
                Allow Trainers to Create Profile Templates
              </Text>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center"
              }}
            >
              <Switch
                style={{ marginRight: "0.5rem" }}
                checked={organization?.allow_edit_ranges}
                onChange={handleAllowEditRangesChange}
              />
              <Text style={{ color: Theme.TEXT }}>
                Allow Trainers to Edit Individual Client Ranges
              </Text>
            </Col>
          </Row>
        )}
        {/* Render Profiles */}
        {filteredProfiles.length > 0 ? (
          <div
            style={{ paddingLeft: "4vw" }}
            className="ant-list-box table-responsive"
          >
            <table className="width-100">
              <thead>
                <tr>
                  <th style={{ fontSize: ".85vw", color: Theme.TEXT2 }}>
                    NAME
                  </th>
                  <th
                    style={{
                      fontSize: ".85vw",
                      right: "3.5vw",
                      position: "relative"
                    }}
                  ></th>
                  <th style={{ fontSize: ".85vw", color: Theme.TEXT2 }}>
                    DATE MODIFIED
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredProfiles.map((d, index) => (
                  <tr key={index} onClick={() => handleClick(d.id, d.trainer)}>
                    <td
                      style={{
                        fontSize: ".75vw",
                        width: "50%",
                        cursor: "pointer"
                      }}
                    >
                      <Row style={{ fontSize: "1vw" }}>
                        <p
                          style={{
                            position: "relative",

                            fontSize: ".85vw",
                            color: Theme.TEXT
                          }}
                        >
                          {d.name}
                        </p>
                      </Row>
                    </td>
                    <td style={{ fontSize: ".85vw", color: Theme.TEXT }}>
                      <span className="text-xs font-weight-bold"></span>
                    </td>
                    <td style={{ fontSize: ".85vw", color: Theme.TEXT }}>
                      {new Date(d.date_modified).toLocaleDateString()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <Col
            style={{
              textAlign: "center",
              marginTop: "3vw",
              marginBottom: "2vw"
            }}
          >
            <FileAddOutlined
              style={{ fontSize: "3vw", color: Theme.PRIMARY }}
            />
            <Text
              style={{
                display: "block",
                color: Theme.TEXT,
                marginTop: 10,
                fontSize: "1vw"
              }}
            >
              No Templates
            </Text>
          </Col>
        )}
      </Card>

      <Modal
        open={rangeTemplateCreateModalVisible}
        onCancel={() => setRangeTemplateCreateModal(false)}
        footer={null}
        width={"30vw"}
        className={
          Theme.BACKGROUND === "#000"
            ? "customAntdModalDark"
            : "customAntdModalLight"
        }
      >
        <Row style={{ padding: "5%" }}>
          <Col span={24}>
            <CreateRangeProfileTemplate
              closeModal={setRangeTemplateCreateModal}
            />
          </Col>
        </Row>
      </Modal>
    </Col>
  );
}

export default RangeProfiles;

const styles = StyleSheet.create({
  fieldrow: {
    marginBottom: "1vw"
  }
});
