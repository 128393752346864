import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Col, Row, Modal, Typography, Button, Spin } from "antd";
import { ExclamationCircleOutlined, FileAddOutlined } from "@ant-design/icons";
import { IoAddCircleOutline } from "react-icons/io5";
import { StyleSheet } from "aphrodite";

import { load_user } from "../../redux/actions/auth";
import BootstrapSearch from "../common/Search";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  deleteBlockTemplate,
  getSingleBlockTemplate,
  getBlockTemplates,
  resetBlockTemplateStatus
} from "../../redux/actions/workouts";
import CreateBlockTemplateForm from "./CreateBlockTemplate";
import AssignBlock from "./AssignBlock";
import EditBlockTemplate from "./EditBlockTemplate";
import meso from "../../assets/images/mesocycle.png";

const { Text } = Typography;

function BlockTemplates() {
  const dispatch = useDispatch();
  const [blockTemplateCreateModalVisible, setBlockTemplateCreateModal] =
    useState(false);
  const [blockTemplateEditModalVisible, setBlockTemplateEditModal] =
    useState(false);
  const [assignModalVisible, setAssignModalVisible] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [assignBlockId, setAssignBlockId] = useState(null);
  const { blockTemplates, blockTemplateStatus, blockTemplate } = useSelector(
    (state) => state.workouts
  );
  const { Theme } = useSelector((state) => state.auth);
  const fetchUser = () => dispatch(load_user());

  useEffect(() => {
    AOS.init();
    fetchUser();
    dispatch(getBlockTemplates());
    setLoading(false);
  }, []);

  const deleteAlert = (id) => {
    Modal.confirm({
      title: "Are you sure you want to delete this Template?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "Cancel",
      onOk: () => dispatch(deleteBlockTemplate(id))
    });
  };

  useEffect(() => {
    if (blockTemplateStatus === "deleted") {
      dispatch(getBlockTemplates());
      dispatch(resetBlockTemplateStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockTemplateStatus]);

  const handleCloseModal = () => {
    setBlockTemplateCreateModal(false);
    setBlockTemplateEditModal(false);
  };

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value.toLowerCase());
  };

  const filteredTemplates = blockTemplates
    ? blockTemplates.filter((template) =>
        template?.name?.toLowerCase().includes(searchInput)
      )
    : blockTemplates;

  if (isLoading) {
    return (
      <Spin
        size="large"
        style={{ position: "absolute", top: "50%", left: "50%" }}
      />
    );
  }

  return (
    <Col style={{ position: "relative" }}>
      <Card
        bordered={true}
        style={{
          width: "60vw",
          background: Theme.CARD,
          borderWidth: 0
        }}
        className="criclebox cardbody"
      >
        <Row className="project-ant" style={{ marginBottom: "1.5vw" }}>
          <Col span={12} style={styles.fieldrow}>
            <BootstrapSearch
              placeholder="Search Templates"
              value={searchInput}
              width="15vw"
              onChange={handleSearchChange}
            />
          </Col>
          <Col style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={() => setBlockTemplateCreateModal(true)}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                borderWidth: 0,
                borderRadius: 12,
                height: "2.2vw",
                width: "9vw",
                fontWeight: "bold",

                cursor: "pointer",
                backgroundImage:
                  "linear-gradient(45deg, #C04848 0%, #480048 80%)"
              }}
            >
              <Row align={"middle"}>
                <IoAddCircleOutline size={"1vw"} style={{}} />
                <div>
                  <Text style={{ fontSize: ".75vw", color: "white" }}>
                    New Template
                  </Text>
                </div>
              </Row>
            </Button>
          </Col>
        </Row>

        {/* Render Profiles */}
        {filteredTemplates.length > 0 ? (
          <div
            style={{ paddingLeft: "4vw" }}
            className="ant-list-box table-responsive"
          >
            <table className="width-100">
              <thead>
                <tr>
                  <th style={{ fontSize: ".85vw", color: Theme.TEXT2 }}>
                    NAME
                  </th>
                  <th
                    style={{
                      fontSize: ".85vw",
                      right: "3.5vw",
                      position: "relative"
                    }}
                  ></th>
                </tr>
              </thead>
              <tbody>
                {filteredTemplates.map((d, index) => (
                  <tr key={index}>
                    <td
                      style={{
                        fontSize: ".75vw",
                        width: "50%",
                        cursor: "pointer"
                      }}
                    >
                      <Row style={{ fontSize: "1vw" }}>
                        <p
                          style={{
                            position: "relative",

                            fontSize: ".85vw",
                            color: Theme.TEXT
                          }}
                        >
                          {d?.name}
                        </p>
                      </Row>
                    </td>
                    <td style={{ fontSize: ".85vw", color: Theme.TEXT }}>
                      <span className="text-xs font-weight-bold"></span>
                    </td>
                    <td
                      style={{
                        fontSize: ".85vw",
                        color: Theme.TEXT,
                        left: "4vw",
                        position: "relative"
                      }}
                    >
                      <Row>
                        <Button
                          onClick={() => {
                            setAssignBlockId(d?.id);
                            setAssignModalVisible(true);
                          }}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "white",
                            borderWidth: 0,
                            borderRadius: 12,
                            height: "1.5vw",
                            width: "4vw",
                            fontWeight: "bold",
                            cursor: "pointer",
                            background: "#000"
                          }}
                        >
                          <Text style={{ fontSize: ".75vw", color: "white" }}>
                            Assign
                          </Text>
                        </Button>
                        <Button
                          onClick={() => {
                            setBlockTemplateEditModal(true);
                            dispatch(getSingleBlockTemplate(d?.id));
                          }}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "white",
                            borderWidth: 0,
                            borderRadius: 12,
                            height: "1.5vw",
                            width: "4vw",
                            fontWeight: "bold",
                            marginRight: "1vw",
                            marginLeft: "1vw",
                            cursor: "pointer",
                            background: "#000"
                          }}
                        >
                          <Text style={{ fontSize: ".75vw", color: "white" }}>
                            Edit
                          </Text>
                        </Button>
                        <Button
                          onClick={() => deleteAlert(d.id)}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "white",
                            borderWidth: 0,
                            borderRadius: 12,
                            height: "1.5vw",
                            width: "4vw",
                            fontWeight: "bold",

                            cursor: "pointer",
                            background: "#000"
                          }}
                        >
                          <Text style={{ fontSize: ".75vw", color: "white" }}>
                            Delete
                          </Text>
                        </Button>
                      </Row>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <Col
            style={{
              textAlign: "center",
              marginTop: "3vw",
              marginBottom: "2vw"
            }}
          >
            <img
              style={{
                height: "3vw",
                width: "3vw",
                objectFit: "cover"
              }}
              src={meso}
              alt="programs"
            />
            <div>
              <Text
                style={{
                  display: "program",
                  color: Theme.TEXT,
                  marginTop: 10,
                  fontSize: "1vw"
                }}
              >
                No Block Templates
              </Text>
            </div>
          </Col>
        )}
      </Card>

      <Modal
        open={blockTemplateCreateModalVisible}
        onCancel={() => setBlockTemplateCreateModal(false)}
        footer={[<Col align={"center"} justify={"center"}></Col>]}
        width={"30vw"}
        style={{ top: "2vw" }}
        className={
          Theme.BACKGROUND === "#000"
            ? "customAntdModalDark"
            : "customAntdModalLight"
        }
      >
        <Col align="middle" justify="center">
          <img
            style={{
              height: "4vw",
              width: "4vw",
              objectFit: "cover"
            }}
            src={meso}
            alt="meso"
          />
          <div>
            <Text
              style={{
                fontWeight: "bold",
                fontSize: ".95dvw",
                bottom: "1vw",
                position: "relative",
                color: Theme.TEXT,
                justify: "center"
              }}
            >
              Create Block Template
            </Text>
          </div>
        </Col>
        <CreateBlockTemplateForm
          // Pass blockData if applicable
          closeModal={handleCloseModal} // Pass handleCloseModal here
          editModalOpen={setBlockTemplateEditModal} // Properly pass editModalOpen
        />
      </Modal>

      <Modal
        open={blockTemplateEditModalVisible}
        onCancel={() => setBlockTemplateEditModal(false)}
        footer={[<Col align={"center"} justify={"center"}></Col>]}
        width={"90vw"}
        style={{ top: "1vw" }}
        className={
          Theme.BACKGROUND === "#000"
            ? "customAntdModalDark"
            : "customAntdModalLight"
        }
      >
        <EditBlockTemplate />
      </Modal>

      <Modal
        open={assignModalVisible}
        onCancel={() => setAssignModalVisible(false)}
        cancelButtonProps={{ style: { display: "none" } }}
        footer={[<Col align={"center"} justify={"center"}></Col>]}
        width={"30vw"}
        style={{ top: "5vw" }}
        className={
          Theme.BACKGROUND === "#000"
            ? "customAntdModalDark"
            : "customAntdModalLight"
        }
      >
        <Col>
          <AssignBlock
            closeModal={() => setAssignModalVisible(false)}
            assignBlockId={assignBlockId}
          />
        </Col>
      </Modal>
    </Col>
  );
}

export default BlockTemplates;

const styles = StyleSheet.create({
  fieldrow: {
    marginBottom: "1vw"
  }
});
