import { useEffect, useState } from "react";

import {
  Row,
  Col,
  Card,
  Descriptions,
  message,
  Typography,
  Input,
  Modal,
  Select,
  Pagination
} from "antd";
import { updatePhoto } from "../redux/actions/clients";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteAccount,
  load_user,
  updateEmail,
  updatePass,
  resetPass,
  updateProfile
} from "../redux/actions/auth";
import { getClients, updateClientTrainers } from "../redux/actions/clients";
import { deleteTrainer, updateTrainer } from "../redux/actions/trainers";
import genericPofile from "../assets/images/profilepic.jpg";
import { IoCameraOutline, IoPencilSharp } from "react-icons/io5";

import Theme from "../constants/Theme";
import { Button } from "react-bootstrap";
import { StyleSheet, css } from "aphrodite";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import * as yup from "yup";

function ProfileComponent({ user_account, trainer, closeModal }) {
  const { user, Theme } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { trainerStatus, trainers } = useSelector((state) => state.trainers);
  const trainerExcludingSelf = trainers.filter(
    (item) => item.user_account.id !== trainer.user_account.id
  );
  const [imageURL, setImageURL] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hideButtons, setHideButtons] = useState(false);
  const [emailFormVisible, setEmailFormVisible] = useState(false);
  const [passFormVisible, setPassFormVisible] = useState(false);
  const [deactFormVisible, setDeactFormVisible] = useState(false);
  const [new_email, setEmailInputValue] = useState("");
  const [re_new_email, setReEmailInputValue] = useState("");
  const [new_password, setNewPassInputValue] = useState("");
  const [re_new_password, setNewRePassInputValue] = useState("");
  const [current_password, setPassInputValue] = useState("");
  const [clientReassignmentMap, setClientReassign] = useState({});
  const dispatchPromises = [];

  //const [first_name, setFirstNameValue] = useState(user?.profile?.first_name);
  const [first_name, setFirstNameValue] = useState(
    trainer.user_account.first_name
  );
  const [last_name, setLastNameValue] = useState(
    trainer.user_account.last_name
  );
  const [email, setEmailValue] = useState(trainer.user_account.email);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [firstNamePlaceHolder, setFirstNamePlaceholder] = useState(first_name);
  const [lastNamePlaceHolder, setLastNamePlaceholder] = useState(last_name);
  const [emailPlaceHolder, setEmailPlaceHolder] = useState(email);
  const [emailError, setEmailError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");

  const fetchUser = () => dispatch(load_user());
  useEffect(() => {
    fetchUser();
  }, []);
  const history = useHistory();
  const { clients, total_clients } = useSelector((state) => state.clients);

  // if (!isAuthenticated) {
  //   history.push("/sign-in");
  // }
  const { Text } = Typography;
  const cardHeight = `${25 + clients.length * 1.5}vw`;

  const submitNewEmail = async (e) => {
    dispatch(updateEmail(new_email, re_new_email, current_password));

    setTimeout(() => {
      setEmailInputValue("");
      setReEmailInputValue("");
      setPassInputValue("");

      setEmailFormVisible(false);
      setHideButtons(false);
      dispatch(load_user());
    }, 1000);
  };

  const submitNewPass = async (e) => {
    dispatch(resetPass(trainer.user_account.email));
    setPassFormVisible(false);
    setHideButtons(false);
    closeModal();
    /*
    setTimeout(() => {
      setPassInputValue("");
      setNewPassInputValue("");
      setNewRePassInputValue("");
      setPassFormVisible(false);
      setHideButtons(false);
      dispatch(load_user());
    }, 1000);
    */
  };

  const resetModal = () => {
    setEmailFormVisible(false);
    setPassFormVisible(false);
    setDeactFormVisible(false);
    setHideButtons(false);
  };

  const submitDelete = async (e) => {
    dispatch(deleteTrainer(trainer.user_account.id));
    for (var client_id in clientReassignmentMap) {
      dispatchPromises.push(
        dispatch(
          updateClientTrainers(client_id, clientReassignmentMap[client_id])
        )
      );
    }

    setDeactFormVisible(false);
    setHideButtons(false);
    closeModal();
    Promise.all(dispatchPromises)
      .then(() => {
        setClientReassign({});
      })
      .catch((error) => {
        setClientReassign({});
      });
  };

  const cancelUpdate = () => {
    setEmailFormVisible(false);
    setPassFormVisible(false);
    setDeactFormVisible(false);
    setHideButtons(false);
    setEmailInputValue("");
    setReEmailInputValue("");
    setNewPassInputValue("");
    setNewRePassInputValue("");
    setPassInputValue("");
    setClientReassign({});
  };

  const [isRowLayout, setIsRowLayout] = useState(window.innerWidth >= 1400);

  // Function to handle window resize event and update layout mode
  const handleWindowResize = () => {
    setIsRowLayout(window.innerWidth >= 768);
  };

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener("resize", handleWindowResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  var trainerPhotoPath = genericPofile;
  const [trainerPhoto, setTrainerPhoto] = useState(trainerPhotoPath);

  useEffect(() => {
    if (trainer.profile_picture != null) {
      setTrainerPhoto(trainer.profile_picture);
    } else {
      setTrainerPhoto(genericPofile);
    }
    setFirstNameValue(trainer.user_account.first_name);
    setFirstNamePlaceholder(trainer.user_account.first_name);
    setLastNameValue(trainer.user_account.last_name);
    setLastNamePlaceholder(trainer.user_account.last_name);
    setEmailValue(trainer.user_account.email);
    setEmailPlaceHolder(trainer.user_account.email);
    dispatch(getClients(1, trainer.user_account.id));
  }, [trainer]);

  const handleImageChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = async () => {
        setTrainerPhoto(reader.result);
        dispatch(updatePhoto(trainer.user_account.id, selectedFile, 1));
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const paginateChange = (page) => {
    dispatch(getClients(page, trainer.user_account.id));
  };

  const handleImageClick = () => {
    document.getElementById("imageInput").click();
  };

  const handleCancel = () => {
    setEditModalVisible(false);
  };

  const onFirstNameChange = (e) => {
    // Update the first_name state variable here
    setFirstNamePlaceholder(e.target.value);
  };
  const onLastNameChange = (e) => {
    // Update the LAst_name state variable here
    setLastNamePlaceholder(e.target.value);
  };
  const onEmailChange = (e) => {
    // Update the Emaile state variable here
    setEmailPlaceHolder(e.target.value);
  };
  const isFadedIn = true;
  const submitUpdate = async (e) => {
    e.preventDefault(); // Prevent form submission

    try {
      // Define validation schema
      const validationSchema = yup.object().shape({
        firstName: yup.string().required("First Name is required"),
        lastName: yup.string().required("Last Name is required"),
        email: yup
          .string()
          .email("Invalid email format")
          .matches(/^.+@.+\..+$/, "Invalid email format")
          .required("Email is required"),
        email: yup
          .string()
          .email("Invalid email format")
          .required("Email is required")
      });

      // Create an object with the values to validate
      const valuesToValidate = {
        firstName: firstNamePlaceHolder,
        lastName: lastNamePlaceHolder,
        email: emailPlaceHolder
      };

      // Validate the values
      await validationSchema.validate(valuesToValidate, { abortEarly: false });

      // If validation succeeds, update the state and dispatch the update action
      setFirstNameValue(firstNamePlaceHolder);
      setLastNameValue(lastNamePlaceHolder);
      setEmailValue(emailPlaceHolder);

      // Clear any previous error messages
      setEmailError("");
      // Clear other error messages for other fields if needed

      dispatch(
        updateTrainer(trainer.user_account.id, {
          first_name: firstNamePlaceHolder,
          last_name: lastNamePlaceHolder,
          email: emailPlaceHolder
        })
      );
      setEmailError("");
      setLastNameError("");
      setFirstNameError("");
      setEditModalVisible(false);
    } catch (error) {
      // Handle validation errors
      const validationErrors = {};
      if (error.inner) {
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
      }

      // Update error state variables for specific fields
      if (validationErrors.email) {
        setEmailError(validationErrors.email);
      }
      if (validationErrors.firstName) {
        setFirstNameError(validationErrors.firstName);
      }
      if (validationErrors.lastName) {
        setLastNameError(validationErrors.lastName);
      }
    }
  };

  return (
    <>
      <Col
        bordered={false}
        className={
          Theme.BACKGROUND === "#000"
            ? "customAntdModalDark"
            : "customAntdModalLight"
        }
        style={{ minHeight: cardHeight }} // Use minHeight instead of height
      >
        <div style={{ position: "relative", top: "1vw" }}>
          <Col align={"center"}>
            <div
              style={{
                bottom: ".5vw",
                // right: "6vw",
                position: "relative",
                borderRadius: 100,
                cursor: "pointer"
              }}
            >
              <img
                src={trainerPhoto}
                alt=""
                style={{
                  width: "6.25vw",
                  height: "6vw",
                  top: ".75vw",
                  position: "relative",
                  borderRadius: 100,
                  cursor: "pointer"
                }}
                object-fit="fill"
                onClick={handleImageClick}
                title="Upload"
              />
              <div
                onClick={handleImageClick}
                style={{
                  height: "1.75vw",
                  width: "1.75vw",
                  top: "5vw",
                  left: "55%",
                  position: "absolute",
                  borderRadius: 50,
                  cursor: "pointer",
                  opacity: 0.9,
                  backgroundImage:
                    "linear-gradient(45deg, #C04848 0%, #480048 80%)"
                }}
              >
                <IoCameraOutline
                  style={{
                    position: "relative",
                    zIndex: 60,
                    top: "5%",
                    // left: "16.5%",
                    fontSize: "1.5vw",
                    color: Theme.WHITE
                  }}
                />
              </div>
              <input
                id="imageInput"
                type="file"
                style={{ display: "none" }}
                onChange={handleImageChange}
              />
            </div>

            <div flex>
              <div middle style={{ marginTop: "5%" }}>
                {!hideButtons && (
                  <Text bold style={{ fontSize: "1vw", color: Theme.TEXT }}>
                    {first_name} {last_name}
                    <span
                      style={{
                        top: 0,
                        left: ".25vw",
                        position: "relative",
                        color: Theme.PRIMARY,
                        cursor: "pointer"
                      }}
                      onClick={() => setEditModalVisible(true)}
                    >
                      <IoPencilSharp size={".75vw"} />
                    </span>
                  </Text>
                )}
                {!hideButtons && (
                  <div>
                    <Text
                      size={18}
                      style={{ fontSize: "1vw", color: Theme.TEXT }}
                    >
                      {email}
                    </Text>
                  </div>
                )}

                {passFormVisible && (
                  <div style={{ top: "8%" }}>
                    <h3 style={{ color: Theme.TEXT }}>
                      A password reset link will be sent to their email
                    </h3>
                    <div style={styles.fieldrow}></div>

                    <div
                      middle
                      row
                      space="evenly"
                      style={{ top: ".5vw", position: "relative" }}
                    >
                      <Button
                        small
                        style={{
                          backgroundColor: Theme.BACKGROUND,
                          borderRadius: 5,
                          borderWidth: 0,
                          padding: "2%",
                          marginRight: ".5vw",
                          minWidth: "4vw",
                          cursor: "pointer",
                          boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.175)" // Adding a shadow effect
                        }}
                        onClick={(e) => submitNewPass(e)}
                      >
                        <Text
                          style={{
                            color: Theme.BLACK,
                            fontWeight: "bold",
                            color: Theme.TEXT
                          }}
                        >
                          Send
                        </Text>
                      </Button>
                      <Button
                        small
                        style={{
                          backgroundColor: Theme.BACKGROUND,
                          borderRadius: 5,
                          borderWidth: 0,
                          padding: "2%",
                          marginRight: ".5vw",
                          minWidth: "4vw",
                          cursor: "pointer",
                          boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.175)" // Adding a shadow effect
                        }}
                        onClick={() => cancelUpdate()}
                      >
                        <Text
                          style={{
                            color: Theme.BLACK,
                            fontWeight: "bold",
                            color: Theme.TEXT
                          }}
                        >
                          Cancel
                        </Text>
                      </Button>
                    </div>
                  </div>
                )}
                {deactFormVisible && (
                  <div style={{ top: "8%" }}>
                    <div style={styles.fieldrow}>
                      <h4 style={{ color: Theme.TEXT }}>
                        Are you sure you want to delete this Trainer?
                      </h4>
                      <div
                        middle
                        row
                        space="evenly"
                        style={{ top: "0vw", position: "relative" }}
                      >
                        <Button
                          small
                          style={{
                            backgroundColor: Theme.BACKGROUND,
                            borderRadius: 5,
                            borderWidth: 0,
                            padding: "2%",
                            marginRight: ".5vw",
                            minWidth: "4vw",
                            boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.175)" // Adding a shadow effect
                          }}
                          onClick={(e) => submitDelete(e)}
                        >
                          <Text
                            style={{
                              color: Theme.BLACK,
                              fontWeight: "bold",
                              color: Theme.TEXT
                            }}
                          >
                            Delete
                          </Text>
                        </Button>
                        <Button
                          small
                          style={{
                            backgroundColor: Theme.BACKGROUND,
                            borderRadius: 5,
                            borderWidth: 0,
                            padding: "2%",
                            marginRight: ".5vw",
                            minWidth: "4vw",
                            cursor: "pointer",
                            boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.175)" // Adding a shadow effect
                          }}
                          onClick={() => cancelUpdate()}
                        >
                          <Text
                            style={{
                              color: Theme.BLACK,
                              fontWeight: "bold",
                              color: Theme.TEXT
                            }}
                          >
                            Cancel
                          </Text>
                        </Button>
                      </div>
                    </div>
                    <div className={css(styles.column)}>
                      {clients.length > 0 && (
                        <Col style={{ marginTop: "10%" }}>
                          <Text
                            style={{
                              fontWeight: "bold",
                              fontSize: "1vw",
                              color: Theme.TEXT
                            }}
                          >
                            Reassign {user?.profile?.client_label}s
                          </Text>
                          <div>
                            <Text
                              style={{ fontSize: ".65vw", color: Theme.TEXT }}
                            >
                              This can also be updated in the{" "}
                              {user?.profile?.client_label.toLowerCase()}{" "}
                              details page at another time
                            </Text>
                          </div>
                        </Col>
                      )}
                      {clients.map((client) => (
                        <Row align={"center"} style={{ marginTop: "2.5%" }}>
                          <Col>
                            <Col>
                              <Text
                                style={{
                                  fontSize: ".75vw",
                                  position: "relative",
                                  right: "2.5%",
                                  fontWeight: "bold",
                                  color: Theme.TEXT
                                }}
                              >
                                {client.first_name} {client.last_name}
                              </Text>
                            </Col>
                            <Select
                              key={client.id}
                              style={{ width: "15vw" }}
                              showSearch
                              placeholder="Select Trainer to Assign"
                              optionFilterProp="children"
                              onChange={(selectedValue) => {
                                //clientReassignmentMap[client.id] =
                                //selectedValue;
                                setClientReassign((prevMap) => ({
                                  ...prevMap,
                                  [client.id]: selectedValue
                                }));
                              }}
                              //filterOption={filterOption}
                              options={trainerExcludingSelf.map((item) => ({
                                value: item.user_account.id.toString(),
                                label:
                                  item.user_account.first_name +
                                  " " +
                                  item.user_account.last_name
                              }))}
                              value={clientReassignmentMap[client.id]}
                            />
                          </Col>
                        </Row>
                      ))}
                      {clients.length > 0 && (
                        <Pagination
                          style={{ marginTop: "7.5%", position: "relative" }}
                          onChange={paginateChange}
                          total={total_clients}
                          defaultCurrent={1}
                          defaultPageSize={10}
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>

              {!hideButtons && (
                <div
                  center
                  column
                  style={{
                    position: "relative",
                    top: "1vw",
                    width: "18vw",
                    right: isRowLayout ? ".10vw" : ".75vw"
                  }}
                >
                  <div center row>
                    <Button
                      small
                      style={{
                        backgroundColor: Theme.BACKGROUND,
                        borderRadius: 5,
                        borderWidth: 0,
                        padding: "2%",
                        marginRight: ".5vw",
                        cursor: "pointer",
                        boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.175)" // Adding a shadow effect
                      }}
                      onClick={() => {
                        setPassFormVisible(true);
                        setHideButtons(true);
                      }}
                    >
                      <Text
                        style={{
                          fontWeight: "bold",
                          color: Theme.TEXT,
                          fontSize: ".75vw",
                          padding: 10,

                          textAlign: "center"
                        }}
                      >
                        Reset Password
                      </Text>
                    </Button>

                    <Button
                      small
                      style={{
                        backgroundColor: Theme.BACKGROUND,
                        borderRadius: 5,
                        borderWidth: 0,
                        padding: "2%",
                        marginLeft: ".5vw",
                        cursor: "pointer",
                        boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.175)" // Adding a shadow effect
                      }}
                      onClick={() => {
                        setDeactFormVisible(true);
                        setHideButtons(true);
                      }}
                    >
                      <Text
                        style={{
                          fontWeight: "bold",
                          color: Theme.TEXT,
                          fontSize: ".75vw",
                          padding: 10,
                          textAlign: "center"
                        }}
                      >
                        Delete Account
                      </Text>
                    </Button>

                    {trainerStatus > 399 && (
                      <div
                        className={css(styles.row, styles.center)}
                        style={{ top: 25 }}
                      >
                        <p className={css(styles.errorText)}>
                          Delete failed. Please try again.
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </Col>
        </div>

        <div middle style={styles.legalCard}></div>
        <div style={{ height: 200 }}></div>
        <Modal
          open={editModalVisible}
          onCancel={handleCancel}
          width={"32vw"}
          align="center"
          justify="center"
          title="Edit Profile"
          bodyStyle={{ minHeight: "20w", paddingBottom: "15%" }}
          footer={[null]}
        >
          <form onSubmit={(e) => submitUpdate(e)}>
            <Col
              align="center"
              justify="center"
              style={{ top: "1vw", position: "relative" }}
            >
              <Col align="center" justify="center" style={styles.fieldrow}>
                <Input
                  style={{ width: "10vw", color: "black" }}
                  placeholder={user?.profile?.first_name}
                  name="current password"
                  secureTextEntry={true}
                  onChange={(e) => onFirstNameChange(e)}
                  defaultValue={first_name}
                />
                <div style={{ marginTop: ".5vw" }}>
                  <Text style={{ color: "red" }}>{firstNameError}</Text>
                </div>
              </Col>
              <div style={{ marginTop: ".5vw", marginBottom: ".5vw" }}>
                <Input
                  style={{ width: "10vw", color: "black" }}
                  secureTextEntry={true}
                  onChange={(e) => onLastNameChange(e)}
                  defaultValue={last_name}
                />
                <div style={{ marginTop: ".5vw" }}>
                  <Text style={{ color: "red" }}>{lastNameError}</Text>
                </div>
              </div>
              <div style={styles.fieldrow}>
                <Input
                  style={{ width: "10vw", color: "black" }}
                  secureTextEntry={true}
                  onChange={(e) => onEmailChange(e)}
                  defaultValue={email}
                />
                <div style={{ marginTop: ".5vw" }}>
                  <Text style={{ color: "red" }}>{emailError}</Text>
                </div>
              </div>
              <div
                middle
                row
                space="evenly"
                style={{ top: "1vw", position: "relative" }}
              >
                <Button
                  small
                  style={{
                    backgroundColor: Theme.BACKGROUND,
                    borderRadius: 5,
                    borderWidth: 0,
                    padding: "2%",
                    marginRight: ".5vw",
                    minWidth: "4vw",
                    cursor: "pointer",
                    boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.175)" // Adding a shadow effect
                  }}
                  onClick={(e) => submitUpdate(e)}
                >
                  <Text
                    style={{
                      color: Theme.BLACK,
                      fontWeight: "bold",
                      color: Theme.TEXT
                    }}
                  >
                    Save
                  </Text>
                </Button>
              </div>
            </Col>
          </form>
        </Modal>
      </Col>
    </>
  );
}

export default ProfileComponent;

const styles = StyleSheet.create({
  profile: {
    // marginBottom: 50,
    shadowColor: Theme.COLORS.BLACK,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 4,
    shadowOpacity: 0.1,
    // justifyContent: "flex-start",.
    flex: 1
  },
  profileContainer: {
    // height: '100%',
    shadowColor: Theme.COLORS.BLACK,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 4,
    shadowOpacity: 0.5,
    elevation: 2,
    zIndex: 1,
    justifyContent: "center",
    alignItems: "center"
  },
  profileBackground: {
    // width: width,
    // height: height / 2,
  },
  profileCard: {
    // position: "relative",
    // paddingTop: 25,
    marginBottom: "5%",
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    // backgroundColor: Theme.COLORS.WHITE,
    shadowColor: Theme.COLORS.BLACK,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 4,
    shadowOpacity: 0.5,
    zIndex: 2,
    elevation: 2
  },
  legalCard: {
    // position: "relative",
    // paddingTop: 25,
    marginBottom: "25%",
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    // backgroundColor: Theme.COLORS.WHITE,
    shadowColor: Theme.COLORS.BLACK,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 4,
    shadowOpacity: 0.5,
    zIndex: 2,
    elevation: 2
  },
  info: {
    paddingHorizontal: 40,
    elevation: 2
  },

  avatar: {
    height: "45%",
    width: "40%",
    borderRadius: 62,
    borderWidth: 0,
    bottom: "15%"
  },
  nameInfo: {
    // marginTop: '4%',
    bottom: "25%"
  },
  divider: {
    width: "90%",
    borderWidth: 1,
    borderColor: "#E9ECEF"
  },
  scroll: {
    borderRadius: 5,
    height: "200%",
    top: 50
    // marginBottom: 500,
  },
  button: {
    backgroundColor: Theme.COLORS.WHITE,
    height: 45,
    width: 90,
    marginBottom: "10%",
    shadowColor: Theme.COLORS.BLACK,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 4,
    shadowOpacity: 0.5
  },
  saveButton: {
    backgroundColor: Theme.COLORS.WHITE,
    height: 45,
    width: "40%",
    marginBottom: "10%",
    shadowColor: Theme.COLORS.BLACK,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 4,
    shadowOpacity: 0.5
  },
  legalText: {
    color: Theme.COLORS.BLACK,
    marginVertical: "2%"
  },
  input: {
    // backgroundColor: "#fff",
    borderBottomColor: Theme.COLORS.PRIMARY,
    borderColor: "transparent",
    borderWidth: 1,
    paddingHorizontal: 10,
    paddingBottom: 10,
    // margin: 10,
    // borderRadius: 5,
    color: Theme.COLORS.WHITE,
    maxWidth: "100%",
    minWidth: "70%"
  },
  bottomInputs: {
    // backgroundColor: "#fff",
    borderBottomColor: Theme.COLORS.PRIMARY,
    borderColor: "transparent",
    borderWidth: 1,
    padding: 10,
    // margin: 10,
    // borderRadius: 5,

    color: Theme.COLORS.BLACK,
    maxWidth: "100%",
    minWidth: "70%"
  },
  fieldrow: {
    flexDirection: "row",
    alignItems: "center"
  },
  modalView: {
    backgroundColor: "black",
    backgroundColor: "white",
    borderRadius: 10,
    // alignItems: "center",
    top: 15
    // alignSelf: "center",
  },
  bottomView: {
    flex: 1,
    // justifyContent: "flex-end",
    top: 25
    // alignSelf: "center",
  }
});
