import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Modal, Row, Typography, Spin } from "antd";

import { Button } from "react-bootstrap";
import { analytics } from "../../firebase-config";
import { logEvent } from "firebase/analytics";
import {
  copyBlockTemplateWeek,
  deleteBlockTemplate,
  getSingleBlockTemplate,
  getSingleProgramTemplate,
  getSingleWorkoutTemplate,
  resetBlockTemplateStatus,
  resetWorkoutStatus
} from "../../redux/actions/workouts";
import {
  CopyOutlined,
  ExclamationCircleOutlined,
  PlusCircleOutlined
} from "@ant-design/icons";
import { CiDumbbell } from "react-icons/ci";
import CreateBlockTemplateForm from "./CreateBlockTemplate";
import EditWorkoutTemplate from "./EditWorkoutTemplate";

import CreateWorkoutTemplateForm from "./CreateWorkoutTemplate";
import meso from "../../assets/images/mesocycle.png";
import WorkoutTemplateDetails from "./WorkoutTemplateDetails";
import AssignBlock from "./AssignBlock";

const { Text } = Typography;

const EditBlockTemplate = ({ closeModal }) => {
  const dispatch = useDispatch();
  const { Theme } = useSelector((state) => state.auth);
  const {
    blockTemplate,
    blockTemplateStatus,
    workoutStatus,
    workout,
    programTemplate
  } = useSelector((state) => state.workouts);
  const [sourceWeek, setSourceWeek] = useState(null);
  const [targetWeeks, setTargetWeeks] = useState([]);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [copyModalVisible, setCopyModalVisible] = useState(false);
  const [editWorkoutModalOpen, setEditWorkoutModalOpen] = useState(false);
  const [copyError, setCopyError] = useState(false);
  const [copyLoading, setCopyLoading] = useState(false);
  const [assignModalVisible, setAssignModalVisible] = useState(false);

  const [loading, setLoading] = useState(true);
  const [fullDescript, setFullDescript] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedWorkout, setSelectedWorkout] = useState({});
  const [workoutUpdated, setWorkoutUpdated] = useState(false);

  useEffect(() => {
    if (blockTemplate && !workoutUpdated && blockTemplate?.weeks?.length) {
      const firstNonEmptyWeek = blockTemplate?.weeks?.find(
        (week) => week.workouts && week.workouts.length > 0
      );
      if (firstNonEmptyWeek) {
        setSelectedWorkout(firstNonEmptyWeek.workouts[0]);
      }
      setWorkoutUpdated(false);
    }
  }, [blockTemplate, workoutUpdated]);

  useEffect(() => {
    return () => {
      setSelectedWorkout({});
    };
  }, []);

  useEffect(() => {
    if (!blockTemplate?.weeks && blockTemplate?.id) {
      dispatch(getSingleBlockTemplate(blockTemplate?.id));
    }
  }, [blockTemplate]);

  useEffect(() => {
    setCopyLoading(false);
    if (blockTemplateStatus === "success") {
      dispatch(getSingleBlockTemplate(blockTemplate?.id));
    }
    if (blockTemplateStatus === "updated") {
      setEditModalOpen(false);
      dispatch(getSingleBlockTemplate(blockTemplate?.id)).then(
        (response) => dispatch(resetBlockTemplateStatus()),
        setLoading(true),
        setTimeout(() => {
          setLoading(false);
        }, 1100)
      );
    }
    if (blockTemplateStatus === "deleted") {
      closeModal();
      dispatch(getSingleProgramTemplate(programTemplate?.id));
      dispatch(resetBlockTemplateStatus());
    }
    if (blockTemplateStatus === "week success") {
      setCopyModalVisible(false);
      setTargetWeeks([]);
      dispatch(getSingleBlockTemplate(blockTemplate?.id));
      dispatch(resetBlockTemplateStatus());
    } else if (blockTemplateStatus === "week fail") {
      setCopyError(true);
    }
  }, [blockTemplateStatus]);

  useEffect(() => {
    if (
      workoutStatus == "success" ||
      workoutStatus == "updated" ||
      workoutStatus == "deleted"
    ) {
      dispatch(getSingleBlockTemplate(blockTemplate?.id)).then(
        (response) => setSelectedWorkout(workout[0]),
        setWorkoutUpdated(true),
        dispatch(resetWorkoutStatus())
      );
    }
  }, [workoutStatus]);

  useEffect(() => {
    logEvent(analytics, "page_view", { title: "shit piss" });

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const handleCloseModal = () => {
    setCreateModalVisible(false);
    setCopyModalVisible(false);
    setEditWorkoutModalOpen(false);
  };

  const closeEditModal = () => {
    setEditWorkoutModalOpen(false);
  };

  const deleteAlert = (id) => {
    Modal.confirm({
      title: "Are you sure you want to delete this Template?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "Cancel",
      onOk: () => {
        dispatch(deleteBlockTemplate(id)).then(
          dispatch(getSingleProgramTemplate(blockTemplate?.macro_template))
        );
      }
    });
  };

  return (
    <div
      style={{
        marginTop: "9vw",
        position: "relative",

        width: "85vw"
      }}
      className={`fade-in-slide-up-element ${
        true ? "fade-in-slide-up-animation" : ""
      }`}
    >
      <Col
        style={{
          width: "85vw",
          position: "relative",
          bottom: "9vw",
          minHeight: "100vh",

          background: Theme.CARD,
          borderWidth: 0,
          borderRadius: 10
        }}
      >
        {loading ? (
          <Col
            align="middle"
            justify="center"
            style={{ marginTop: "25%", position: "relative", top: "15%" }}
          >
            <Spin
              style={{ marginTop: "25%", position: "relative", top: "15%" }}
              size="large"
            />
          </Col>
        ) : (
          <div>
            {blockTemplate && (
              <div>
                <Row
                  style={{
                    paddingLeft: "2vw",
                    marginBottom: blockTemplate?.description ? "1vw" : "2vw",
                    background: "#000",
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                    paddingTop: "1vw",
                    paddingBottom: "1vw"
                  }}
                >
                  <img
                    style={{
                      height: "3.5vw",
                      width: "5.9vw",
                      borderRadius: 10,
                      objectFit: "cover",
                      marginRight: "1vw"
                    }}
                    src={meso}
                  />
                  <Col>
                    <Text
                      style={{
                        fontSize: "1.2vw",
                        color: Theme.WHITE,
                        fontWeight: "bold"
                      }}
                    >
                      {blockTemplate?.name}
                    </Text>
                    {blockTemplate?.description && (
                      <div style={{ width: "30vw" }}>
                        {blockTemplate?.description.length < 230 ? (
                          <Text
                            style={{ fontSize: ".65vw", color: Theme.WHITE }}
                          >
                            {blockTemplate?.description}
                          </Text>
                        ) : fullDescript ? (
                          <Text
                            style={{ fontSize: ".55vw", color: Theme.WHITE }}
                          >
                            {blockTemplate?.description}{" "}
                            <span
                              onClick={() => setFullDescript(false)}
                              style={{
                                fontSize: ".75vw",
                                color: Theme.PRIMARY,
                                fontWeight: "bold",
                                cursor: "pointer"
                              }}
                            >
                              Show Less
                            </span>
                          </Text>
                        ) : (
                          <Row>
                            <Text
                              style={{ fontSize: ".55vw", color: Theme.WHITE }}
                            >
                              {blockTemplate?.description.slice(0, 230)}...{" "}
                              <span
                                onClick={() => setFullDescript(true)}
                                style={{
                                  fontSize: ".75vw",
                                  color: Theme.PRIMARY,
                                  fontWeight: "bold",
                                  cursor: "pointer"
                                }}
                              >
                                Show More
                              </span>
                            </Text>{" "}
                          </Row>
                        )}
                      </div>
                    )}
                  </Col>

                  <Row style={{ position: "absolute", right: "2vw" }}>
                    <Col style={{ marginLeft: "4vw", textAlign: "center" }}>
                      <Text
                        style={{
                          fontSize: ".9vw",
                          color: Theme.WHITE,
                          fontWeight: "bold"
                        }}
                      >
                        Length
                      </Text>
                      <div>
                        <Text style={{ fontSize: ".9vw", color: Theme.WHITE }}>
                          {blockTemplate?.numOfWeeks} Weeks
                        </Text>
                      </div>
                    </Col>
                    {blockTemplate?.order && (
                      <Col
                        style={{
                          marginLeft: "4vw",
                          marginRight: "2vw",
                          textAlign: "center"
                        }}
                      >
                        <Text
                          style={{
                            fontSize: ".9vw",
                            color: Theme.WHITE,
                            fontWeight: "bold"
                          }}
                        >
                          Order
                        </Text>
                        <div>
                          <Text
                            style={{ fontSize: ".9vw", color: Theme.WHITE }}
                          >
                            {blockTemplate?.order}
                          </Text>
                        </div>
                      </Col>
                    )}
                    {!blockTemplate?.macro_template && (
                      <Button
                        onClick={() => {
                          setAssignModalVisible(true);
                        }}
                        style={{
                          marginLeft: "4vw",
                          cursor: "pointer",
                          height: "2dvw",
                          width: "4vw",
                          // backgroundImage:
                          //   "linear-gradient(60deg, #C04848 25%, #480048 120%)",
                          borderRadius: 10,
                          borderWidth: 0
                        }}
                      >
                        <Text
                          style={{
                            color: Theme.BLACK,
                            fontSize: ".75vw",
                            textAlign: "center",
                            fontWeight: "bold"
                          }}
                        >
                          Assign
                        </Text>
                      </Button>
                    )}
                    <Button
                      color={"PRIMARY"}
                      style={{
                        marginLeft: "2vw",
                        cursor: "pointer",
                        height: "2dvw",
                        width: "4vw",
                        // backgroundImage:
                        //   "linear-gradient(60deg, #C04848 25%, #480048 120%)",
                        borderRadius: 10,
                        borderWidth: 0
                      }}
                      onClick={() => setEditModalOpen(true)}
                    >
                      <div style={{ flexDirection: "column" }}>
                        <div style={{ flexDirection: "row" }}>
                          <Text
                            style={{
                              color: Theme.BLACK,
                              fontSize: ".75vw",
                              textAlign: "center",
                              fontWeight: "bold"
                            }}
                          >
                            Edit
                          </Text>
                        </div>
                      </div>
                    </Button>
                    {blockTemplate?.macro_template && (
                      <Button
                        color={"PRIMARY"}
                        style={{
                          marginLeft: "2vw",
                          cursor: "pointer",
                          height: "2dvw",
                          width: "4vw",
                          // backgroundImage:
                          //   "linear-gradient(60deg, #C04848 25%, #480048 120%)",
                          borderRadius: 10,
                          borderWidth: 0
                        }}
                        onClick={() => deleteAlert(blockTemplate?.id)}
                      >
                        <div style={{ flexDirection: "column" }}>
                          <div style={{ flexDirection: "row" }}>
                            <Text
                              style={{
                                color: Theme.BLACK,
                                fontSize: ".75vw",
                                textAlign: "center",
                                fontWeight: "bold"
                              }}
                            >
                              Delete
                            </Text>
                          </div>
                        </div>
                      </Button>
                    )}
                  </Row>
                </Row>
                {/* <hr /> */}
                <Row style={{ marginLeft: "0vw" }}>
                  <Col
                    align="start"
                    style={{
                      width: "17vw",
                      marginLeft: "0vw",
                      marginRight: "2vw",
                      position: "relative",
                      position: "relative",
                      // right: "1vw",
                      borderRightColor: Theme.TEXT2,
                      paddingBottom: "5vw"
                      // background: Theme.BACKGROUND
                    }}
                  >
                    <Col
                      style={{
                        marginRight: "3vw",
                        position: "relative",
                        left: "2vw"
                      }}
                    >
                      <Col align="middle" justify={"center"}></Col>
                      {blockTemplate?.weeks?.map((week, index) => (
                        <div key={index} style={{ marginTop: "1vw" }}>
                          {/* Week Workouts */}
                          <Col
                            style={{
                              marginBottom:
                                week?.workouts?.length === 0 ? "3vw" : "1.5vw"
                            }}
                          >
                            <Text
                              style={{
                                fontSize: ".9vw",
                                color: Theme.TEXT,
                                fontWeight: "bold"
                              }}
                            >
                              Week {index + 1}
                            </Text>
                            {week?.workouts?.length > 0 && (
                              <Button
                                color={"PRIMARY"}
                                style={{
                                  paddingRight: ".5vw",
                                  // height: "1.5vw",
                                  cursor: "pointer",

                                  // top: "1.85vw",
                                  backgroundImage:
                                    "linear-gradient(60deg, #C04848 25%, #480048 120%)",
                                  borderRadius: 10,
                                  borderWidth: 0,
                                  marginLeft: ".75vw"
                                  // opacity: 0.9,
                                }}
                                onClick={() => {
                                  setCopyModalVisible(true);
                                  setSourceWeek(index + 1);
                                }}
                              >
                                <div style={{ flexDirection: "column" }}>
                                  <div style={{ flexDirection: "row" }}>
                                    <Text
                                      style={{
                                        color: "white",
                                        fontSize: ".75vw",
                                        textAlign: "center",

                                        fontWeight: "bold",
                                        marginBottom: 20
                                      }}
                                    >
                                      <CopyOutlined
                                        style={{
                                          position: "relative",
                                          fontSize: ".7vw",
                                          color: Theme.WHITE,
                                          cursor: "pointer"
                                        }}
                                      />
                                      Copy Week
                                    </Text>
                                  </div>
                                </div>
                              </Button>
                            )}

                            {week?.workouts?.map((workout) => (
                              <Col
                                key={workout.id}
                                style={{
                                  marginTop: "1vw",
                                  background:
                                    selectedWorkout.id === workout.id
                                      ? Theme.PRIMARY
                                      : Theme.BACKGROUND,
                                  borderWidth: 0,
                                  backgroundImage:
                                    selectedWorkout.id === workout.id
                                      ? "linear-gradient(60deg, #000000 5%, #353939 85%)"
                                      : null,
                                  cursor: "pointer",
                                  minWidth: "13vw",
                                  borderRadius: 5,
                                  boxShadow:
                                    "1px 1px 1px 1px rgba(0, 0, 0, 0.175)",
                                  padding: "10px",
                                  paddingTop: "5%",
                                  paddingBottom: "5%",
                                  marginBottom: ".5vw",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                  right: ".5vw",
                                  position: "relative"
                                }}
                                onClick={() => {
                                  setSelectedWorkout(workout);
                                  dispatch(
                                    getSingleWorkoutTemplate(workout.id)
                                  );
                                }}
                              >
                                <Col
                                  align="start"
                                  style={{ marginLeft: ".25vw" }}
                                >
                                  <div style={{ marginLeft: ".5vw" }}>
                                    <Text
                                      style={{
                                        color: Theme.PRIMARY,
                                        fontSize: ".75vw",
                                        fontWeight: "bold"
                                      }}
                                    >
                                      Day {workout.day}
                                    </Text>
                                  </div>
                                  <div
                                    style={{
                                      width: "85%",
                                      marginLeft: ".5vw",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis"
                                    }}
                                  >
                                    <Text
                                      style={{
                                        color:
                                          selectedWorkout.id === workout.id
                                            ? Theme.WHITE
                                            : Theme.TEXT,
                                        fontSize: ".7vw",
                                        fontWeight: "bold",
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis"
                                      }}
                                    >
                                      {workout.title}
                                    </Text>
                                  </div>
                                </Col>
                              </Col>
                            ))}
                          </Col>
                        </div>
                      ))}
                    </Col>
                  </Col>
                  {blockTemplate?.weeks?.every(
                    (week) => week?.workouts?.length === 0
                  ) ? (
                    <Col
                      align="middle"
                      style={{
                        // marginLeft: "5vw",
                        position: "relative",
                        right: "5%",
                        marginTop: "5vw",
                        width: "70%"
                      }}
                    >
                      <CiDumbbell size={"3vw"} style={{ color: Theme.TEXT }} />
                      <div>
                        <Text style={{ fontSize: "1vw", color: Theme.TEXT }}>
                          No Workouts for this Block Template
                        </Text>
                      </div>
                      <div>
                        <Button
                          color={"PRIMARY"}
                          style={{
                            // width: "5vw",
                            // position: "absolute",
                            // right: "1vw",
                            padding: ".5vw",
                            marginTop: "1vw",
                            paddingRight: ".5vw",
                            // height: "1.5vw",
                            cursor: "pointer",

                            // top: "1.85vw",
                            backgroundImage:
                              "linear-gradient(60deg, #C04848 25%, #480048 120%)",
                            borderRadius: 10,
                            borderWidth: 0
                            // opacity: 0.9,
                          }}
                          onClick={() => setCreateModalVisible(true)}
                        >
                          <div style={{ flexDirection: "column" }}>
                            <div style={{ flexDirection: "row" }}>
                              <Text
                                style={{
                                  color: "white",
                                  fontSize: ".75vw",
                                  textAlign: "center",

                                  fontWeight: "bold"
                                }}
                              >
                                <PlusCircleOutlined
                                  style={{
                                    position: "relative",
                                    fontSize: ".7vw",
                                    color: Theme.WHITE,
                                    cursor: "pointer"
                                  }}
                                />{" "}
                                Add Workout
                              </Text>
                            </div>
                          </div>
                        </Button>
                      </div>
                    </Col>
                  ) : (
                    selectedWorkout !== null && (
                      <Col
                        style={{
                          background: Theme.BACKGROUND,
                          // backgroundImage: Theme.BACKGROUND !== "#121212" ?
                          // "linear-gradient(60deg, #C04848 25%, #480048 120%)" : null,
                          borderRadius: 20,
                          // marginLeft: "3vw",
                          marginTop: "1vw",
                          width: "65%"
                        }}
                        align={"middle"}
                        justify={"center"}
                      >
                        <Col style={{ marginTop: "5%" }}>
                          <WorkoutTemplateDetails
                            workoutData={selectedWorkout}
                            onBlock={true}
                          />
                        </Col>
                      </Col>
                    )
                  )}
                  {blockTemplate && (
                    <div>
                      {blockTemplate?.weeks?.some(
                        (week) => week?.workouts?.length > 0
                      ) ? (
                        <Col align={"end"} style={{ marginLeft: "3vw" }}>
                          <Button
                            color={"PRIMARY"}
                            style={{
                              padding: ".5vw",
                              top: "7vw",
                              paddingRight: ".5vw",
                              backgroundImage:
                                "linear-gradient(60deg, #C04848 25%, #480048 120%)",
                              borderRadius: 10,
                              borderWidth: 0,
                              cursor: "pointer"
                            }}
                            onClick={() => setCreateModalVisible(true)}
                          >
                            <div style={{ flexDirection: "column" }}>
                              <div style={{ flexDirection: "row" }}>
                                <Text
                                  style={{
                                    color: "white",
                                    fontSize: ".75vw",
                                    textAlign: "center",
                                    fontWeight: "bold"
                                  }}
                                >
                                  <PlusCircleOutlined
                                    style={{
                                      position: "relative",
                                      fontSize: ".7vw",
                                      color: Theme.WHITE,
                                      cursor: "pointer"
                                    }}
                                  />{" "}
                                  Add Workout
                                </Text>
                              </div>
                            </div>
                          </Button>
                        </Col>
                      ) : null}
                    </div>
                  )}
                </Row>
              </div>
            )}
          </div>
        )}
        <Modal
          open={editModalOpen}
          onCancel={() => setEditModalOpen(false)}
          cancelButtonProps={{ style: { display: "none" } }}
          footer={[<Col align={"center"} justify={"center"}></Col>]}
          width={"35vw"}
          style={{ top: "1vw" }}
          className={
            Theme.BACKGROUND === "#000"
              ? "customAntdModalDark"
              : "customAntdModalLight"
          }
        >
          <Col align="middle" style={{ marginTop: "1vw" }}>
            <Col>
              {" "}
              <img
                style={{
                  height: "4vw",
                  width: "4vw",
                  objectFit: "cover"
                }}
                src={meso}
              />
              <div>
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: ".95dvw",
                    bottom: "1vw",
                    position: "relative",
                    color: Theme.TEXT,
                    justify: "center"
                  }}
                >
                  Update Block Template
                </Text>
              </div>
            </Col>

            <Row
              aling="middle"
              justify={"center"}
              style={{ marginTop: "1vw", paddingBottom: "1.5vw" }}
            >
              <CreateBlockTemplateForm
                blockData={blockTemplate}
                modalOpen={editModalOpen}
              />
            </Row>
          </Col>
        </Modal>
        <Modal
          open={createModalVisible}
          onCancel={() => setCreateModalVisible(false)}
          cancelButtonProps={{ style: { display: "none" } }}
          closeIcon={<span></span>}
          footer={[<Col align={"center"} justify={"center"}></Col>]}
          width={"90vw"}
          style={{ top: "1vw" }}
          className={
            Theme.BACKGROUND === "#000"
              ? "customAntdModalDark"
              : "customAntdModalLight"
          }
        >
          <CreateWorkoutTemplateForm
            closeModal={handleCloseModal}
            meso={blockTemplate}
          />
        </Modal>
        <Modal
          open={editWorkoutModalOpen}
          onCancel={() => setEditWorkoutModalOpen(false)}
          cancelButtonProps={{ style: { display: "none" } }}
          closeIcon={<span></span>}
          footer={[<Col align={"center"} justify={"center"}></Col>]}
          width={"90vw"}
          style={{ top: "1vw" }}
          className={
            Theme.BACKGROUND === "#000"
              ? "customAntdModalDark"
              : "customAntdModalLight"
          }
        >
          <EditWorkoutTemplate
            closeModal={handleCloseModal}
            workoutData={selectedWorkout}
            editModalOpen={editWorkoutModalOpen}
            setModalOpen={closeEditModal}
            fromMeso={true}
          />
        </Modal>
        <Modal
          open={copyModalVisible}
          onCancel={() => setCopyModalVisible(false)}
          footer={[<Col align={"center"} justify={"center"}></Col>]}
          width={"30vw"}
          style={{ top: "1vw" }}
          className={
            Theme.BACKGROUND === "#000"
              ? "customAntdModalDark"
              : "customAntdModalLight"
          }
        >
          <Col
            align="middle"
            justify="center"
            style={{
              display: "flex", // Ensure flex layout
              flexDirection: "column", // Stack children vertically
              gap: "10px" // Add spacing between buttons
            }}
          >
            <Text
              style={{ fontSize: "1vw", color: Theme.TEXT, fontWeight: "bold" }}
            >
              Select Weeks
            </Text>
            {blockTemplate &&
              blockTemplate?.weeks?.map((weekData, index) => {
                const isSelected = targetWeeks.includes(index + 1);
                if (index + 1 == sourceWeek) {
                  return null;
                }
                return (
                  <Button
                    key={index}
                    color="secondary"
                    textStyle={{ fontSize: 12, fontWeight: "700" }}
                    style={{
                      background: isSelected ? Theme.PRIMARY : Theme.BACKGROUND,
                      borderRadius: 10,
                      height: 30,
                      cursor: "pointer",
                      borderWidth: 0,
                      alignSelf: "center",
                      width: "20vw"
                    }}
                    onClick={() =>
                      targetWeeks.includes(index + 1)
                        ? setTargetWeeks(
                            targetWeeks.filter((week) => week !== index + 1)
                          )
                        : setTargetWeeks([...targetWeeks, index + 1])
                    }
                  >
                    <Text
                      style={{
                        color: isSelected ? Theme.WHITE : Theme.TEXT2
                      }}
                    >
                      Week {index + 1}
                    </Text>
                  </Button>
                );
              })}
            <Button
              color={"PRIMARY"}
              style={{
                width: "15vw",
                // position: "absolute",
                // right: "1vw",
                padding: ".5vw",
                marginTop: "1vw",
                paddingRight: ".5vw",
                // height: "1.5vw",
                cursor: "pointer",

                // top: "1.85vw",
                backgroundImage:
                  "linear-gradient(60deg, #C04848 25%, #480048 120%)",
                borderRadius: 10,
                borderWidth: 0,
                alignSelf: "center"
              }}
              onClick={() => {
                if (blockTemplate) {
                  setCopyLoading(true);
                  dispatch(
                    copyBlockTemplateWeek(blockTemplate?.id, {
                      source_week: sourceWeek,
                      target_weeks: targetWeeks
                    })
                  );
                }
              }}
            >
              <div style={{ flexDirection: "column" }}>
                <div style={{ flexDirection: "row" }}>
                  {copyLoading ? (
                    <Spin size="medium" />
                  ) : (
                    <Text
                      style={{
                        color: "white",
                        fontSize: ".75vw",
                        textAlign: "center",

                        fontWeight: "bold"
                      }}
                    >
                      <CopyOutlined
                        style={{
                          position: "relative",
                          fontSize: ".7vw",
                          color: Theme.WHITE,
                          cursor: "pointer"
                        }}
                      />{" "}
                      Copy Week
                    </Text>
                  )}
                </div>
              </div>
            </Button>
            {copyError && (
              <Text style={{ color: "red" }}>Error copying week</Text>
            )}
          </Col>
        </Modal>
        <Modal
          open={assignModalVisible}
          onCancel={() => setAssignModalVisible(false)}
          cancelButtonProps={{ style: { display: "none" } }}
          footer={[<Col align={"center"} justify={"center"}></Col>]}
          width={"30vw"}
          style={{ top: "5vw" }}
          className={
            Theme.BACKGROUND === "#000"
              ? "customAntdModalDark"
              : "customAntdModalLight"
          }
        >
          <Col>
            <AssignBlock
              closeModal={() => setAssignModalVisible(false)}
              assignBlockId={blockTemplate?.id}
            />
          </Col>
        </Modal>
      </Col>
    </div>
  );
};

export default EditBlockTemplate;
