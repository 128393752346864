// Import necessary components and libraries
import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  DatePicker,
  Button,
  Row,
  Typography,
  Col,
  Modal
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  createProgramTemplate,
  getProgramTemplates,
  resetProgramTemplateStatus,
  updateProgram,
  updateProgramTemplate
} from "../../redux/actions/workouts";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const { Text } = Typography;

const CreateProgramTemplateForm = ({
  programTemplateData,
  closeModal,
  setProgramTemplateEditModal
}) => {
  const dispatch = useDispatch();
  const { Theme } = useSelector((state) => state.auth);
  const { client } = useSelector((state) => state.clients);
  const { programTemplateStatus } = useSelector((state) => state.workouts);
  const [form] = Form.useForm();
  const [error, setError] = useState(false);

  const onFinish = async (values) => {
    try {
      setError(false);
      const { name, description } = values;

      const progData = {
        name,
        description,
        client: client.id
      };
      if (programTemplateData) {
        dispatch(updateProgramTemplate(programTemplateData?.id, progData));
      } else {
        dispatch(createProgramTemplate(progData));
      }
    } catch (error) {
      console.error("Error creating program:", error);
      setError(true);
    }
  };

  useEffect(() => {
    if (programTemplateData) {
      form.setFieldsValue({
        name: programTemplateData?.name,
        description: programTemplateData?.description,
        start_date: programTemplateData?.time_length_start,
        end_date: programTemplateData?.time_length_end
      });
    }
  }, [programTemplateData]);

  useEffect(() => {
    if (programTemplateStatus !== null) {
      dispatch(resetProgramTemplateStatus());
      closeModal();
    }
    if (programTemplateStatus === "success") {
      setProgramTemplateEditModal(true);
      dispatch(getProgramTemplates());
    }
  }, [programTemplateStatus]);

  return (
    <Form
      name="createProgramTemplate"
      onFinish={onFinish}
      initialValues={{ remember: true }}
      layout="vertical"
      align="middle"
      justify="center"
      form={form}
      style={{ background: Theme.CARD }}
    >
      <Col align="middle" justify="center">
        <Form.Item
          className="custom-form-item"
          //   label="Name"
          name="name"
          style={{ fontSize: "0.75vw", marginTop: ".5vw" }}
          rules={[{ required: true, message: "Please enter the program name" }]}
        >
          <Input
            placeholder="Name"
            className={
              Theme.BACKGROUND === "#000"
                ? "custom-placeholder-color-dark"
                : "custom-placeholder-color-light"
            }
            style={{
              width: "15vw",
              color: Theme.TEXT,
              background: Theme.BACKGROUND,
              borderWidth: Theme.BACKGROUND === "#000" ? 0 : 1,
              fontSize: ".75vw"
            }}
          />
        </Form.Item>
      </Col>

      <Col align="middle" justify="center">
        <Form.Item
          className="custom-form-item"
          name="description"
          style={{ marginTop: ".5vw" }}
        >
          <Input.TextArea
            className={
              Theme.BACKGROUND === "#000"
                ? "custom-placeholder-color-dark"
                : "custom-placeholder-color-light"
            }
            placeholder="Description"
            autosize={{ minRows: 1, maxRows: 6 }}
            style={{
              width: "19vw",
              resize: "none",
              minHeight: "5vw",
              color: Theme.TEXT,
              fontSize: ".75vw",
              background: Theme.BACKGROUND,
              borderWidth: Theme.BACKGROUND === "#000" ? 0 : 1
            }}
          />
        </Form.Item>
      </Col>
      <Row style={{ marginTop: "1.5vw" }}>
        <div>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              color={"PRIMARY"}
              style={{
                zIndex: 1,
                backgroundImage:
                  "linear-gradient(60deg, #C04848 25%, #480048 120%)",
                borderRadius: 10,
                borderWidth: 0,
                opacity: 0.9
              }}
            >
              {programTemplateData ? (
                <Text style={{ color: "white" }}>Update Program</Text>
              ) : (
                <Text style={{ color: "white" }}>Create Program</Text>
              )}{" "}
            </Button>
          </Form.Item>{" "}
        </div>
      </Row>

      {error === true && (
        <Text style={{ fontsize: ".65vw", color: "red" }}>
          Error - Please Check the Fields and Try Again
        </Text>
      )}
    </Form>
  );
};

export default CreateProgramTemplateForm;
