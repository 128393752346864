import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Modal, Typography, Button, Spin } from "antd";
import { ExclamationCircleOutlined, FileAddOutlined } from "@ant-design/icons";
import { StyleSheet } from "aphrodite";

import { load_user } from "../../redux/actions/auth";
import BootstrapSearch from "../common/Search";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  assignWorkoutTemplate,
  deleteWorkoutTemplate,
  getWorkouts,
  getWorkoutTemplates,
  resetWorkoutTemplateStatus
} from "../../redux/actions/workouts";
import styled from "styled-components";
import Calendar from "react-calendar";
const { Text } = Typography;

function WorkoutTemplateModal({ closeModal, fromTrainerGroup }) {
  const dispatch = useDispatch();
  const { client } = useSelector((state) => state.clients);
  const { workoutTemplates, workoutTemplateStatus, workouts } = useSelector(
    (state) => state.workouts
  );
  const { trainer_group } = useSelector((state) => state.trainer_groups);
  const [isLoading, setLoading] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [assignWorkoutId, setAssignWorkoutId] = useState(null);
  const [selectedDates, setSelectedDates] = useState([]);
  const [error, setError] = useState(false);

  const handleSubmit = async () => {
    setError(false);
    const data = {
      client_ids: fromTrainerGroup
        ? trainer_group.clients.map((client) => client.id)
        : [client?.id],
      dates: selectedDates
    };
    dispatch(assignWorkoutTemplate(assignWorkoutId, data));
  };

  useEffect(() => {
    setError(false);
    setAssignWorkoutId(null);
    setSelectedDates([]);
  }, [client?.id, closeModal]);

  useEffect(() => {
    if (workoutTemplateStatus === "success") {
      dispatch(getWorkouts(client?.id));
      closeModal();
      dispatch(resetWorkoutTemplateStatus());
    } else if (workoutTemplateStatus === "fail") {
      setError(true);
    }
  }, [client?.id, closeModal, dispatch, workoutTemplateStatus]);

  const { Theme } = useSelector((state) => state.auth);

  useEffect(() => {
    AOS.init();
    dispatch(getWorkoutTemplates());
    setLoading(false);
  }, []);

  useEffect(() => {
    if (workoutTemplateStatus === "deleted") {
      dispatch(getWorkoutTemplates());
      dispatch(resetWorkoutTemplateStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workoutTemplateStatus]);

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value.toLowerCase());
  };

  const filteredTemplates = workoutTemplates
    ? workoutTemplates.filter((template) =>
        template?.title?.toLowerCase().includes(searchInput)
      )
    : workoutTemplates;

  const handleDateClick = (date) => {
    const formattedDate = date.toISOString().split("T")[0]; // Convert to YYYY-MM-DD format
    if (selectedDates.includes(formattedDate)) {
      // Deselect the date if it's already selected
      setSelectedDates((prevSelectedDates) =>
        prevSelectedDates.filter((d) => d !== formattedDate)
      );
    } else {
      // Select the date if it's not already selected
      setSelectedDates((prevSelectedDates) => [
        ...prevSelectedDates,
        formattedDate
      ]);
    }
  };

  const isDateWithWorkout = (date) => {
    return workouts.some((workout) => {
      const workoutDate = new Date(`${workout.date}T00:00:00Z`);
      return (
        workoutDate.getUTCDate() === date.getUTCDate() &&
        workoutDate.getUTCMonth() === date.getUTCMonth() &&
        workoutDate.getUTCFullYear() === date.getUTCFullYear()
      );
    });
  };

  const getDayClassName = (date) => {
    if (!(date instanceof Date)) return ""; // Ensure 'date' is a valid Date object

    const formattedDate = date.toISOString().split("T")[0];
    const isSelected = selectedDates.includes(formattedDate);
    return isSelected
      ? "react-calendar__tile--active custom-active"
      : "react-calendar__tile--active";
  };

  const isTileDisabled = ({ date }) => {
    // Disable dates that already have workouts
    return isDateWithWorkout(date);
  };

  const StyledCalendar = styled(Calendar)`
    .workout {
      width: 40vw;
      font-size: 1vw;
      border-width: 0;
      /* box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.25); */
      background-color: #262828; /* Set your desired background color */
      color: #333; /* Set your desired text color */
      /* Add more styles as needed */
    }

    .light-theme {
      background-color: #fff !important; /* Set your light background color */
      color: #333 !important; /* Set your light text color */
    }

    .react-calendar__tile--active {
      background-color: transparent !important;
      color: #fff !important;
      border-radius: 5%;
    }

    .react-calendar__tile--active:enabled:hover,
    .react-calendar__tile--active:enabled:focus {
      background-color: #c04848 !important;
      border-radius: 5%;
    }

    .react-calendar__tile--active.dark-theme {
      background-color: transparent !important;
      border-radius: 5%;
    }

    .react-calendar__tile--active.custom-active {
      background-color: #c04848 !important;
      border-radius: 5%;
    }
  `;

  const buttonDisabled =
    workoutTemplates?.length === 0 ||
    selectedDates.length === 0 ||
    assignWorkoutId === null;

  if (isLoading) {
    return (
      <Spin
        size="large"
        style={{ position: "absolute", top: "50%", left: "50%" }}
      />
    );
  }

  return (
    <Col align={"middle"} justify={"center"} style={{ position: "relative" }}>
      <Col
        align={"middle"}
        justify={"center"}
        bordered={true}
        style={{
          background: Theme.CARD,
          borderWidth: 0,
          minHeight: "60vh",
          paddingBottom: "10vh"
        }}
        className="criclebox cardbody"
      >
        <Text
          style={{
            fontWeight: "bold",
            fontSize: "1dvw",
            marginBottom: "3vw",
            top: "1vw",
            position: "relative",
            color: Theme.TEXT,
            justify: "center"
          }}
        >
          Select Template and Dates
        </Text>
        <Col
          align="middle"
          justify="center"
          style={{ position: "absolute", right: 75 }}
        >
          <Button
            color={"PRIMARY"}
            style={{
              width: "8vw",
              cursor: "pointer",
              zIndex: 999,
              backgroundImage: buttonDisabled
                ? Theme.MUTED
                : "linear-gradient(60deg, #C04848 25%, #480048 120%)",
              borderRadius: 10,
              borderWidth: 0,
              opacity: 0.9,
              background: buttonDisabled
                ? Theme.MUTED
                : "linear-gradient(60deg, #C04848 25%, #480048 120%)"
            }}
            onClick={() => handleSubmit()}
            disabled={buttonDisabled}
          >
            <Text
              style={{
                color: "white",
                fontSize: ".75vw",
                textAlign: "center",
                opacity: 1,
                fontWeight: "bold"
              }}
            >
              Create
            </Text>
          </Button>
          <div>
            {error && (
              <Text
                style={{
                  fontSize: ".65vw",
                  color: "red"
                }}
              >
                Error - Please Try Again
              </Text>
            )}
          </div>
        </Col>

        <Row align={"top"} justify={"center"}>
          <Col
            style={{ width: "50%", position: "relative", top: "2vw" }}
            justify="start"
          >
            <Row
              className="project-ant"
              style={{
                position: "relative",
                left: "2vw"
              }}
            >
              <Col span={12} style={styles.fieldrow}>
                <BootstrapSearch
                  placeholder="Search Templates"
                  value={searchInput}
                  width="15vw"
                  onChange={handleSearchChange}
                />
              </Col>
            </Row>

            {/* Render Profiles */}
            {filteredTemplates.length > 0 ? (
              <div
                style={{ paddingLeft: "4vw" }}
                className="ant-list-box table-responsive"
              >
                <table className="width-100">
                  <thead></thead>
                  <tbody>
                    {filteredTemplates.map((d, index) => (
                      <tr key={index}>
                        <td
                          style={{
                            fontSize: ".75vw",
                            width: "50%",
                            cursor: "pointer"
                          }}
                        >
                          <Row
                            style={{
                              fontSize: "1vw",
                              position: "relative",
                              right: "1.25vw"
                            }}
                          >
                            <p
                              style={{
                                position: "relative",

                                fontSize: ".85vw",
                                color: Theme.TEXT
                              }}
                            >
                              {d?.title}
                            </p>
                          </Row>
                        </td>

                        <td
                          style={{
                            fontSize: ".85vw",
                            color: Theme.TEXT,
                            left: "3vw",
                            position: "relative"
                          }}
                        >
                          <Row align={"middle"} justify={"center"}>
                            <Button
                              onClick={() => {
                                setAssignWorkoutId(d?.id);
                              }}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color: "white",
                                borderWidth: 0,
                                borderRadius: 5,
                                height: "1.5vw",
                                width: "5vw",
                                fontWeight: "bold",

                                cursor: "pointer",
                                background:
                                  d?.id === assignWorkoutId
                                    ? "#000"
                                    : Theme.PRIMARY
                              }}
                            >
                              <Row align={"middle"}>
                                <div>
                                  {d?.id === assignWorkoutId ? (
                                    <Text
                                      style={{
                                        fontSize: ".75vw",
                                        color: "white"
                                      }}
                                    >
                                      Selected
                                    </Text>
                                  ) : (
                                    <Text
                                      style={{
                                        fontSize: ".75vw",
                                        color: "white"
                                      }}
                                    >
                                      Select
                                    </Text>
                                  )}
                                </div>
                              </Row>
                            </Button>
                          </Row>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <Col
                style={{
                  textAlign: "center",
                  marginTop: "10vw",
                  marginBottom: "2vw",
                  width: "50%"
                }}
                align="middle"
                justify="center"
              >
                <FileAddOutlined
                  style={{ fontSize: "3vw", color: Theme.PRIMARY }}
                />
                <Text
                  style={{
                    display: "block",
                    color: Theme.TEXT,
                    marginTop: 10,
                    fontWeight: "bold",
                    fontSize: "1vw"
                  }}
                >
                  No Templates
                </Text>
                <Text
                  style={{
                    display: "block",
                    color: Theme.TEXT,
                    marginTop: 10,
                    fontSize: ".8vw",
                    textAlign: "center"
                  }}
                >
                  Create workout templates from the templates section in the
                  side navigation panel
                </Text>
              </Col>
            )}
          </Col>
          <Col
            style={{
              zIndex: 100,

              width: "50%",
              top: 100,
              position: "relative",
              background: Theme.CARD
            }}
          >
            <Col style={{ top: 50, position: "relative" }}>
              <StyledCalendar
                onClickDay={handleDateClick}
                tileClassName={({ date }) => getDayClassName(date)}
                selectedDates={selectedDates}
                tileDisabled={isTileDisabled}
              />
            </Col>
          </Col>
        </Row>
      </Col>
    </Col>
  );
}

export default WorkoutTemplateModal;

const styles = StyleSheet.create({
  fieldrow: {
    marginBottom: "1vw"
  }
});
