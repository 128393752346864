import { useState, useEffect } from "react";
import {
  Col,
  Grid,
  Modal,
  Row,
  Tabs,
  Typography,
  Card,
  Button,
  Form,
  Switch,
  Input,
  Spin
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getSingleClient,
  getSingleProfile,
  deleteProfile,
  updateRangeProfile
} from "../redux/actions/clients";
import AssessmentList from "../components/AssessmentList";
import ClientHeader from "../components/ClientHeader";
import FreeRecordList from "../components/FreeRecords";
import Progress from "../components/Progress";
import WorkoutSchedule from "../components/workouts/WorkoutSchedule";
import Cycles from "../components/workouts/Cycles";
import { analytics } from "../firebase-config";
import { logEvent } from "firebase/analytics";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { IoChevronBackOutline } from "react-icons/io5";
import rangePic from "../assets/images/rangeprofile.png";
import {
  useHistory,
  useParams
} from "react-router-dom/cjs/react-router-dom.min";
import { InfoCircleOutlined } from "@ant-design/icons";
import RangeTemplates from "../components/templates/RangeTemplates";
import range_descriptions from "../components/templates/range_descriptions";

const { Text } = Typography;
const { confirm } = Modal;
const { Title } = Typography;
const { TabPane } = Tabs;

const RangeTemplateDetails = () => {
  const history = useHistory();
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);
  const [form] = Form.useForm();

  const { profile, profileStatus } = useSelector((state) => state.clients);
  const { Theme } = useSelector((state) => state.auth);

  const { client } = useSelector((state) => state.clients);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [rangeProfile, setRangeProfile] = useState({});
  const [infoModalVisible, setInfoModalVisible] = useState(false);
  const rangeDesc = [
    "Set ideal ranges for this profile with the inputs for each data point.",
    " Linear scoring means the score will be based on the number that you set (e.g. if the joint travels 50° during the movement with a range of 100°, they'll receive a 50%.",
    " Optimal scoring sets an ideal range of motion to hit for 100% (e.g. if you set the optimal number to 15°, traveling 10° or 20° will provide the same score as they are both 5° away from optimal."
  ];

  const [isRangeInfoModalVisible, seRangeInfoModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState({
    name: "",
    description: ""
  });

  const handleRangeInfoModalClose = () => {
    seRangeInfoModalVisible(false);
  };

  const showRangeInfoModal = (category) => {
    const descriptionData = range_descriptions[category];
    if (descriptionData) {
      setModalContent({
        name: category,
        description: descriptionData.description
      });
      seRangeInfoModalVisible(true);
    }
  };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        await dispatch(getSingleProfile(id));
        logEvent(analytics, "page_view", { title: "Range Templates" });
      } catch (error) {
        console.error("Error fetching profile:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, [dispatch]);

  useEffect(() => {
    if (profile && profile.range_profile) {
      setRangeProfile(profile.range_profile);
    }
  }, [profile]);

  useEffect(() => {
    if (profileStatus === "success") {
      getSingleProfile(id);
    }
  }, [profileStatus]);

  const deleteRangeProfile = () => {
    confirm({
      title: "Delete Range Template",
      content: "Are you sure you want to delete this Template?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        dispatch(deleteProfile(id));
        history.push("/templates");
      }
    });
  };

  const handleInfoClick = () => {
    setInfoModalVisible(true);
  };

  const handleInfoModalClose = () => {
    setInfoModalVisible(false);
  };

  const handleSave = async () => {
    setIsSaving(true); // Show spinner
    setError(null); // Reset error

    try {
      await dispatch(
        updateRangeProfile(profile.id, { range_profile: rangeProfile })
      );
      //message.success("Profile updated successfully!");
    } catch (err) {
      setError("Error updating profile. Please try again.");
    } finally {
      setIsSaving(false);
    }
  };

  const capitalize = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const [isRowLayout, setIsRowLayout] = useState(window.innerWidth >= 1000);

  // Function to handle window resize event and update layout mode
  const handleWindowResize = () => {
    setIsRowLayout(window.innerWidth >= 996);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const handleGoToTemplates = () => {
    history.push("/templates");
  };

  if (loading) {
    return (
      <Row
        style={{
          minHeight: "500vh",
          justifyContent: "center",
          alignItems: "center",
          padding: "2rem",
          backgroundColor: "transparent"
        }}
      >
        <Spin size="large" />
      </Row>
    );
  }

  return (
    <Col
      align="middle"
      justify="center"
      style={{
        minHeight: "100vh",
        position: "relative",
        marginRight: "10vw",
        backgroundColor: "transparent"
      }}
    >
      <Row
        onClick={handleGoToTemplates}
        style={{
          left: "-8vw",
          top: "2vw",
          position: "absolute",
          cursor: "pointer",
          width: "5vw"
        }}
      >
        <IoChevronBackOutline
          style={{
            top: ".2vw",
            marginRight: ".5%",
            position: "relative",
            color: Theme.TEXT
          }}
        />
        <div>
          <Text style={{ color: Theme.TEXT }}>Back</Text>
        </div>
      </Row>
      {/* Profile Header Card */}
      <Col
        align="middle"
        justify="center"
        // span={12}
        style={{
          justifyContent: "center",
          marginBottom: "2rem"
        }}
      >
        <Card
          style={{
            width: "30vw",
            textAlign: "center",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            backgroundColor: Theme.CARD,
            borderWidth: 0
          }}
        >
          <Button
            style={{
              borderWidth: 0,
              width: "auto",
              minHeight: "auto",
              background: "transparent",
              cursor: "pointer",
              padding: 0,
              position: "absolute", // Absolutely position the button
              top: "1vw", // Adjust the top position
              right: "1vw", // Align it to the right
              boxShadow: "none", // Remove any potential shadow
              outline: "none" // Remove any focus outline
            }}
            onClick={() => handleInfoClick()}
          >
            <InfoCircleOutlined
              style={{
                fontSize: ".95vw",
                color: Theme.PRIMARY // Keep the color from Theme
              }}
            />
          </Button>
          <img
            src={rangePic}
            alt=""
            style={{
              height: "2vw", // Adjust the image height using vw units
              width: "2vw"
            }}
          />
          <Title level={3} style={{ color: Theme.TEXT, fontSize: "1.5vw" }}>
            {profile.name}
          </Title>
          <Text
            level={3}
            style={{
              marginBottom: "1rem",
              color: Theme.TEXT,
              fontSize: ".95vw"
            }}
          >
            Range Profile
          </Text>
          <Row justify="space-between">
            {!profile.isDefault && (
              <Button
                style={{ backgroundColor: Theme.PRIMARY, color: "white" }}
                danger
                onClick={deleteRangeProfile}
              >
                Delete
              </Button>
            )}

            <Button type="primary" onClick={handleSave}>
              {isSaving ? <Spin size="small" /> : "Save"}
            </Button>
          </Row>

          {error && (
            <div style={{ color: "red", marginTop: "1rem" }}>{error}</div>
          )}
        </Card>
      </Col>

      <Form
        form={form}
        layout="vertical"
        style={{
          width: "100%",
          paddingLeft: "0vw",
          backgroundColor: "transparent"
        }}
      >
        <Row
          gutter={[16, 16]}
          justify="center"
          style={{
            width: "115%",

            backgroundColor: Theme.BACKGROUND
          }}
        >
          <RangeTemplates
            rangeProfile={rangeProfile}
            setRangeProfile={setRangeProfile}
            showModal={showRangeInfoModal}
          />
        </Row>
      </Form>
      <Modal
        open={infoModalVisible}
        onCancel={handleInfoModalClose}
        width={"45vw"}
        bodyStyle={{
          paddingBottom: "10vw",
          paddingTop: "1vw",
          paddingLeft: "1vw",
          paddingRight: "1vw",
          minHeight: "25vw"
        }}
        className={
          Theme.BACKGROUND === "#000"
            ? "customAntdModalDark"
            : "customAntdModalLight"
        }
        footer={[<div></div>]}
      >
        <Title level={3} style={{ marginBottom: "1vw", color: Theme.TEXT }}>
          {"Ranges"}
        </Title>

        {/* Descriptions */}
        <Text
          style={{
            display: "block",
            marginBottom: "1vw",
            fontSize: "1.1vw",
            color: Theme.TEXT
          }}
        >
          {rangeDesc}
        </Text>
      </Modal>
      <Modal
        visible={isRangeInfoModalVisible}
        onCancel={handleRangeInfoModalClose}
        width={modalContent.name === "CMJ" ? "55vw" : "25vw"}
        bodyStyle={{
          paddingBottom: "10vw",
          paddingTop: "1vw",
          paddingLeft: "1vw",
          paddingRight: "1vw",
          minHeight: "25vw"
        }}
        className={
          Theme.BACKGROUND === "#000"
            ? "customAntdModalDark"
            : "customAntdModalLight"
        }
        footer={[<div></div>]}
      >
        <Title style={{ color: Theme.TEXT }} level={3}>
          {modalContent.name}
        </Title>
        {modalContent.description &&
          modalContent.description.map((desc, index) => (
            <p key={index} style={{ fontSize: "1.5rem", color: Theme.TEXT }}>
              {desc}
            </p>
          ))}
      </Modal>
    </Col>
  );
};
export default RangeTemplateDetails;
